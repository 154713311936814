import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { Table, Form, Button, Col, Row } from "react-bootstrap";
import axiosInstance from "../../utils/axiosInstance";
import axios from "axios";
import { APIS } from "../../utils/constants";
import dateFormat from "dateformat";

export default function Orders() {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [orderArr, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  useEffect(() => {
    try {
      const controller = new AbortController();
      axiosInstance
        .get(APIS.order_list, {
          signal: controller.signal,
        })
        .then((response) => {
          const orders = response.data;
          const sortedArray = orders.sort(
            (a, b) => a?.createdAt - b?.createdAt
          );
          setOrders(sortedArray);
          setFilteredOrders(sortedArray);
        });
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled", error.message);
      } else {
        alert(error.message);
      }
    } finally {
    }
  }, []);
  const handleFilter = () => {
    // Filter orders based on the selected date range
    const filtered = orderArr?.filter((order) => {
      const orderDate = new Date(order?.createdAt).toISOString();
      const start = startDate ? new Date(startDate).toISOString() : null;
      const end = endDate ? new Date(endDate).toISOString() : null;
      return (!start || orderDate >= start) && (!end || orderDate <= end);
    });

    setFilteredOrders(filtered);
  };

  return (
    <div>
      <Card
        style={{
          marginLeft: "5%",
          marginRight: "5%",
          marginTop: "2%",
          borderRadius: "10px",
          boxShadow: "0 20px 50px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Card.Body style={{ padding: "50px" }}>
          <Card.Title>
            <Row className="row-gap-10">
              <Col lg={8}>
                <h2 style={{ color: "var(--main)" }}>Recent Orders</h2>
              </Col>
              <Col lg={4}>
                <Form>
                  <Row className="row-gap-5">
                    <Col xs={12} md={4}>
                      <Form.Group>
                        <Form.Label style={{ fontSize: "large" }}>
                          Start Date:
                        </Form.Label>
                        <Form.Control
                          type="date"
                          style={{
                            width: "100%",
                            fontSize: "small",
                            fontFamily: "sans-serif",
                          }}
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={4}>
                      <Form.Group>
                        <Form.Label style={{fontSize:"large"}}>End Date:</Form.Label>
                        <Form.Control
                          type="date"
                          style={{
                            width: "100%",
                            fontSize: "small",
                            fontFamily: "sans-serif",
                          }}
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={4}>
                      <Button
                        variant="primary"
                        onClick={handleFilter}
                        style={{
                          width: "100%",
                          margin: "20px 0",
                          fontSize: "small",
                          fontFamily: "sans-serif",
                        }}
                      >
                        Filter
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Card.Title>

          <Table>
            <thead>
              <tr>
                <th style={{ padding: "20px" }}>No.</th>
                <th style={{ padding: "20px" }}>Order</th>
                <th style={{ padding: "20px" }}>Date</th>
                <th style={{ padding: "20px" }}>Total Amount</th>
                <th style={{ padding: "20px" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredOrders.map((order, index) => {
                const isLastRow = index === filteredOrders.length - 1;

                return (
                  <tr key={order.id} style={{ borderStyle: "none" }}>
                    <td style={{ padding: "20px" }}>{index + 1}</td>
                    <td style={{ padding: "20px" }}>{order.invoiceNo}</td>
                    <td style={{ padding: "20px" }}>{dateFormat(order?.createdAt, "paddedShortDate", true)}</td>
                    <td style={{ padding: "20px" }}>$ {order.total}</td>
                    <td style={{ padding: "20px" }}>
                      <a
                        style={{ color: "var(--main)" }}
                        href={order.receiptLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </div>
  );
}
