import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Container, Row, Table, Modal } from "react-bootstrap";
import axios from "axios";
import { APIS } from "../../utils/constants";
import axiosInstance from "../../utils/axiosInstance";
import Loader from "../../Components/Loader";
import { useUser } from "../../utils/UserContext";
import Cookies from "js-cookie";

const CartPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [cartData, setCartData] = useState([]);
  const [updateQuantity, setUpdateQuantity] = useState([]);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const { setCartItem } = useUser();
  const [quantityLoading, setQuantityLoading] = useState(false);
  const handleCloseErrorModal = () => setShowErrorModal(false);

  useEffect(() => {
    const controller = new AbortController();
    const fetchData = async () => {
      try {
        setLoading(true);
        const token = Cookies.get("QW_authToken");

        const response =
          token &&
          (await axiosInstance.get(`${APIS.get_cart}`, {
            signal: controller.signal,
          }));
        const responseData = response.data;
        setCartItem(responseData?.cart?.items?.length || 0);
        setCartData(responseData?.cart?.items);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled", error.message);
        } else {
          setError(error);
        }
      } finally {
        setLoading(false);
      }
    };
    fetchData();
    return () => {
      controller.abort("Component got unmounted");
    };
  }, []);

  const totalPrice = cartData?.reduce(
    (acc, item) => acc + item.productPrice * item.quantity,
    0
  );

  const handleUpdateCart = async () => {
    if (updateQuantity.length === 0) {
      setError("Cart is empty. No need to update.");
      setShowErrorModal(true);
      console.log("Cart is empty. No need to update.");
      return;
    }
    try {
      const cartItems = updateQuantity.map((product) => ({
        productId: product.productId,
        quantity: product.quantity,
      }));
      // Assuming you have an API endpoint for updating the cart
      const response = await axiosInstance.post(APIS.addItem, {
        items: cartItems,
      });

      if (response.data?.success) {
        const newUpdatedCart = cartData?.filter((item) => item.quantity > 0);
        setCartData(newUpdatedCart);
        setCartItem(newUpdatedCart?.length);
      }

      // Handle the response, e.g., show a success message
    } catch (error) {
      setError(error.data.message);
      console.log(error);
      setShowErrorModal(true);
      setLoading(false);
      console.error("Error updating cart", error);
    }
  };
  const handleRemove = async (productID) => {
    if (productID && productID > 0) {
      try {
        const token = Cookies.get("QW_authToken");
        setLoading(true);
        await axiosInstance
          .post(`${APIS.add_item_to_cart}`, {
            productId: productID,
            quantity: 0,
          })
          .then(async () => {
            const response =
              token && (await axiosInstance.get(`${APIS.get_cart}`));
            const responseData = response.data;
            setCartItem(responseData?.cart?.items?.length || 0);
            setCartData(responseData?.cart?.items);
          });
      } catch (error) {
        console.error("Cart Update Error in my-cart", error);
        setError(error);
        alert(error.message);
      } finally {
        setLoading(false);
      }
    }
  };
  // Function to handle quantity change
  const handleQuantityChange = (itemId, newQuantity) => {
    setQuantityLoading(true);
    const updatedCartData = cartData?.reduce((updatedCart, item) => {
      const shouldUpdate = item.productId === itemId;
      const updatedItem = shouldUpdate
        ? !item.oldQuantity
          ? { ...item, quantity: newQuantity, oldQuantity: item.quantity }
          : { ...item, quantity: newQuantity }
        : item;

      const find = updateQuantity.find((q) => q.productId === itemId);

      if (!find && shouldUpdate) {
        setUpdateQuantity([
          ...updateQuantity,
          { ...item, quantity: newQuantity, oldQuantity: item.quantity },
        ]);
      } else if (shouldUpdate && find && item.oldQuantity === newQuantity) {
        const newarrQuantity = updateQuantity.reduce((accumulator, newItem) => {
          if (newItem.productId !== itemId) {
            accumulator.push(newItem);
          }
          return accumulator;
        }, []);
        setUpdateQuantity(newarrQuantity);
      } else if (shouldUpdate && find) {
        const newData = updateQuantity.reduce((accumulator, updateItem) => {
          if (updateItem.productId === itemId) {
            updateItem.quantity = newQuantity;
          }
          accumulator.push(updateItem);
          return accumulator;
        }, []);

        setUpdateQuantity(newData);
      }

      return updatedCart.concat(updatedItem);
    }, []);
    setCartData(updatedCartData);
    setQuantityLoading(false);
    return updatedCartData;
  };

  const proceedCheckout = () => {
    const productWithAvailableQuantity = cartData.reduce(
      (accumulator, item) => {
        if (
          item.quantity > item.availableStocksQuantity ||
          (item.quantity >= 0 && item.availableStocksQuantity === 0)
        ) {
          accumulator.push({
            ...item,
            errorMessage: `Avl: ${item.availableStocksQuantity}`,
          });
        }
        return accumulator;
      },
      []
    );
    console.log("productWithAvailableQuantity", productWithAvailableQuantity);
    let message =
      "🚨Attention! 🚨The item you're interested in might be out of stock or running low.\nPlease check your cart for the most current stock availability to secure your purchase.\nDon't miss your chance!\n";

    productWithAvailableQuantity.length > 0 && alert(message);
    setCartData(productWithAvailableQuantity);
    productWithAvailableQuantity.length === 0 && navigate("/checkout");
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      {cartData?.length > 0 ? (
        <Container>
          <h1
            style={{
              fontFamily: "Titillium Web, serif",
              fontSize: "70px",
              paddingTop: "20px",
              color: "var(--orange)",
            }}
          >
            Your Cart
          </h1>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Item</th>
                <th>Price</th>
                <th>Quantity</th>
                <th>Available Quantity</th>
                <th>Total</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {cartData?.map((item, idx) => (
                <tr key={item.productId}>
                  <td>{idx + 1}</td>
                  <td
                    style={{ cursor: "pointer", color: "var(--orange)" }}
                    onClick={() => {
                      return navigate(
                        `/product-details?product=${item.productId}`
                      );
                    }}
                  >
                    {item.productName}
                  </td>
                  <td>${item?.productPrice?.toFixed(2)}</td>
                  <td>
                    <span style={{ width: "60px" }}>
                      <span
                        style={{
                          width: "40px",
                          maxWidth: "80px",
                          display: "inline-block",
                          marginLeft: "5px", // Add some spacing between buttons
                        }}
                      >
                        {item.quantity}
                      </span>
                      <Button
                        style={{
                          backgroundColor: "var(--main)",
                          borderColor: "white",
                          borderRadius: "50%",
                          marginLeft: "5px", // Add some spacing between buttons
                        }}
                        disabled={
                          item.quantity <= 0 ||
                          quantityLoading ||
                          item.availableStocksQuantity < 0
                        }
                        onClick={() => {
                          item.quantity > 0 &&
                            item.availableStocksQuantity > 0 &&
                            handleQuantityChange(
                              item.productId,
                              item.quantity - 1
                            );
                        }}
                      >
                        <span style={{ padding: 5, fontSize: "15px" }}>-</span>
                      </Button>
                      <Button
                        style={{
                          backgroundColor: "var(--orange)",
                          borderColor: "white",
                          borderRadius: "50%",
                        }}
                        disabled={
                          item.quantity === item.availableStocksQuantity ||
                          quantityLoading ||
                          item.availableStocksQuantity < 0
                        }
                        onClick={() => {
                          item.quantity < item.availableStocksQuantity &&
                            item.availableStocksQuantity > 0 &&
                            handleQuantityChange(
                              item.productId,
                              item.quantity + 1
                            );
                        }}
                      >
                        <span style={{ padding: 5, fontSize: "15px" }}>+</span>
                      </Button>
                      {item?.errorMessage && (
                        <p
                          style={{
                            display: "flex",
                            whiteSpace: "nowrap",
                            margin: 0,
                            fontSize: "medium",
                            color: "var(--main)",
                            fontWeight: "bold",
                          }}
                        >
                          {item?.errorMessage}
                        </p>
                      )}
                    </span>
                  </td>
                  <td>
                    <span style={{ width: "60px" }}>
                      {item.availableStocksQuantity}
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        width: "80px",
                        maxWidth: "80px",
                        display: "inline-block",
                      }}
                    >
                      ${(item?.productPrice * item.quantity)?.toFixed(2)}
                    </span>
                  </td>
                  <td>
                    <Button
                      variant="danger"
                      style={{
                        fontSize: "15px",
                      }}
                      onClick={() => handleRemove(item.productId)}
                    >
                      Remove
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Row>
            <Col className="text-right">
              <h4
                style={{
                  fontSize: "21px",
                  fontWeight: "bold",
                  color: "var(--main)",
                }}
              >
                Total: ${totalPrice?.toFixed(2)}
              </h4>
              <Button
                style={{
                  marginTop: 5,
                  backgroundColor: "var(--orange)",
                  fontSize: "21px",
                  borderRadius: 11,
                  borderColor: "white",
                  color: "white",
                }}
                disabled={updateQuantity.length === 0}
                onClick={handleUpdateCart}
              >
                Update to cart
              </Button>
              <br />
              {}
              <div
                className="small fw-800 text-one-line"
                // href="/checkout"
                onClick={proceedCheckout}
                style={{ color: "black", cursor: "pointer" }}
              >
                <span
                  // className="ms-3"
                  style={{
                    color: "var(--orange)",
                    fontSize: "21px",
                    marginLeft: "2px",
                  }}
                >
                  Proceed to Checkout
                </span>
              </div>
            </Col>
          </Row>
        </Container>
      ) : (
        <h2
          style={{
            fontFamily: "Titillium Web, serif",
            // fontSize: "70px",
            paddingTop: "15rem",
            justifyContent: "center",
            color: "var(--orange)",
            textAlign: "center",
          }}
        >
          Please select one item to place order
        </h2>
      )}

      <Modal show={showErrorModal} onHide={handleCloseErrorModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{error}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseErrorModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CartPage;
