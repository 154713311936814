import { Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { SVGIcons } from "../Data/SVGIcons";
import MapComponent from "../Map/Map";
import { googleMapsLink } from "../../utils/constants";
import "./Footer.css";
import { useUser } from "../../utils/UserContext";

function Footer() {
  const { user } = useUser();
  return (
    <footer className="footer-section">
      <div className="footer-contain space-top">
        <Container>
          <div className="footer-widget border-top pt-5 mb-5 pb-5">
            <Row className="row-gap-5">
              <Col lg={3}>
                <div>
                  <Image
                    src={"/assets/Logo/logo.png"}
                    alt="Quality Wholesale USA"
                    fluid
                    width={300}
                    style={{ paddingBottom: "10px" }}
                  />
                  <ul className="widget-nav">
                    <li>
                      <Link to="/about-us">About Us</Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/terms-of-service">Terms of Service</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col lg={3}>
                <div>
                  {user ? (
                    <>
                      <h3 className="widget-title">My Account</h3>
                      <ul className="widget-nav">
                        <li>
                          <Link to="/profile">My Profile</Link>
                        </li>
                        <li>
                          <Link to="/my-cart">Shopping Cart</Link>
                        </li>
                      </ul>
                    </>
                  ) : (
                    <>
                      <h3 className="widget-title">My Account</h3>
                      <ul className="widget-nav">
                        <li>
                          <Link to="/login">Sign In</Link>
                        </li>
                        <li>
                          <Link to="/register">Register</Link>
                        </li>
                      </ul>
                    </>
                  )}
                </div>
              </Col>
              <Col lg={3}>
                <div>
                  <h3 className="widget-title">Contact Us</h3>
                  <div className="icon d-align-center mb-4">
                    <a
                      href={googleMapsLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {SVGIcons.Location}
                      <span className="ms-3">
                        1419 N Robison Rd, Texarkana TX 75501, United States
                      </span>
                    </a>
                  </div>
                  <div className="icon d-align-center mb-4">
                    <a
                      href="tel:+14302004319"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {SVGIcons.Call}
                      <span className="ms-3">(430) 200-4319</span>
                    </a>
                  </div>
                  <div className="icon d-align-center mb-4">
                    <a
                      href="mailto:qualitywholesaleus@gmail.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {SVGIcons.Email}
                      <span className="ms-3">qualitywholesaleus@gmail.com</span>
                    </a>
                  </div>
                </div>
              </Col>
              <Col lg={3}>
                <div>
                  <h3 className="widget-title">Locate Us</h3>
                  <ul className="widget-nav">
                    <MapComponent />
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
          <div className="footer-copyright border-top pt-5 pb-5">
            <Row className="row-gap-5">
              <Col lg={6}>
                <div className="copyright-text">
                  <p className="mid">
                    &copy; 2023. All rights reserved design by Quality Wholesale
                    USA
                  </p>
                </div>
              </Col>
              <Col lg={6}>
                <div className="footer-nav">
                  <ul>
                    <li>
                      <Link to="/privacy-policy">Privacy &amp; Policy</Link>
                    </li>
                    <li>
                      <Link to="/terms-of-service">Terms of Service</Link>
                    </li>
                    <li>
                      <Link to="#">Faqs</Link>
                    </li>
                    <li>
                      <Link to="#">Services</Link>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </footer>
  );
}
export default Footer;
