import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ScrollToTopOnMount from "../../Components/ScrollToTopOnMount";

function TermsOfService() {
  const sectionStyle = {
    padding: "20px",
    marginBottom: "20px",
  };

  const headingStyle = {
    color: "#333",
    marginBottom: "15px",
  };

  const paragraphStyle = {
    color: "#555",
    lineHeight: "1.6",
    textAlign: "justify",
  };

  return (
    <>
      <ScrollToTopOnMount />
      <Container style={{ paddingTop: "25px" }}>
        <Row>
          <Col>
            {/* Introductory Section */}
            <div style={sectionStyle}>
              <h2 style={headingStyle}>Terms of Service</h2>
              <p style={paragraphStyle}>
                This platform is meticulously managed and operated by Quality
                Wholesale USA. Throughout the entirety of the website, the
                pronouns "we", "us", and "our" are utilized to refer
                specifically and exclusively to Quality Wholesale USA. Quality
                Wholesale USA diligently offers this website, replete with an
                extensive array of information, sophisticated tools, and
                comprehensive services available to you, the esteemed user,
                contingent upon your informed acceptance of all the intricate
                terms, multifaceted conditions, detailed policies, and explicit
                notices delineated herein.
              </p>
              <br />
              <p style={paragraphStyle}>
                In the act of visiting our site and/or effectuating a purchase,
                you are engaging in our "Service" and, as such, you are agreeing
                to be inexorably bound by the following terms and conditions
                ("Terms of Service", "Terms"), which encompass those additional
                terms, conditions, and policies cited within this document
                and/or accessible via hyperlink. These Terms of Service are
                unequivocally applicable to all users of the site, an inclusive
                category that spans, without limitation, browsers, vendors,
                customers, merchants, and contributors of content.
              </p>
              <br />
              <p style={paragraphStyle}>
                We implore you, with utmost sincerity, to peruse these Terms of
                Service with the greatest care and attention before initiating
                access or commencing usage of our website. By accessing or
                utilizing any segment of the site, you are willfully consenting
                to be bound by these Terms of Service. Should you find any
                component of the terms and conditions of this agreement
                disagreeable, then you are advised not to access the website or
                employ any of the services proffered. The contemplation of these
                Terms of Service as an offer is strictly circumscribed to these
                Terms.
              </p>
              <br />
              <p style={paragraphStyle}>
                In the event that the store introduces new features or tools, it
                is understood that they will invariably be subject to the
                pre-existing Terms of Service. The most contemporary version of
                the Terms of Service can invariably be reviewed on this page. We
                reserve the unassailable right to implement updates, instigate
                changes, or effectuate replacements to segments of these Terms
                of Service by posting updates and changes to our website. The
                onus falls upon you to periodically scrutinize this page for
                changes. Your continued utilization of or access to the website
                subsequent to the posting of any amendments signifies your
                acceptance of those changes.
              </p>
            </div>

            {/* Section 1 */}
            <div style={sectionStyle}>
              <h3 style={headingStyle}>
                SECTION 1 - CONDITIONS GOVERNING THE USE OF ONLINE STORE
              </h3>
              <p style={paragraphStyle}>
                By consenting to these Terms of Service, you affirm and
                represent that you have reached the legal age of majority as per
                the laws of your state or province of residence. Furthermore, if
                you are the age of majority in your respective jurisdiction, you
                hereby confirm that you have provided your explicit consent to
                permit any of your minor dependents to engage with this site
                under your supervision.
              </p>
              <br />
              <p style={paragraphStyle}>
                You are strictly prohibited from utilizing our products for any
                purposes that are deemed illegal or unauthorized, and you must
                not, in your utilization of the Service, contravene any
                applicable laws within your jurisdiction, this includes but is
                not limited to, laws pertaining to copyright infringement.
              </p>
              <br />
              <p style={paragraphStyle}>
                You are obligated to refrain from disseminating any malicious
                software, including worms, viruses, or any other codes that bear
                a destructive nature.
              </p>
              <br />
              <p style={paragraphStyle}>
                Any act of non-compliance or infringement of any of these
                stipulated Terms will lead to an immediate cessation of your
                Services, as such actions constitute a material breach of your
                agreement with us
              </p>
            </div>

            {/* Section 2 */}
            <div style={sectionStyle}>
              <h3 style={headingStyle}>SECTION 2 - FUNDAMENTAL PROVISIONS</h3>
              <p style={paragraphStyle}>
                At our sole discretion, we maintain the unequivocal right to
                deny service to any individual at any given moment, for reasons
                that need not be disclosed. It is imperative for you to
                comprehend that the data you transmit (excluding sensitive
                credit card information) might be transferred in an unencrypted
                manner, which includes (a) passing through various disparate
                networks, and (b) undergoing alterations to meet and adapt to
                the technical specifications of connecting networks or devices.
                Notwithstanding the foregoing, be assured that your credit card
                information is always subjected to encryption during its transit
                across networks.
              </p>
              <br />
              <p style={paragraphStyle}>
                You solemnly concur that you will not engage in the
                reproduction, duplication, copying, sale, resale, or
                exploitation of any segment of the Service, the use of the
                Service, or access to the Service, or any contact on the website
                through which the service is rendered, barring the possession of
                explicit written authorization granted by us.
              </p>
              <br />
              <p style={paragraphStyle}>
                For purposes of clarity and ease of reference, the headings
                employed within this agreement are solely for your convenience
                and shall not bear any effect on the interpretation or
                constriction of these Terms.
              </p>
            </div>

            {/* Section 3 */}
            <div style={sectionStyle}>
              <h3 style={headingStyle}>
                SECTION 3 - PRECISION, COMPREHENSIVENESS, AND CURRENCY OF
                INFORMATION
              </h3>
              <p style={paragraphStyle}>
                We bear no responsibility for the veracity, completeness, or
                currentness of the information presented on this site. The
                content provided here is intended solely for general
                informational purposes and should not be the sole factor in your
                decision-making processes without seeking more reliable,
                complete, or up-to-date sources. Placing reliance on the
                material from this site is done strictly at your own peril.
              </p>
              <br />
              <p style={paragraphStyle}>
                Please be aware that this site may encompass specific historical
                data. Such information, by its nature, is not current and is
                furnished solely for your reference. While we reserve the
                unfettered right to alter the content of this site at our
                discretion, there exists no binding obligation upon us to ensure
                that information on our site is updated. It is incumbent upon
                you to vigilantly monitor any alterations to our site.
              </p>
            </div>

            {/* Section 4 */}
            <div style={sectionStyle}>
              <h3 style={headingStyle}>
                SECTION 4 - ALTERATIONS TO THE SERVICE AND PRICING STRUCTURE
              </h3>
              <p style={paragraphStyle}>
                Be advised that the pricing of our offerings may undergo
                alterations without prior notification.
              </p>
              <br />
              <p style={paragraphStyle}>
                At our sole discretion, we retain the absolute authority to
                amend, modify, or cease the Service (or any portion or element
                thereof) at any given moment, without the requirement to provide
                advance notice.
              </p>
              <br />
              <p style={paragraphStyle}>
                Under no circumstances shall we be accountable to you or any
                third party for any changes, adjustments in pricing,
                interruptions, or cessation of the Service.
              </p>
            </div>

            {/* Section 5 */}
            <div style={sectionStyle}>
              <h3 style={headingStyle}>
                SECTION 5 - AVAILABILITY AND SPECIFICATIONS OF PRODUCTS OR
                SERVICES (where applicable)
              </h3>
              <p style={paragraphStyle}>
                A select range of products or services may be exclusively
                accessible online via the website. These offerings may be
                present in limited quantities and are eligible for return or
                exchange solely in alignment with our Return Policy.
              </p>
              <br />
              <p style={paragraphStyle}>
                While we strive to represent our products' colors and images as
                accurately as possible, we cannot assure that the display of any
                color on your computer monitor will be precise.
              </p>
              <br />
              <p style={paragraphStyle}>
                We retain the discretionary right, though not the obligation, to
                restrict the sales of our products or Services to any
                individual, geographic area, or jurisdiction, and this right
                might be exercised on an individual basis. We also reserve the
                authority to limit the available quantities of any of our
                products or services. The descriptions and pricing of our
                products are subject to alteration at any time without prior
                notification, entirely at our discretion. Furthermore, we hold
                the right to cease the availability of any product at any given
                moment. Any proposal for any product or service on this site is
                null and void where prohibited by law. We provide no assurance
                that the quality of any products, services, information, or
                other material acquired by you will align with your
                anticipations, nor do we guarantee that any errors in the
                Service will be rectified.
              </p>
            </div>

            {/* Section 6 */}
            <div style={sectionStyle}>
              <h3 style={headingStyle}>
                SECTION 6 - PRECISION IN BILLING AND ACCOUNT DATA
              </h3>
              <p style={paragraphStyle}>
                We hold the unassailable right to decline any order you initiate
                with us. At our discretion, we may restrict or nullify the
                quantity of items purchased per individual, per household, or
                per order. Such limitations might apply to orders made from the
                same customer account, orders charged to the same credit card,
                and/or orders that utilize identical billing and shipping
                addresses. Should we decide to alter or annul an order, we will
                endeavor to inform you by reaching out to the e-mail and/or
                billing address or phone number you provided at the time the
                order was placed. We maintain the exclusive right to refuse or
                cancel orders that, in our own judgment, seem to originate from
                dealers, resellers, or distributors.
              </p>
              <br />
              <p style={paragraphStyle}>
                You consent to furnish accurate, complete, and up-to-date
                information for all purchases conducted at our establishment.
                You also agree to promptly refresh your account and other
                pertinent details, including your email address, credit card
                numbers, and expiration dates, thereby enabling us to finalize
                your transactions and communicate with you as necessary.
              </p>
            </div>

            {/* Section 7 */}
            <div style={sectionStyle}>
              <h3 style={headingStyle}>
                SECTION 7 - UTILIZATION OF SUPPLEMENTARY TOOLS
              </h3>
              <p style={paragraphStyle}>
                We might grant you the opportunity to engage with third-party
                instruments, over which we exercise no supervision, control, or
                influence.
              </p>
              <br />
              <p style={paragraphStyle}>
                You recognize and concur that we make such auxiliary tools
                available on an "as is" and "as available" basis, devoid of any
                warranties, representations, or conditions of any nature and
                without any endorsement. We bear absolutely no liability arising
                from or related to your use of these ancillary third-party
                tools. Your decision to use any such optional tools offered
                through the site rests solely with you and should be made with
                caution and an understanding of the terms under which these
                tools are provided by the respective third-party provider(s). In
                the future, we might introduce new services and/or features to
                the website (this may include the release of new tools and
                resources). These future additions will also fall under the
                purview of these Terms of Service.
              </p>
            </div>

            {/* Section 8 */}
            <div style={sectionStyle}>
              <h3 style={headingStyle}>
                SECTION 8 - EXTERNAL LINKS AND CONTENT
              </h3>
              <p style={paragraphStyle}>
                Our Service may encompass content, products, and services that
                include materials sourced from external entities.
              </p>
              <br />
              <p style={paragraphStyle}>
                Links found on our site leading to third-party webpages are not
                under our affiliation. We do not bear the responsibility to
                scrutinize or assess the veracity or precision of the content
                found therein, nor do we offer any guarantees, and we
                categorically renounce any accountability or liability for
                materials or websites of third parties, as well as for any other
                materials, products, or services provided by third parties.
              </p>
              <br />
              <p style={paragraphStyle}>
                We absolve ourselves from any liability concerning any potential
                detriment or damages arising from the acquisition or utilization
                of commodities, services, resources, content, or any
                transactions conducted in association with any third-party
                websites. It is crucial for you to meticulously review the
                third-party's policies and practices to ensure comprehension
                before you undertake any transaction. Should you have any
                grievances, claims, concerns, or queries about third-party
                products, these should be directed towards the third-party
                involved.
              </p>
            </div>

            {/* Section 9 */}
            <div style={sectionStyle}>
              <h3 style={headingStyle}>
                SECTION 9 - CONTRIBUTIONS FROM USERS, CRITICAL FEEDBACK, AND
                OTHER INPUTS
              </h3>
              <p style={paragraphStyle}>
                Should you, at our behest, provide specific contributions (such
                as entries for a competition) or, independent of our
                solicitation, present innovative ideas, suggestions, proposals,
                blueprints, or other materials, either online, via email,
                through postal mail, or by any other means (herein referred to
                collectively as 'remarks'), you consent to our right to freely,
                at any juncture, and without any limitations, modify, replicate,
                publish, disseminate, translate and utilize in any form
                whatsoever any remarks that you dispatch to us. We have no
                binding commitment (1) to keep any remarks confidential; (2) to
                offer remuneration for any remarks; or (3) to acknowledge or
                respond to any remarks.
              </p>
              <br />
              <p style={paragraphStyle}>
                While not obligated, we reserve the right to monitor, edit, or
                remove any content that, in our sole and absolute judgment, is
                deemed illegal, offensive, threatening, slanderous, defamatory,
                pornographic, obscene, or otherwise reprehensible or infringes
                upon any party’s intellectual property or these Terms of
                Service.
              </p>
              <br />
              <p style={paragraphStyle}>
                You covenant that your remarks will not infringe upon the rights
                of any third party, including but not limited to copyright,
                trademark, privacy, personality, or any other personal or
                proprietary right. Moreover, you agree that your remarks will
                not encompass any libelous or otherwise unlawful, offensive, or
                obscene material, nor contain any computer virus or other
                malware that could in any manner impair the functionality of the
                Service or any related website. You are prohibited from using a
                false email address, impersonating any person or entity, or
                otherwise deceiving us or third parties as to the origin of any
                remarks. You bear sole responsibility for the remarks you submit
                and their precision. We bear no accountability and assume no
                liability for any remarks posted by you or any third party.
              </p>
            </div>

            {/* Section 10 */}
            <div style={sectionStyle}>
              <h3 style={headingStyle}>
                SECTION 10 - HANDLING OF PERSONAL DATA
              </h3>
              <p style={paragraphStyle}>
                The personal information you disclose through our store is
                managed in accordance with the stipulations laid out in our
                Privacy Policy. For an in-depth understanding of our practices
                concerning your personal data, we encourage you to consult our
                Privacy Policy.
              </p>
            </div>

            {/* Section 11 */}
            <div style={sectionStyle}>
              <h3 style={headingStyle}>
                SECTION 11 - DISCREPANCIES, ERRONEOUS DETAILS, AND OVERSIGHTS
              </h3>
              <p style={paragraphStyle}>
                From time to time, it's possible that our site or the Service
                might display information laden with typographical mistakes,
                inaccuracies, or neglects, particularly concerning product
                descriptions, costings, promotions, propositions, product
                delivery charges, transit durations, and availability. We hold
                the prerogative to rectify any such errors, inaccuracies, or
                omissions, and to alter or revise information or annul orders if
                any data within the Service or on any affiliated website is
                found to be incorrect at any moment without necessitating prior
                notice (this includes moments subsequent to the submission of
                your order). We are under no binding commitment to modify,
                update, or clarify information within the Service or on any
                related website, which includes, but is not limited to, pricing
                information, barring as mandated by applicable law. No
                determined update or refresh timeline applied within the Service
                or on any associated website should be perceived as an indicator
                that all information within the Service or on any associated
                website has been altered or refreshed.
              </p>
            </div>

            {/* Section 12 */}
            <div style={sectionStyle}>
              <h3 style={headingStyle}>SECTION 12 - RESTRICTED ACTIVITIES</h3>
              <p style={paragraphStyle}>
                Beyond the restrictions already outlined in the Terms of
                Service, you are expressly forbidden from engaging with the site
                or its contents for purposes that are: (a) illicit in any form;
                (b) designed to incite others to partake in or perform illegal
                deeds; (c) in breach of any local, state, national, or
                international laws, statutes, ordinances, or regulations; (d)
                aimed at infringing or violating our intellectual property
                rights or those of other entities; (e) intended to harass,
                abuse, insult, harm, defame, slander, vilify, intimidate, or
                discriminate against individuals based on gender, sexual
                orientation, religion, ethnicity, race, age, national origin, or
                disability; (f) involving the submission of erroneous or
                deceptive information; (g) to transmit or disseminate viruses or
                any malicious code designed to affect the functionality or
                operation of the Service, any associated websites, other
                websites, or the Internet; (h) to track or collect personal
                information of others unlawfully; (i) to engage in unsolicited
                advertising, phishing, pretexting, spidering, crawling, or
                scraping; (j) for purposes that are obscene, indecent, or in any
                way immoral; or (k) to interfere with or circumvent the security
                measures of the Service, any related website, other websites, or
                the Internet. We assert our right to terminate your access to
                the Service or any related website for engaging in any of the
                prohibited activities.
              </p>
            </div>

            {/* Section 13 */}
            <div style={sectionStyle}>
              <h3 style={headingStyle}>
                SECTION 13 - EXCLUSION OF GUARANTEES; RESTRICTION OF
                RESPONSIBILITY
              </h3>
              <p style={paragraphStyle}>
                We make no assertions, guarantees, or warranties that your
                engagement with our service will be uninterrupted, punctual,
                secure, or devoid of errors. Nor do we assure that the outcomes
                derived from your use of the service will be dependable or
                accurate.
              </p>
              <br />
              <p style={paragraphStyle}>
                You acknowledge and consent to the possibility that we might
                temporarily suspend the service for indefinite durations, or
                terminate the service at any juncture, all without the
                obligation of prior notification to you.
              </p>
              <br />
              <p style={paragraphStyle}>
                You explicitly acknowledge that your decision to use the
                service, or your incapacity to use it, rests entirely with you
                and at your sole risk. The service, along with all products and
                services rendered to you through the service, are provided 'as
                is' and 'as available' for your use, devoid of any form of
                representation, warranties, or conditions, be they explicit or
                implied, including but not limited to any implied warranties or
                conditions of merchantability, quality fit for a particular
                purpose, durability, title, and non-infringement.
              </p>
              <br />
              <p style={paragraphStyle}>
                Under no circumstances will Metrix Distributions, along with our
                directors, officers, employees, affiliates, agents, contractors,
                interns, suppliers, service providers, or licensors, be held
                accountable for any injuries, losses, claims, or any direct,
                indirect, incidental, punitive, special, or consequential
                damages of any kind. This includes, without limitation, lost
                profits, lost revenue, lost savings, loss of data, replacement
                costs, or similar damages, whether established in contract, tort
                (including negligence), strict liability, or otherwise, stemming
                from your use of any of the services or any products procured
                using the service, or for any other claim associated in any
                manner with your use of the service or any product. This
                encompasses, but is not limited to, any errors or omissions in
                any content, or any loss or damage of any kind incurred as a
                result of the use of the service or any content (or product)
                posted, transmitted, or otherwise made available via the
                service, even if advised of their potential. In jurisdictions
                that do not allow the exclusion or limitation of liability for
                consequential or incidental damages, our liability shall be
                confined to the greatest extent permitted by law.
              </p>
            </div>

            {/* Section 14 */}
            <div style={sectionStyle}>
              <h3 style={headingStyle}>SECTION 14 - OBLIGATION TO INDEMNIFY</h3>
              <p style={paragraphStyle}>
                You solemnly commit to indemnify, defend, and absolve Metrix
                Distributions along with our parent company, subsidiaries,
                affiliates, partners, officers, directors, agents, contractors,
                licensors, service providers, subcontractors, suppliers,
                interns, and employees from any claims, demands, including
                reasonable legal fees, that may arise from a third party due to
                or resulting from your contravention of these Terms of Service
                or the documents referenced herein, or your infringement of any
                law or the rights of a third-party.
              </p>
            </div>

            {/* Section 15 */}
            <div style={sectionStyle}>
              <h3 style={headingStyle}>SECTION 15 - DIVISIBILITY</h3>
              <p style={paragraphStyle}>
                Should it transpire that a particular stipulation within these
                Terms of Service is deemed illegal, null, or for any reason
                unenforceable, that stipulation shall nevertheless be enforced
                to the maximum extent permissible under relevant law.
                Furthermore, the portion deemed unenforceable shall be
                considered as excised from these Terms of Service. This specific
                judgment shall not impinge upon or diminish the validity and
                enforceability of the remaining provisions.
              </p>
            </div>

            {/* Section 16 */}
            <div style={sectionStyle}>
              <h3 style={headingStyle}>SECTION 16 - CONCLUSION OF AGREEMENT</h3>
              <p style={paragraphStyle}>
                The duties and responsibilities incurred by both parties before
                the date of conclusion shall persist beyond the cessation of
                this agreement for all intents and purposes.
              </p>
              <br />
              <p style={paragraphStyle}>
                These Terms of Service remain in force until they are concluded
                by either you or us. You possess the right to conclude these
                Terms of Service at any juncture by informing us of your
                decision to discontinue the use of our Services, or upon your
                cessation of usage of our site.
              </p>
              <br />
              <p style={paragraphStyle}>
                Should we, in our exclusive discretion, determine or suspect
                that you have not adhered to any clause or condition of these
                Terms of Service, we may bring this agreement to an end at any
                moment without prior notification. In such an event, you will
                continue to be accountable for all costs accrued up until and
                including the date of termination; and/or we may consequently
                deny you access to our Services, in whole or in part.
              </p>
            </div>

            {/* Section 17 */}
            <div style={sectionStyle}>
              <h3 style={headingStyle}>SECTION 17 - COMPREHENSIVE AGREEMENT</h3>
              <p style={paragraphStyle}>
                Our failure to assert a specific right or provision under these
                Terms of Service does not equate to a relinquishment of such
                right or provision.
              </p>
              <br />
              <p style={paragraphStyle}>
                The entirety of these Terms of Service, along with any policies
                or operational guidelines posted by us on this site or in
                relation to the Service, forms the complete agreement and mutual
                understanding between you and us. It governs your utilization of
                the Service and supersedes all prior or contemporaneous
                negotiations, discussions, agreements, and proposals, both
                verbal and written, between you and us (this includes, but is
                not limited to, any earlier iterations of the Terms of Service).
              </p>
              <br />
              <p style={paragraphStyle}>
                Should there be any uncertainty or vagueness in the
                interpretation of these Terms of Service, such ambiguity shall
                not be interpreted to the disadvantage of the party responsible
                for drafting.
              </p>
            </div>

            {/* Section 18 */}
            <div style={sectionStyle}>
              <h3 style={headingStyle}>
                SECTION 18 - JURISDICTIONAL LEGISLATION
              </h3>
              <p style={paragraphStyle}>
                The stipulations delineated in these Terms of Service, along
                with any distinct agreements through which we furnish you with
                Services, shall be subject to the governance and interpreted in
                alignment with the legislation of the United States. This
                encompasses all legal principles and regulatory frameworks that
                are applicable under the jurisdiction of the United States.
              </p>
            </div>

            {/* Section 19 */}
            <div style={sectionStyle}>
              <h3 style={headingStyle}>
                SECTION 19 - AMENDMENTS TO TERMS OF SERVICE
              </h3>
              <p style={paragraphStyle}>
                At your convenience, you are able to peruse the most up-to-date
                rendition of the Terms of Service on this particular webpage.
              </p>
              <br />
              <p style={paragraphStyle}>
                We assert the exclusive right and retain the prerogative, based
                on our sole judgment, to introduce updates, implement
                alterations, or effectuate replacements to portions of these
                Terms of Service by disseminating updates and modifications on
                our website. It falls under your purview to periodically review
                our website for any such changes. Your sustained engagement
                with, or access to, our website or the Service subsequent to the
                publication of any alterations to these Terms of Service
                signifies your acknowledgment and acceptance of those changes.
              </p>
            </div>

            {/* Section 20 */}
            <div style={sectionStyle}>
              <h3 style={headingStyle}>
                SECTION 20 - INQUIRIES AND COMMUNICATION
              </h3>
              <p style={paragraphStyle}>
                Should you have any inquiries or seek further clarification
                regarding the Terms of Service, please direct your questions to
                us via email at Support@Qualitywholesaleusa.com Your queries are
                important, and we aim to provide the necessary assistance
                promptly.
              </p>
            </div>

            {/* Section 21 */}
            <div style={sectionStyle}>
              <p style={paragraphStyle}>
                If you have any questions, please email us at
                Support@Qualitywholesaleusas.com or visit our FAQs. You can also
                contact to a real human during our operation hours. They can
                answer questions about your account or help you for your orders
                and many more.
              </p>
            </div>

            {/* Continue adding more sections in similar pattern... */}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default TermsOfService;
