import React from "react";
import { Container, Row, Col, Alert, Button } from "react-bootstrap";

function FailurePage() {
  return (
    <Container className="mt-5">
      <Row className="justify-content-md-center text-center">
        <Col md={8}>
          <h1
            className="mb-4"
            style={{
              color: "var(--main)",
            }}
          >
            Order Failed!
          </h1>
          <Alert
            variant="failure"
            style={{
              fontSize: 20,
              fontWeight: "bold",
            }}
          >
            Something went wrong with order please try again later.
            <br />
          </Alert>
          <Button
            variant="success"
            href="/contact"
            className="mt-3"
            style={{
              padding: "10px 20px",
              fontSize: 15,
              backgroundColor: "var(--orange)",
              borderColor: "white",
            }}
          >
            Contact US
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default FailurePage;
