export const googleMapsLink =
  "https://www.google.com/maps/place/Quality+wholesale+usa/@33.4301684,-94.0837575,17z/data=!3m1!4b1!4m6!3m5!1s0x863441935412b583:0xf7af3086db8aba28!8m2!3d33.4301639!4d-94.0811826!16s%2Fg%2F11sjdkt_2l?entry=ttu";

export const reCAPTCHA_KEY = "6LfAbD0pAAAAABcldosqrTASc2AuOd4QVKEKl5Ss";

export const APIS = {
  login: "/auth/login",
  categories_list: "/product-group/tree",
  products_by_category: "/products/byGroupId",
  feature_products: "/products/by-feature",
  product_details: "/products/details/id",
  get_cart: "/shop/get-cart",
  final_checkout: "/shop/final-checkout",
  checkout_by_credit: "/shop/payment-by-credit",
  add_item_to_cart: "shop/addItem",
  addItem: "shop/multiple/addItem",
  signUp: "/auth/signup",
  search: "/products/search",
  inquiry: "/inquiries/create",
  change_password: "/user/change/password",
  addCard: "/payment/add/card",
  viewCard: "/payment/get/card",
  removecard: "/payment/remove/card",
  order_list: "/shop/orders",
  user_profile:"/user/profile",
  update_profile:"/user/update/address"
};

export const DEFAULT_PRODUCT_IMAGE = "/assets/Images/productImage.png";
export const DEFAULT_CARD_IMAGE = "/images/generalCard.png";
