import { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import "./modal.css";

export default function AgeVerificationModal() {
  const [show, setShow] = useState(false);
  const [ageVerified, setAgeVerified] = useState(null);

  const handleNo = () => {
    setAgeVerified(false);
  };

  const handleYes = () => {
    const currentTime = new Date().getTime();
    sessionStorage.setItem("ageVerified_QW", "true");
    sessionStorage.setItem("verificationTime_QW", currentTime);
    setAgeVerified(true);
    setShow(false);
  };

  useEffect(() => {
    const ageVerified = sessionStorage.getItem("ageVerified_QW");
    const verificationTime = sessionStorage.getItem("verificationTime_QW");
    const currentTime = new Date().getTime();

    const sixHours = 6 * 60 * 60 * 1000;

    if (
      ageVerified &&
      verificationTime &&
      currentTime - verificationTime < sixHours
    ) {
      setAgeVerified(true);
    } else {
      setAgeVerified(null);
      sessionStorage.removeItem("ageVerified_QW");
      sessionStorage.removeItem("verificationTime_QW");
      setShow(true);
    }
  }, []);

  return (
    <Modal show={show} centered backdrop="static" keyboard={false}>
      <Modal.Header className="justify-content-center">
        <Modal.Title>
          <h3>Age Verification</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {ageVerified === false ? (
          <p className="text-danger">
            You are not old enough to view this content.
          </p>
        ) : (
          <p className="text-center">Are you over 21 years of age?</p>
        )}
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        {ageVerified === null && (
          <>
            <Button
              className="main-btn text-white undefined btn btn-none bg-danger border-0"
              variant="danger"
              onClick={handleNo}
            >
              No
            </Button>
            <Button
              className="main-btn text-white undefined btn btn-none bg-success border-0"
              variant="primary"
              onClick={handleYes}
            >
              Yes
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
}
