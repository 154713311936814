import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import {
  Col,
  Container,
  Row,
  // Table,
  Button,
  Breadcrumb,
  Image,
} from "react-bootstrap";
// import { Zoom, Navigation, Pagination, Autoplay } from "swiper/modules";
import { SVGIcons } from "../../Components/Data/SVGIcons";
import ScrollToTopOnMount from "../../Components/ScrollToTopOnMount";
// import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/zoom";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { useUser } from "../../utils/UserContext";
import { APIS, DEFAULT_PRODUCT_IMAGE } from "../../utils/constants";
import axiosInstance from "../../utils/axiosInstance";
import Loader from "../../Components/Loader";

export default function ProductCard() {
  const location = useLocation();
  const { user } = useUser();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [productData, setProductData] = useState({});
  const [quantity, setQuantity] = useState(1);
  const [cartUpdate, setCartUpdate] = useState({});

  const swiperRefLocal = useRef();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const productId = queryParams.get("product");
    const parsedProductId = parseInt(productId, 10);
    const source = axios.CancelToken.source();
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get(
          `${APIS.product_details}?productID=${parsedProductId}`,
          { cancelToken: source.token }
        );
        const responseData = response.data;
        setProductData(responseData);
        if (responseData.is_in_cart) {
          setQuantity(responseData.in_cart_quantity);
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled", error.message);
        } else {
          setError(error);
        }
      } finally {
        setLoading(false);
      }
    };
    if (!isNaN(parsedProductId) && parsedProductId > 0) {
      fetchData();
    } else {
      console.error("Invalid Product ID:", productId);
      setError(new Error("Invalid Product ID"));
      setProductData({});
    }
  }, []);

  const handleQuantityChange = (newQuantity) => {
    const updatedQuantity = Math.max(0, newQuantity); // Ensure the quantity is at least 0
    setQuantity(updatedQuantity);
    setCartUpdate({
      productId: productData.id,
      quantity: updatedQuantity,
    });
  };

  const addOrUpdateCart = async () => {
    if (cartUpdate && cartUpdate.productId) {
      setLoading(true);
      try {
        const response = await axiosInstance
          .post(`${APIS.add_item_to_cart}`, cartUpdate)
          .then(async () => {
            const response = await axiosInstance.get(
              `${APIS.product_details}?productID=${cartUpdate.productId}`
            );
            const responseData = response.data;
            setProductData(responseData);
            if (responseData.is_in_cart) {
              setQuantity(responseData.in_cart_quantity);
            }
            alert("Cart updated successfully");
          });
      } catch (error) {
        console.error("Cart Update Error", error);
        setError(error);
        alert(error?.message || "Something went wrong");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleMouseEnter = () => {
    swiperRefLocal?.current?.swiper?.autoplay?.stop();
  };

  const handleMouseLeave = () => {
    swiperRefLocal?.current?.swiper?.autoplay?.start();
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <ScrollToTopOnMount />
      <Container>
        <div className="bread-crumb space-top-small space-bottom-small">
          <Breadcrumb>
            <Breadcrumb.Item href="/">HOME</Breadcrumb.Item>
            {productData && productData.category_name && (
              <Breadcrumb.Item
                href={`/products?category=${productData.group_id}`}
              >
                {productData.category_name}
              </Breadcrumb.Item>
            )}
            {productData && productData.name && (
              <Breadcrumb.Item active>{productData.name}</Breadcrumb.Item>
            )}
          </Breadcrumb>
        </div>
      </Container>
      <section className="single-product-section position-relative">
        {productData && productData.name ? (
          <div className="single-product-contain space-top-small">
            <Container>
              <Row>
                <Col lg={6}>
                  <div
                    className=""
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <Image
                      src={` https://qw3images.blob.core.windows.net/products-qw/${productData?.code2}.png`}
                      className="img-contain"
                      alt={productData?.name?.en}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = DEFAULT_PRODUCT_IMAGE;
                      }}
                    />
                  </div>
                </Col>

                <Col lg={6}>
                  <div>
                    <h3 className="product-name fw-600 mb-4">
                      {productData.name}
                    </h3>

                    <div className="information-style-one">
                      <div className="mb-5">
                        <span
                          className="ms-3"
                          style={{
                            color: "black",
                            fontWeight: "bold",
                            fontSize: "15px",
                            margin: "0 10px 0 0",
                          }}
                        >
                          UPC: {productData?.code2 ? productData?.code2 : "N/A"}{" "}
                        </span>
                        <div className="icon d-align-center mb-2">
                          {user && user.isVerified && (
                            <span
                              className="ms-3"
                              style={{
                                color:
                                  productData.stock &&
                                  productData.stock.free > 0
                                    ? "green"
                                    : "var(--main)",
                              }}
                            >
                              {productData.stock && productData.stock.free > 0
                                ? `${productData.stock.free} Units Available`
                                : "Out of Stock"}
                            </span>
                          )}
                        </div>
                        {user && user.isVerified ? (
                          <div>
                            <div className="icon d-align-center mb-2">
                              <span
                                className="ms-3"
                                style={{ color: "var(--main)" }}
                              >
                                <b>Price :</b> {productData.price} USD
                              </span>
                            </div>
                            <div
                              className="d-align-center mb-2"
                              style={{ paddingLeft: "10px" }}
                            >
                              <Button
                                style={{
                                  backgroundColor: "var(--main)",
                                  borderColor: "white",
                                  borderRadius: 15,
                                }}
                                onClick={() =>
                                  handleQuantityChange(quantity - 1)
                                }
                              >
                                <span style={{ padding: 5, fontSize: "15px" }}>
                                  -
                                </span>
                              </Button>
                              <span
                                style={{
                                  paddingLeft: "10px",
                                  paddingRight: "10px",
                                }}
                              >
                                {quantity}
                              </span>
                              <Button
                                style={{
                                  backgroundColor: "var(--orange)",
                                  borderColor: "white",
                                  borderRadius: 15,
                                }}
                                onClick={() =>
                                  handleQuantityChange(quantity + 1)
                                }
                              >
                                <span style={{ padding: 5, fontSize: "15px" }}>
                                  +
                                </span>
                              </Button>
                            </div>
                            <div className="update-cart">
                              <Button
                                style={{
                                  marginTop: 5,
                                  backgroundColor: "var(--orange)",
                                  fontSize: "18px",
                                  borderColor: "white",
                                  color: "white",
                                  marginLeft: "8px",
                                  borderRadius: 10,
                                  padding: "5px, 5px",
                                }}
                                onClick={addOrUpdateCart}
                                disabled={
                                  productData.in_cart_quantity === quantity
                                }
                                variant="success"
                              >
                                {productData.is_in_cart
                                  ? "Update Cart"
                                  : "Add to Cart"}
                              </Button>
                              <Button
                                style={{
                                  marginTop: 5,
                                  backgroundColor: "var(--orange)",
                                  fontSize: "18px",
                                  borderColor: "white",
                                  color: "white",
                                  marginLeft: "50px",
                                  borderRadius: 10,
                                  padding: "5px, 5px",
                                }}
                                variant="success"
                                href="/my-cart"
                              >
                                Go To Cart
                              </Button>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="icon d-align-center mb-2">
                        {SVGIcons.Chats}{" "}
                        <span className="ms-3">
                          Have a question about this?{" "}
                          <Link to="/contact" className="text-orange">
                            Click here
                          </Link>
                        </span>
                      </div>

                      <div className="icon d-align-center mb-2">
                        {SVGIcons.Location}{" "}
                        <span className="ms-3">Delivery & Return</span>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        ) : (
          <div className="single-product-contain space-top-small">
            <Container>
              <Row>
                <Col>
                  <h3 style={{ fontWeight: "400" }}>
                    Sorry, we can not find the item that you are looking for !{" "}
                    <Link
                      to="/"
                      style={{
                        color: "var(--orange)",
                      }}
                    >
                      Go to Home Page
                    </Link>
                  </h3>
                </Col>
              </Row>
            </Container>
          </div>
        )}
      </section>
    </>
  );
}
