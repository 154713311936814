import { Col, Container, Row } from "react-bootstrap";
import Title from "../../Components/Title/Title";
import CustomButton from "../../Components/Button";
import "./About.css";

export default function AboutUs() {
  const sectionStyle = {
    padding: "20px",
    marginBottom: "20px",
  };

  const headingStyle = {
    color: "#333",
    marginBottom: "15px",
  };

  const paragraphStyle = {
    color: "#555",
    lineHeight: "1.6",
    textAlign: "justify",
  };
  return (
    <>
      <Title maintext="About Us" mainpage="Home" subpage="About Us" />
      <section className="about-section position-relative">
        <div className="about-contain space-top space-bottom">
          <Container>
            <Row className="row-gap-10">
              <Col lg={6} className="position-relative d-center">
                <img
                  className=""
                  src={"/assets/Images/about.png"}
                  alt="About Us"
                />
                <img
                  className="position-absolute left-0 bottom-0 floating d-none d-lg-block"
                  src={"/assets/Images/circle-logo-2.png"}
                  alt="'/assets/Images/circle-logo-2.png'"
                />
              </Col>
              <Col lg={6}>
                <div className="title">About Us</div>
                <h2> A Digital Marketing Agency In London</h2>
                <p className="mt-5 text-gray mb-5 mid">
                  Welcome to Thirstyfish, a digital marketing agency based in
                  London. Our honest and transparent approach speaks for itself.
                  Commitment to detail without losing sight of the outcome, hard
                  work combined with talent - these attributes have made our
                  business grow organically since 2009. We deliver personal,
                  passionate & tailored services to our clients in London and
                  abroad.
                </p>

                <div>
                  <ul className="list-style-one">
                    <li>Branding</li>
                    <li>Web Development</li>
                    <li>Digital Marketing</li>
                    <li>Content Production</li>
                    <li>Consulting</li>
                  </ul>
                </div>
                <CustomButton
                  btnClassName="shadow-btn mt-5"
                  text={<span className="">More</span>}
                />
              </Col>
              <Col lg={6}>
                <h2>Our Story - Quality Wholesale</h2>
                <div style={sectionStyle}>
                  <h3 style={headingStyle}>The Journey Begins :</h3>
                  <p style={paragraphStyle}>
                    In 2022, amidst a rapidly evolving global market, Quality
                    Wholesale was born with a singular vision: to redefine the
                    standards of wholesale trading. Our founders, a diverse
                    group of industry veterans and entrepreneurial spirits, saw
                    an opportunity to create a company that would not only
                    supply products but also build partnerships based on trust,
                    quality, and unparalleled service.
                  </p>
                </div>

                <div style={sectionStyle}>
                  <h3 style={headingStyle}>Rising to the Challenge :</h3>
                  <p style={paragraphStyle}>
                    Starting a new venture in a competitive industry was no
                    small feat, especially during a time of global economic
                    uncertainty. However, Quality Wholesale was up for the
                    challenge. We set out with a clear goal - to offer a wide
                    range of high-quality products at competitive prices, backed
                    by a customer service experience that was second to none.
                  </p>
                </div>

                <div style={sectionStyle}>
                  <h3 style={headingStyle}>Building on Success :</h3>
                  <p style={paragraphStyle}>
                    In a short span of time, Quality Wholesale has grown from a
                    promising startup to a thriving business. Our success is
                    rooted in our commitment to our core values: integrity,
                    customer satisfaction, and continuous improvement. We pride
                    ourselves on being more than just a supplier; we are a
                    reliable partner to businesses of all sizes.
                  </p>
                </div>

                <div style={sectionStyle}>
                  <h3 style={headingStyle}>Our Approach:</h3>
                  <p style={paragraphStyle}>
                    {" "}
                    Our approach is simple yet effective: understand the unique
                    needs of each client and deliver solutions that not only
                    meet but exceed their expectations. Whether it's through
                    offering a diverse product portfolio, flexible pricing
                    models, or personalized customer service, we strive to add
                    value to every interaction.
                  </p>
                </div>

                <div style={sectionStyle}>
                  <h3 style={headingStyle}>Looking to the Future:</h3>
                  <p style={paragraphStyle}>
                    As we look towards the future, Quality Wholesale is
                    dedicated to expanding its reach while maintaining the
                    quality and service standards that our clients have come to
                    expect. We are constantly exploring new markets, embracing
                    innovative technologies, and strengthening our supply chain
                    to ensure that we continue to meet the evolving needs of our
                    customers.
                  </p>
                </div>

                <div style={sectionStyle}>
                  <h3 style={headingStyle}>Join Us on Our Journey</h3>
                  <p style={paragraphStyle}>
                    At Quality Wholesale, every client, big or small, is a part
                    of our story. We invite you to join us on this exciting
                    journey as we continue to grow, innovate, and lead in the
                    wholesale industry. Together, let's build a future where
                    quality and value go hand in hand.
                  </p>
                </div>

                <div style={sectionStyle}>
                  <h3 style={headingStyle}>Why Choose Us?</h3>
                  <p style={paragraphStyle}>
                    Extensive Range: A vast selection of products to meet all
                    your wholesale needs.
                  </p>
                  <br />
                  <p style={paragraphStyle}>
                    Competitive Pricing: Exceptional value without compromising
                    on quality Personalized Service: A dedicated team to assist
                    with personalized requirements and ensure customer
                    satisfaction.
                  </p>
                  <br />
                  <p style={paragraphStyle}>
                    Reliability: Timely and efficient delivery with a focus on
                    ensuring your business continuity. Quality Assurance:
                    Rigorous quality control to guarantee product excellence.
                  </p>
                  <br />
                  <p style={paragraphStyle}>
                    At Quality Wholesale, we're more than just a supplier; we're
                    a partner in your business's success. For us, quality isn't
                    just a promise; it's our name.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
}
