import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ScrollToTopOnMount from "../../Components/ScrollToTopOnMount";
import "./privacyPolicy.css";

function PrivacyPolicy() {
  return (
    <>
      <ScrollToTopOnMount />
      <Container
        style={{
          paddingTop: "25px",
        }}
      >
        <Row>
          <Col className="justify-text">
            <h2>Privacy Policy</h2>
            <p style={{ paddingTop: "5px", paddingBottom: "5px" }}>
              <strong>
                Last updated: {new Date(2023, 11, 23).toDateString()}
              </strong>
            </p>
            <p style={{ paddingTop: "5px", paddingBottom: "5px" }}>
              Where your privacy and data protection are of utmost importance.
              This Privacy Policy outlines our practices concerning the
              collection, use, and safeguarding of your personal information.
            </p>
            <h3>1. Information We Collect</h3>
            <p style={{ paddingTop: "5px", paddingBottom: "5px" }}>
              <strong>Personal Identification Information: </strong> Includes
              your full name, email address, mailing address, phone number, and
              other contact details you provide when you register, place an
              order, or contact us.
            </p>
            <p style={{ paddingTop: "5px", paddingBottom: "5px" }}>
              <strong>Financial Information: </strong> Credit/debit card numbers
              or other payment information for transaction processing. We use
              secure, encrypted channels for payment processing and do not store
              your full card details.
            </p>
            <p style={{ paddingTop: "5px", paddingBottom: "5px" }}>
              <strong>Usage and Technical Data: </strong> Includes details such
              as your IP address, browser type, operating system, page views,
              and navigation patterns on our website, collected via cookies and
              analytics tools.
            </p>
            <p style={{ paddingTop: "5px", paddingBottom: "5px" }}>
              <strong>Customer Feedback: </strong> Any information you provide
              through surveys, reviews, or feedback forms.
            </p>
            <h3>2. How We Use Your Information</h3>
            <p style={{ paddingTop: "5px", paddingBottom: "5px" }}>
              <strong>Order Fulfillment: </strong> To process, confirm, and
              fulfill your orders, including managing payments and communicating
              with you about your orders.
            </p>
            <p style={{ paddingTop: "5px", paddingBottom: "5px" }}>
              <strong>Customer Service: </strong> To provide customer support,
              respond to inquiries, and resolve any issues.
            </p>
            <p style={{ paddingTop: "5px", paddingBottom: "5px" }}>
              <strong>Improving Our Services: </strong> For internal research
              and development purposes, to understand customer preferences,
              enhance user experience, and improve our products and services.
            </p>
            <p style={{ paddingTop: "5px", paddingBottom: "5px" }}>
              <strong>Legal Compliance: </strong> To comply with legal and
              regulatory obligations, such as tax and financial reporting
              requirements.
            </p>
            <h3>3. Sharing Your Information</h3>
            <p style={{ paddingTop: "5px", paddingBottom: "5px" }}>
              <strong>Third-Party Service Providers: </strong> We may share
              information with trusted partners who provide services such as
              hosting, payment processing, analytics, marketing, and customer
              service. These partners are prohibited from using your personal
              information for any purpose other than to provide these services.
            </p>
            <p style={{ paddingTop: "5px", paddingBottom: "5px" }}>
              <strong> Legal Requirements: </strong> We may disclose your
              information if required by law, such as to comply with a subpoena
              or other legal processes, or to protect and defend our rights and
              property. Business Transfers: In the event of a merger,
              acquisition, or asset sale, your personal information may be
              transferred.
            </p>
            <h3>4. Data Security</h3>
            <p style={{ paddingTop: "5px", paddingBottom: "5px" }}>
              We have implement a variety of security measures, including secure
              servers, firewalls, and encryption technologies, to protect your
              personal information against unauthorized access, alteration,
              disclosure, or destruction.
            </p>

            <h3>5. Cookies and Tracking Technologies</h3>
            <p style={{ paddingTop: "5px", paddingBottom: "5px" }}>
              <strong>Cookies: </strong> Small data files placed on your device
              to enhance site functionality, understand site usage, and improve
              user experience.
            </p>
            <p style={{ paddingTop: "5px", paddingBottom: "5px" }}>
              <strong>Analytics Tools: </strong> We use tools like Google
              Analytics to gather data about website traffic and user
              interactions.
            </p>

            <h3>6. Your Rights and Choices</h3>
            <p style={{ paddingTop: "5px", paddingBottom: "5px" }}>
              Opt-Out of Marketing Communications: You can opt-out of receiving
              marketing communications from us by following the unsubscribe
              instructions in the emails.
            </p>
            <h3>7. Third-Party Links</h3>
            <p style={{ paddingTop: "5px", paddingBottom: "5px" }}>
              Our website may include links to external sites, which are not
              governed by this Privacy Policy. We recommend reviewing the
              privacy policies of these third-party sites.
            </p>
            <h3>8. Children's Privacy</h3>
            <p style={{ paddingTop: "5px", paddingBottom: "5px" }}>
              Our website and services are not intended for individuals under
              the age of 19. We do not knowingly collect personal information
              from children.
            </p>
            <h3>9. International Transfer of Data</h3>
            <p style={{ paddingTop: "5px", paddingBottom: "5px" }}>
              Your information may be transferred to and maintained on computers
              located outside your country where the data protection laws may
              differ.
            </p>
            <h3>10. Changes to This Policy</h3>
            <p style={{ paddingTop: "5px", paddingBottom: "5px" }}>
              We reserve the right to modify this Privacy Policy at any time.
              Any changes will be posted on this page with an updated revision
              date.
            </p>
            <h3>11. Contact Us</h3>
            <p style={{ paddingTop: "5px", paddingBottom: "5px" }}>
              If you have questions about this Privacy Policy or our treatment
              of your personal information, please contact us at{" "}
              <a href="mailto:qualitywholesaleus@gmail.com">
                <span style={{ color: "#212f64" }}>
                  qualitywholesaleus@gmail.com{" "}
                </span>
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default PrivacyPolicy;
