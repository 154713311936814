import Slider from "../../Components/Slider/Slider";
import Contact from "../../Components/Contact/Contact";
import Inquiry from "../../Components/Inquiry/Inquiry";

export default function Contacts() {
  return (
    <>
      {/* <Slider /> */}
      {/* <Contact /> */}
      <Inquiry />
    </>
  );
}
