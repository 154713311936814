import axios from "axios";
import Cookies from "js-cookie";
import { APIS } from "./constants";

export const loginUser = async (email, password, recaptchaToken) => {
  try {
    if (!email || !password || !recaptchaToken) {
      return;
    } else {
      const response = await axios.post(
        `${process.env.REACT_APP_serverUrl}${APIS.login}`,
        {
          email,
          password,
          recaptchaToken,
        }
      );
      const token = response.data.access_token;
      const isVerified = response.data.isVerified;
      const isAdmin = response.data.isAdmin;
      Cookies.set("QW_authToken", token, { expires: 1 / 12 }); // 2 hours (1/12 of a day)

      return { success: true, isVerified: isVerified, isAdmin: isAdmin };
    }
  } catch (error) {
    return { success: false, error: error.response };
  }
};

export const logoutUser = async () => {
  Cookies.remove("QW_authToken");

  // Optional: Call an API endpoint to invalidate the token on the server
  // await axios.post('BACKEND_API_URL/logout');
};
