import React from "react";
import { Navigate } from "react-router-dom";
import { useUser } from "../utils/UserContext";

const ProtectedRoute = ({ children }) => {
  const { user } = useUser();
  const isAuthenticated = () => {
    return user;
  };

  return isAuthenticated() ? children : <Navigate to="/login" replace />;
};

export default ProtectedRoute;
