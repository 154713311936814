import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./inquiry.css";
import axiosInstance from "../../utils/axiosInstance";
import { APIS } from "../../utils/constants";

export default function Inquiry() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const validateForm = () => {
    const newErrors = {};

    // Validate full name
    if (!formData.name.trim()) {
      newErrors.name = "Full Name is required";
    }

    // Validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (formData.email.trim().length === 0) {
      newErrors.email = "Email is required";
    } else if (
      !formData.email.trim() ||
      !emailRegex.test(formData.email.trim())
    ) {
      newErrors.email = "Please enter a valid email address";
    }

    // Validate phone number (you might need to adjust the validation based on your requirements)
    const phoneRegex = /^[0-9]*$/;
    if (formData.phone.trim().length === 0) {
      newErrors.phone = "Phone number is required";
    } else if (
      formData.phone.trim() &&
      !phoneRegex.test(formData.phone.trim())
    ) {
      newErrors.phone = "Please enter a valid phone number";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if there are no errors
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      // Form is valid, make the API call here
      try {
        console.log("API call payload:", formData);
        // Example API call (replace with your actual API endpoint and logic)
        const response = await axiosInstance.post(APIS.inquiry, formData);
        if (response?.data) {
          // API call successful, you can handle the response accordingly
          console.log("API call successful:", response);
          alert("Inquiry sent successfully");
          setFormData({
            name: "",
            email: "",
            phone: "",
            subject: "",
            message: "",
          });
          setErrors({});
          navigate("/");
        } else {
          // API call failed
          console.error("API call failed:", response.statusText);
          alert(response.statusText + "Something went wrong");
        }
      } catch (error) {
        console.error("Error during API call:", error.message);
        alert(error.message);
      }
    }
  };

  const handleChange = (e) => {
    if (e.target.name === "name") {
      e.target.value.trim().length < 56 &&
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
    } else if (e.target.name === "email") {
      e.target.value.trim().length <= 26 &&
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
    } else if (e.target.name === "phone") {
      e.target.value.trim().length <= 10 &&
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }

    // Clear error message when the user starts typing
    setErrors({
      ...errors,
      [e.target.name]: "",
    });
  };
  return (
    <>
      <h1 className="header">
        <em>Have Questions or Need Support?</em>
      </h1>
      <blockquote className="quote">
        <em>
          Our team at Quality Wholesale is always ready to assist you. Whether
          you're looking for product information, need help with an order, or
          want to share your feedback, we're here to listen and help. Reach out
          to us through our phone line, email, or visit our office for
          personalized assistance. You can also fill out our online contact form
          available on our subject, and we'll get back to you promptly. Your
          satisfaction is our top priority, and we look forward to hearing from
          you.
        </em>
      </blockquote>
      <div className="inquiry-container">
        <div className="left">
          <div className="form-container">
            <h2 className="form-heading">
              Fill out the form below to get started:
            </h2>
            <form>
              <input
                type="text"
                name="name"
                placeholder="Full Name*"
                required
                className="input-field"
                value={formData.name}
                onChange={handleChange}
              />
              {errors.name && (
                <span className="error-message">{errors.name}</span>
              )}

              <input
                type="email"
                name="email"
                placeholder="Email Address*"
                required
                className="input-field"
                value={formData.email}
                onChange={handleChange}
              />
              {errors.email && (
                <span className="error-message">{errors.email}</span>
              )}

              <input
                type="tel"
                name="phone"
                required
                placeholder="Phone*"
                className="input-field"
                value={formData.phone}
                onChange={handleChange}
              />
              {errors.phone && (
                <span className="error-message">{errors.phone}</span>
              )}
              <input
                type="text"
                name="subject"
                placeholder="Reason for contact"
                className="input-field"
                value={formData.subject}
                onChange={handleChange}
              />
              <textarea
                name="message"
                placeholder="Text your message"
                className="input-field"
                value={formData.message}
                onChange={handleChange}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit(e);
                  }
                }}
              ></textarea>
              <button
                type="submit"
                className="submit-button"
                onClick={handleSubmit}
              >
                Contact Us
              </button>
            </form>
          </div>
        </div>
        <div className="right">
          <div className="info-container">
            <h2>
              <em>General Inquiries</em>
            </h2>
            <p>Have a question... or just want to say hello? Get in touch:</p>
            <a href="mailto:qualitywholesaleus@gmail.com">
              qualitywholesaleus@gmail.com
            </a>
            <br />
            <a href="tel:+1 (430) 200-4319">(430) 200-4319</a>
            <h3>
              <em>Our Location</em>
            </h3>
            <address>
              1419 N Robinson Rd,
              <br />
              Texarkana TX 77501,
              <br />
              United States.
            </address>
            <a href="https://maps.app.goo.gl/LoRn6GWGpTHBuw6d9">
              Get Directions
            </a>
            <h2>
              <em>Quality Wholesale Newsletter</em>
            </h2>
            <p>Exclusive digital marketing & advertising insights</p>
            <a href="#">
              <b>Sign Up Here</b>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
