import React from "react";
import { Button, Container } from "react-bootstrap";
import ScrollToTopOnMount from "../../Components/ScrollToTopOnMount";
import { useNavigate } from "react-router-dom";

export default function UnderReview() {
  const navigate = useNavigate();
  return (
    <>
      <ScrollToTopOnMount />
      <Container
        className="d-flex flex-column justify-content-center align-items-center"
        style={{ paddingTop: "100px" }}
      >
        <h1 className="text-2xl md:text-4xl font-bold text-center mb-4">
          Thank You for Registering!
        </h1>
        <p className="text-center mb-6 px-4 pb-5">
          <span style={{ color: "var(--orange)" }}>
            Your account is under review.
          </span>{" "}
          We will notify you within 24-48 hours upon approval. Further
          instructions have been sent to your{" "}
          <span style={{ color: "var(--main)" }}>email address</span>.
        </p>
        <Button
          size="lg"
          style={{
            fontSize: "20px",
            backgroundColor: "var(--main)",
            borderColor: "white",
            borderRadius: 12,
          }}
          onClick={() => navigate("/")}
        >
          <span style={{ padding: 5 }}>← Back to Home</span>
        </Button>
      </Container>
    </>
  );
}
