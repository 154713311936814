import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Title.css";
const Title = (props) => {
  return (
    <>
      <section className="title-section position-relative">
        <div className="title-contain">
          <Container>
            <Row className="row-gap-10">
              <Col lg={6}>
                <div>
                  <h1 className="title-inner-text">{props.maintext}</h1>
                  <ul className="d-flex gap-4">
                    <li className="arrow-btn gray d-align-center ">
                      <Link to="/">{props.mainpage}</Link>
                    </li>
                    <li>
                      <Link className="text-orange" to="/">
                        <u>{props.subpage}</u>
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col lg={6} className=" position-relative">
                <video
                  className=""
                  autoplay="autoplay"
                  loop="loop"
                  muted="muted"
                  playsinline="playsinline"
                  src={"/assets/Video/about.mp4"}
                ></video>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
};

export default Title;
