import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Row, Col, Button, Form, Card } from "react-bootstrap";
import axios from "axios";
import axiosInstance from "../../utils/axiosInstance";
import { APIS, DEFAULT_CARD_IMAGE } from "../../utils/constants";
import Loader from "../../Components/Loader";

const sampleItems = [
  {
    name: "Awesome Widget",
    quantity: 1,
    priceOfOne: 99.99,
    FeatureImage: "/assets/Images/productImage.png",
    FeatureTitle: "Awesome Widget",
  },
  {
    name: "Great Gadget",
    quantity: 2,
    priceOfOne: 42.5,
    FeatureImage: "/assets/Images/productImage.png",
    FeatureTitle: "Great Gadget",
  },
];

export default function CheckoutPage() {
  const navigate = useNavigate();
  const [nameOnCard, setNameOnCard] = useState("");
  const [nameOnCardError, setNameOnCardError] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cardNumberError, setCardNumberError] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [expiryDateError, setExpiryDateError] = useState("");
  const [cvv, setCvv] = useState("");
  const [cvvError, setCvvError] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [emailAddressError, setEmailAddressError] = useState("");
  const [billingAddressLine1, setBillingAddressLine1] = useState("");
  const [billingAddressLine1Error, setBillingAddressLine1Error] = useState("");
  const [billingAddressLine2, setBillingAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [cityError, setCityError] = useState("");
  const [state, setState] = useState("");
  const [stateError, setStateError] = useState("");
  const [zip, setZip] = useState("");
  const [zipError, setZipError] = useState("");
  const [country, setCountry] = useState("USA");
  const [countryError, setCountryError] = useState("");

  const handleAddNewCard = () => {
    setPaymentMethod("card");
    setShowCreditDetails(true);
  };
  const [saveCard, setSaveCard] = useState(false);
  console.log(saveCard, "saveCard");
  const handleSaveCardChange = (e) => {
    setSaveCard(e.target.checked);
  };
  const handleCVVChange = (e) => {
    let input = e.target.value;
    input = input.replace(/\D/g, "");
    input = input.slice(0, 4);
    setCvv(input);
    if (input.length < 3 || input.length > 4) {
      setCvvError("CVV should contain 3-4 digits");
    } else {
      setCvvError("");
    }
  };
  const handleExpiryDateChange = (e) => {
    let input = e.target.value;
    input = input.replace(/\D/g, "");
    if (input.length > 2) {
      input = input.slice(0, 2) + "/" + input.slice(2);
    }
    input = input.slice(0, 5);
    setExpiryDate(input);
    if (!input || !/^\d{2}\/\d{2}$/.test(input)) {
      setExpiryDateError("Expiry date should contain exactly 4 digits");
    } else {
      setExpiryDateError("");
    }
  };
  const [items, setItems] = useState(sampleItems);
  const [totalPrice, setTotalPrice] = useState("N/A");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [checkoutData, setCheckoutData] = useState([]);
  const [creditDetails, setCreditDetails] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [responseData, setResponseData] = useState([])
  const handleGoBack = () => {
    navigate(-1);
  };
  const [selectedIdx, setSelectedIdx] = useState(null);

  const handleCardClick = (idx) => {
    setSelectedIdx(idx === selectedIdx ? null : idx);
  };

  useEffect(() => {
    const source = axios.CancelToken.source();

    const fetchData = async () => {
      try {
        setLoading(true);

        const request1 = axiosInstance.get(`${APIS.final_checkout}`, {
          cancelToken: source.token,
        });

        const [response1, viewCardResponse] = await Promise.all([
          request1,
        ]);

        const responseData1 = response1.data;
        console.log(responseData1, "responseData1");
        setCreditDetails(responseData1?.creditDetails);
        setCheckoutData(responseData1?.cart);
        setTotalPrice(responseData1?.totalCartPrice);
        setResponseData(viewCardResponse.data);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled", error.message);
        } else {
          setError(error);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    return () => {
      source.cancel("Component unmounted, canceling API requests");
    };
  }, []);

  const handleCheckout = async () => {
    try {
      let hasError = false;
  
      if (!termsAccepted) {
        setTermsError(true);
        alert("Please accept the Terms and Conditions to proceed");
        hasError = true;
      } else {
        setTermsError(false);
      }
  
      if (hasError || !creditDetails.hasCredit || !creditDetails.canMakeCreditPurchase) {
        return;
      }
  
      setLoading(true);
  
      if (saveCard) {
        // Code for adding and viewing card
      }
  
      const checkoutResponse = await axiosInstance.get(
        `${APIS.checkout_by_credit}`
      );
  
      if (checkoutResponse.data?.message === "Success") {
        navigate("/success-payment");
        window.location.reload();
      } else {
        navigate("/failed-payment");
      }
    } catch (error) {
      navigate("/failed-payment");
      console.log("Payment API Error", error);
    }
  };
  

  const [showCreditDetails, setShowCreditDetails] = useState(false);
  const [showCreditButton, setShowCreditButton] = useState(true);
  const handlePaymentMethodChange = (method) => {
    setPaymentMethod(method);
    if (method === "credit") {
      setShowCreditButton(true);
    } else {
      setShowCreditButton(false);
    }
  };

  if (loading) {
    return <Loader />;
  }
  const handleTermsChange = (e) => {
    setTermsAccepted(e.target.checked);
    if (e.target.checked) {
      setTermsError(false);
    }
  };

  if (loading) {
    return <Loader />;
  }
  const handleCheckboxChange = (idx) => {};

  const handleEdit = (idx) => {};
  const cardTypeImages = {
    Mastercard: "mastercard.png",
    AmericanExpress: "american-express.png",
    Discovery: "discovery.png",
    PayPal: "paypal.png",
    Visa: "visa.png",
  };
  const handleRemove = async (idx, item) => {
    try {
      const paymentProfileId = String(item.customerPaymentProfileId);
      console.log("Removing card:", paymentProfileId);
      console.log(typeof item.customerPaymentProfileId);
      const response = await axiosInstance.delete(APIS.removecard, {
        customerPaymentProfileId: " " + paymentProfileId,
      });
      console.log("Card removed successfully:", response.data);
    } catch (error) {
      console.error("Error removing card:", error);
    }
  };

  return (
    <Container className="mt-5">
      <Row>
        <Col md={6}>
          <div>
            <h2 className="mb-4" style={{ fontSize: "40px" }}>
              Order <span className="fill-text orange">Summary</span>
            </h2>
            {checkoutData.map((item, idx) => (
              <Form.Group
                as={Row}
                key={idx}
                className="align-items-center mb-3"
              >
                <Col xs={3}>
                  <img
                    src={` https://qw3images.blob.core.windows.net/products-qw/${item?.code2}.png`}
                    alt={item.name}
                    className="img-fluid"
                    style={{ width: "100px", height: "100px" }}
                  />
                </Col>
                <Col xs={9}>
                  <Row>
                    <Col xs={8}>
                      <h5
                        className="mb-0"
                        style={{ fontSize: "20px", fontWeight: "bold" }}
                      >
                        {item.name}
                      </h5>
                    </Col>
                    <Col xs={4} className="d-flex justify-content-end">
                      <span className="mx-2" style={{ fontSize: "18px" }}>
                        {item.quantity}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-right">
                      <p className="mt-2">
                        Price:{" "}
                        {`$${item.priceOfOne} x ${item.quantity} = $${item.totalPrice}`}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-right">
                      <p>
                        Category:{" "}
                        <a
                          href={`products?category=${item?.groupID}`}
                          style={{ color: "#212f64" }}
                          onMouseOver={(e) =>
                            (e.target.style.color = "#c02233")
                          }
                          onMouseOut={(e) => (e.target.style.color = "#212f64")}
                        >
                          {item?.groupName?.toLowerCase()}
                        </a>
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Form.Group>
            ))}
          </div>
        </Col>
        {!loading && (
          <Col
            md={4}
            className="bg-blue text-white p-3 mx-auto"
            style={{
              borderRadius: "15px",
              padding: "20px",
              border: "1px solid var(--orange)",
              marginLeft: "20px",
            }}
          >
            <h3
              className="mb-4"
              style={{ fontWeight: "bold", color: "var(--orange)" }}
            >
              Payment Details
            </h3>
            <hr style={{ backgroundColor: "white" }} />
            <h4
              className="mb-3"
              style={{ marginTop: "20px", color: "var(--orange)" }}
            >
              Payment Method
            </h4>
            <div className="d-flex mb-3">
              {/* <Button
                variant="light"
                disabled={true}
                onClick={() => handlePaymentMethodChange("card")}
                className="rounded-pill border-0 me-2"
                style={{
                  width: "100px",
                  fontWeight: "bold",
                  fontSize: "14px",
                  backgroundColor: "var(--main)",
                  color: "white",
                  padding: "10px 20px",
                  transition: "background-color 0.3s ease",
                }}
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = "var(--orange)";
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = "var(--main)";
                }}
              >
                Card
              </Button> */}
              {/* {creditDetails.hasCredit && ( */}
                <Button
                  variant="light"
                  onClick={() => handlePaymentMethodChange("credit")}
                  className="rounded-pill border-0"
                  style={{
                    width: "100px",
                    fontWeight: "bold",
                    fontSize: "14px",
                    border: "3px solid Red",
                    backgroundColor: "var(--main)",
                    color: "white",
                    padding: "10px 20px",
                    transition: "background-color 0.3s ease",
                  }}
                  disabled={!creditDetails?.canMakeCreditPurchase}
                  onMouseEnter={(e) => {
                    e.target.style.backgroundColor = "var(--orange)";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = "var(--main)";
                  }}
                >
                  Credit
                </Button>
              {/* )} */}
            </div>
            {showCreditButton && (
             <>
             {(!creditDetails.hasCredit || !creditDetails.canMakeCreditPurchase) ? (
  <>
    <hr style={{ backgroundColor: "white" }}></hr>
    <h4
      className="mb-3"
      style={{
        marginTop: "20px",
        color: "var(--main)",
        fontSize: "18px",
      }}
    >
      Please contact admin
    support@qualitywholesaleusa.com
    </h4>
  </>
) : (
  creditDetails.hasCredit && creditDetails.canMakeCreditPurchase ? (
    // Show empty message if both conditions are true
    null
  ) : (
    <>
      <hr style={{ backgroundColor: "white" }}></hr>
      <h4
        className="mb-3"
        style={{
          marginTop: "20px",
          color: "var(--main)",
          fontSize: "18px",
        }}
      >
        Please contact admin
        support@qualitywholesaleusa.com
      </h4>
    </>
  )
)}

             </>
            )}

            {responseData.map(
              (item, idx) =>
                paymentMethod !== "credit" && (
                  <Card
                    key={idx}
                    style={{
                      width: "100%",
                      cursor: "pointer",
                      border:
                        idx === selectedIdx
                          ? "2px solid red"
                          : "1px solid blue",
                      borderRadius: "5px",
                    }}
                    onClick={() => handleCardClick(idx)}
                  >
                    <Card.Body className="d-flex justify-content-between align-items-center">
                      <div
                        className="d-flex align-items-center justify-content-between"
                        style={{ marginRight: "20px" }}
                      >
                        <Form.Group
                          controlId={`creditCardCheckbox-${idx}`}
                          className="d-flex align-items-center"
                          style={{ margin: "0" }}
                        >
                          <Form.Check
                            type="checkbox"
                            onChange={() => handleCheckboxChange(idx)}
                            checked={idx === selectedIdx}
                          />
                        </Form.Group>
                        <div style={{ margin: "0 20px" }}>
                          <img
                            src={`/images/${
                              cardTypeImages[item.payment.creditCard.cardType]
                            }`}
                            alt={item.payment.creditCard.cardType}
                            style={{ width: "50px", height: "auto" }}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = DEFAULT_CARD_IMAGE;
                            }}
                          />
                        </div>
                        <div
                          style={{ marginLeft: "20px", marginRight: "auto" }}
                        >
                          <div>
                            <p>{item.payment.creditCard.cardNumber}</p>
                            <p>{item.payment.creditCard.expirationDate}</p>
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                )
            )}

            {responseData.length > 0 &&
              !showCreditDetails &&
              paymentMethod !== "credit" && (
                <Button
                  style={{
                    backgroundColor: "var(--main)",
                    borderColor: "white",
                    fontSize: "20px",
                    marginTop: "20px",
                    width: "100%",
                  }}
                  onClick={handleAddNewCard}
                  size="lg"
                >
                  Add New card
                </Button>
              )}
            {(showCreditDetails || paymentMethod === "card") &&
              paymentMethod === "card" && (
                <>
                  <Form.Group controlId="nameOnCard" className="mt-3">
                    <Form.Label
                      className="mb-1"
                      style={{ color: "var(--orange)" }}
                    >
                      Name on Card
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter name on card"
                      className="mb-3 rounded-pill"
                      style={{
                        height: "40px",
                        fontSize: "14px",
                      }}
                      value={nameOnCard}
                      onChange={(e) => {
                        setNameOnCard(e.target.value);
                      }}
                    />
                    {nameOnCardError && (
                      <Form.Text className="text-danger">
                        {nameOnCardError}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group controlId="cardNumber" className="mb-3">
                    <Form.Label
                      className="mb-1"
                      style={{ color: "var(--orange)" }}
                    >
                      Card Number
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter card number"
                      className="mb-3 rounded-pill"
                      style={{
                        height: "40px",
                        fontSize: "14px",
                      }}
                      value={cardNumber}
                      onChange={(e) => {
                        setCardNumber(e.target.value);
                      }}
                    />
                    {cardNumberError && (
                      <Form.Text className="text-danger">
                        {cardNumberError}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group>
                    <div className="row mb-3">
                      <div className="col">
                        <label
                          className="mb-1"
                          style={{ color: "var(--orange)" }}
                        >
                          Expiry Date
                        </label>
                        <input
                          style={{
                            height: "30px",
                            fontSize: "14px",
                          }}
                          type="text"
                          className="form-control mb-1"
                          placeholder="MM/YY"
                          value={expiryDate}
                          onChange={handleExpiryDateChange}
                        />
                        {expiryDateError && (
                          <Form.Text className="text-danger">
                            {expiryDateError}
                          </Form.Text>
                        )}
                      </div>
                      <div className="col">
                        <label
                          className="mb-1"
                          style={{ color: "var(--orange)" }}
                        >
                          CVV
                        </label>
                        <input
                          type="password"
                          style={{
                            height: "30px",
                            fontSize: "14px",
                          }}
                          className="form-control mb-1"
                          placeholder="CVV"
                          value={cvv}
                          onChange={(e) => {
                            handleCVVChange(e);
                          }}
                        />
                        {cvvError && (
                          <Form.Text className="text-danger">
                            {cvvError}
                          </Form.Text>
                        )}
                      </div>
                    </div>
                  </Form.Group>
                  <Form.Group controlId="billingAddressLine1" className="mb-3">
                    <Form.Label
                      className="mb-1"
                      style={{ color: "var(--orange)" }}
                    >
                      Billing Address
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter billing address line 1"
                      className="mb-3 rounded-pill"
                      style={{
                        height: "40px",
                        fontSize: "14px",
                      }}
                      value={billingAddressLine1}
                      onChange={(e) => {
                        setBillingAddressLine1(e.target.value);
                      }}
                    />
                    {billingAddressLine1Error && (
                      <Form.Text className="text-danger">
                        {billingAddressLine1Error}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group controlId="city" className="mb-3">
                    <Form.Label
                      className="mb-1"
                      style={{ color: "var(--orange)" }}
                    >
                      City
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter city"
                      className="mb-3 rounded-pill"
                      style={{
                        height: "40px",
                        fontSize: "14px",
                      }}
                      value={city}
                      onChange={(e) => {
                        setCity(e.target.value);
                      }}
                    />
                    {cityError && (
                      <Form.Text className="text-danger">{cityError}</Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group controlId="zip" className="mb-3">
                    <Form.Label
                      className="mb-1"
                      style={{ color: "var(--orange)" }}
                    >
                      Zip
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter zip"
                      className="mb-3 rounded-pill"
                      style={{
                        height: "40px",
                        fontSize: "14px",
                      }}
                      value={zip}
                      onChange={(e) => {
                        setZip(e.target.value);
                      }}
                    />
                    {zipError && (
                      <Form.Text className="text-danger">{zipError}</Form.Text>
                    )}
                  </Form.Group>

                  <Form.Group>
                    <Form.Label
                      className="mb-1"
                      style={{ color: "var(--orange)" }}
                    >
                      State
                    </Form.Label>
                    <Form.Select
                      id="state"
                      name="state"
                      style={{
                        height: "40px",
                        fontSize: "14px",
                      }}
                      className="mb-3 rounded-pill"
                      value={state}
                      onChange={(e) => {
                        setState(e.target.value);
                      }}
                      aria-label="State"
                    >
                      <option value={null}>State</option>
                      <option value="New York">New York</option>
                      <option value="Alabama">Alabama</option>
                      <option value="Alaska">Alaska</option>
                      <option value="Arizona">Arizona</option>
                      <option value="Arkansas">Arkansas</option>
                      <option value="California">California</option>
                      <option value="Colorado">Colorado</option>
                      <option value="Connecticut">Connecticut</option>
                      <option value="Delaware">Delaware</option>
                      <option value="Florida">Florida</option>
                      <option value="Georgia">Georgia</option>
                      <option value="Hawaii">Hawaii</option>
                      <option value="Idaho">Idaho</option>
                      <option value="Illinois">Illinois</option>
                      <option value="Indiana">Indiana</option>
                      <option value="Iowa">Iowa</option>
                      <option value="Kansas">Kansas</option>
                      <option value="Kentucky">Kentucky</option>
                      <option value="Louisiana">Louisiana</option>
                      <option value="Maine">Maine</option>
                      <option value="Maryland">Maryland</option>
                      <option value="Massachusetts">Massachusetts</option>
                      <option value="Michigan">Michigan</option>
                      <option value="Minnesota">Minnesota</option>
                      <option value="Mississippi">Mississippi</option>
                      <option value="Missouri">Missouri</option>
                      <option value="Montana">Montana</option>
                      <option value="Nebraska">Nebraska</option>
                      <option value="Nevada">Nevada</option>
                      <option value="New Hampshire">New Hampshire</option>
                      <option value="New Jersey">New Jersey</option>
                      <option value="New Mexico">New Mexico</option>
                      <option value="New York">New York</option>
                      <option value="North Carolina">North Carolina</option>
                      <option value="North Dakota">North Dakota</option>
                      <option value="Ohio">Ohio</option>
                      <option value="Oklahoma">Oklahoma</option>
                      <option value="Oregon">Oregon</option>
                      <option value="Pennsylvania">Pennsylvania</option>
                      <option value="Rhode Island">Rhode Island</option>
                      <option value="South Carolina">South Carolina</option>
                      <option value="South Dakota">South Dakota</option>
                      <option value="Tennessee">Tennessee</option>
                      <option value="Texas">Texas</option>
                      <option value="Utah">Utah</option>
                      <option value="Vermont">Vermont</option>
                      <option value="Virginia">Virginia</option>
                      <option value="Washington">Washington</option>
                      <option value="West Virginia">West Virginia</option>
                      <option value="Wisconsin">Wisconsin</option>
                      <option value="Wyoming">Wyoming</option>
                    </Form.Select>
                    {stateError && (
                      <Form.Text className="text-danger">
                        {stateError}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group controlId="country" className="mb-3">
                    <Form.Label
                      className="mb-1"
                      style={{ color: "var(--orange)" }}
                    >
                      Country
                    </Form.Label>
                    <Form.Select
                      id="country"
                      name="country"
                      style={{
                        height: "40px",
                        fontSize: "14px",
                      }}
                      className="mb-3 rounded-pill"
                      value={country}
                      onChange={(e) => {
                        setCountry(e.target.value);
                        const selectedCountry = e.target.value;
                        console.log("Selected country:", selectedCountry);
                      }}
                      aria-label="Country"
                      defaultValue={"USA"}
                    >
                      <option disabled>Country</option>
                      <option selected value="USA">
                        USA
                      </option>
                    </Form.Select>
                    {countryError && (
                      <Form.Text className="text-danger">
                        {countryError}
                      </Form.Text>
                    )}
                  </Form.Group>

                  <div>
                    <input
                      type="checkbox"
                      id="saveCard"
                      onChange={handleSaveCardChange}
                    />
                    <label for="saveCard" style={{ color: "black" }}>
                      Save your card
                    </label>
                  </div>
                </>
              )}

            <Row className="border-top pt-3">
              <Col className="text-right" style={{ color: "var(--orange)" }}>
                <div>Subtotal:</div>
                <div>Shipping:</div>
                {/* <div>Payment Processing:</div> */}
              </Col>
              <Col className="text-right" style={{ color: "var(--main)" }}>
                <div>${totalPrice}</div>
                <div>Free</div>
                {/* <div>${(totalPrice * 0.035).toFixed(2)}</div> */}
              </Col>
            </Row>
            <hr style={{ margin: "20px 0", color: "red" }} />
            <Row>
              <Col className="text-right" style={{ color: "var(--orange)" }}>
                Total:
              </Col>
              <Col className="text-right" style={{ color: "var(--main)" }}>
                <div>
                  {" "}
                  $
                  {totalPrice}
                </div>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col className="text-right">
                {termsError && (
                  <span style={{ color: "var(--main)" }}>
                    Please accept the Terms and Conditions to proceed.
                  </span>
                )}
                <Form.Group
                  controlId="formBasicCheckbox"
                  className="d-flex align-items-center"
                  style={{ color: "var(--orange)" }}
                >
                  <Form.Check
                    type="checkbox"
                    id="custom-checkbox"
                    checked={termsAccepted}
                    onChange={handleTermsChange}
                    className="me-2"
                  />
                  <Form.Label htmlFor="custom-checkbox" className="mb-0 me-2">
                    I agree to the
                  </Form.Label>
                  <Link
                    to="/terms-of-service"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ms-1 me-1"
                    style={{ color: "var(--main)" }}
                  >
                    Terms of Service
                  </Link>
                  and
                  <Link
                    to="/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ms-1"
                    style={{ color: "var(--main)" }}
                  >
                    Privacy Policy
                  </Link>
                  .
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col className="text-right">
                <Button
                  style={{
                    backgroundColor: "var(--main)",
                    borderColor: "white",
                    fontSize: "20px",
                  }}
                  onClick={() => handleCheckout()}
                  size="lg"
                >
                  Checkout
                </Button>
                <Button
                  style={{
                    backgroundColor: "var(--orange)",
                    borderColor: "white",
                    fontSize: "20px",
                  }}
                  onClick={handleGoBack}
                  className="ms-2"
                  size="lg"
                >
                  Go Back
                </Button>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </Container>
  );
}
