import React from "react";
import { Image } from "react-bootstrap";
import "./loader.css";

const Loader = () => {
  return (
    <div className="preloader-container">
      <Image
        src={"/assets/Logo/logo.png"}
        className="img-contain logo"
        alt=""
      />
      <div className="preloader" />
    </div>
  );
};

export default Loader;
