import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import Home from "./Pages/Home";
import { UserProvider } from "./utils/UserContext";
import About from "./Pages/About";
import ProductDetails from "./Pages/ProductDetails";
import Products from "./Pages/Products/Products";
import SearchProduct from "./Pages/SearchProduct/index";
import Contact from "./Pages/Contact/Contact";
import SignIn from "./Pages/SignIn";
import SignUp from "./Pages/SignUp";
import UserProfile from "./Pages/userProfile";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import Success from "./Pages/Success";
import CartPage from "./Pages/Cart";
import Failed from "./Pages/Failure";
import TermsOfService from "./Pages/TermsAndCondition";
import AgeVerificationModal from "./Components/Modal/AgeVerification";
import Loader from "./Components/Loader";
import BackToTopButton from "./Components/BackToTop";
import CheckoutPage from "./Pages/Checkout";
import Orders from "./Pages/Orders";
import ForgotPassword from "./Pages/ForgotPassword";
import UnderReview from "./Pages/UnderReview";
import LoggedInRoute from "./Components/LoggedInRoute";
import ProtectedRoute from "./Components/ProtectedRoute";
import "./App.css";
import "./Css/Default.css";
import "./Css/Responsive.css";
import ChangePassword from "./Pages/ChangePassword";
import FeatureProducts from "./Components/FeatureProducts/Feature";
import PaymentSettings from "./Pages/paymentSettings";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 10));
      setIsLoading(false);
    };

    fetchData();
  }, []);

  return (
    <>
      <div>
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            <Router>
              <UserProvider>
                <Navbar />
                <AgeVerificationModal />
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route
                    path="/login"
                    element={
                      <LoggedInRoute>
                        <SignIn />
                      </LoggedInRoute>
                    }
                  />
                  <Route
                    path="/forgot-password"
                    element={
                      <LoggedInRoute>
                        <ForgotPassword />
                      </LoggedInRoute>
                    }
                  />
                  <Route
                    path="/change-password"
                    element={
                      <ProtectedRoute>
                      <ChangePassword />
                      </ProtectedRoute>
                    }
                  />
                   {/* <Route
                    path="/payment-settings"
                    element={
                      <ProtectedRoute>
                      <PaymentSettings />
                      </ProtectedRoute>
                    }
                  /> */}
                  
                  <Route
                    path="/register"
                    element={
                      <LoggedInRoute>
                        <SignUp />
                      </LoggedInRoute>
                    }
                  />
                  <Route path="/about-us" element={<About />} />
                  <Route
                    path="/popular-products"
                    element={<FeatureProducts />}
                  />
                  <Route path="/new-arrival" element={<FeatureProducts />} />
                  <Route path="/deals" element={<FeatureProducts />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route
                    path="/terms-of-service"
                    element={<TermsOfService />}
                  />
                  {/* Products by Category */}
                  <Route path="/products" element={<Products />} />
                  <Route path="/products/search" element={<SearchProduct />} />
                  <Route path="/product-details" element={<ProductDetails />} />
                  <Route
                    path="/under-review"
                    element={
                      <LoggedInRoute>
                        <UnderReview />
                      </LoggedInRoute>
                    }
                  />
                  <Route
                    path="/my-cart"
                    element={
                      <ProtectedRoute>
                        <CartPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/profile"
                    element={
                      <ProtectedRoute>
                        <UserProfile />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/orders"
                    element={
                      <ProtectedRoute>
                        <Orders />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route path="/under-review" element={<UnderReview />} /> */}
                  <Route
                    path="/success-payment"
                    element={
                      <ProtectedRoute>
                        <Success />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/failed-payment"
                    element={
                      <ProtectedRoute>
                        <Failed />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/checkout"
                    element={
                      <ProtectedRoute>
                        <CheckoutPage />
                      </ProtectedRoute>
                    }
                  />
                </Routes>
                <BackToTopButton />
                <Footer />
              </UserProvider>
            </Router>
          </div>
        )}
      </div>
    </>
  );
}

export default App;
