import React, { useState } from "react";
import axiosInstance from "../../utils/axiosInstance";
import { APIS } from "../../utils/constants";
import { useNavigate } from "react-router-dom";

const ChangePassword = () => {
  const navigate = useNavigate();

  const [values, setValues] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const [passwordVisibility, setPasswordVisibility] = useState({
    currentPassword: false,
    newPassword: false,
    confirmNewPassword: false,
  });

  const [strength, setStrength] = useState({
    newPassword: "",
    currentPassword: "",
  });

  const [matchMessage, setMatchMessage] = useState("");

  const checkPasswordStrength = () => {
    const currentPassword = values.currentPassword;
    let strength = 0;

    if (currentPassword.length >= 8) strength++;
    if (currentPassword.match(/[A-Z]/)) strength++;
    if (currentPassword.match(/[a-z]/)) strength++;
    if (currentPassword.match(/[0-9]/)) strength++;
    if (
      currentPassword.match(
        /[\!\@\#\$\%\^\&\*\(\)\-\+\=\{\}\[\]\:\;\"\'\<\>,\.\?\/\~\_\|\`]/
      )
    )
      strength++;
    setStrength({
      ...strength,
      currentPassword: strength,
    });
    return strength === 5;
  };

  const checkPasswordMatch = () => {
    const newPassword = values.newPassword;
    const confirmNewPassword = values.confirmNewPassword;

    if (newPassword === confirmNewPassword) {
      setMatchMessage("Passwords match.");
      return true;
    } else {
      setMatchMessage("Passwords do not match.");
      return false;
    }
  };

  const togglePasswordVisibility = (field) => {
    setPasswordVisibility({
      ...passwordVisibility,
      [field]: !passwordVisibility[field],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Make your API call or any other logic here
    if (checkPasswordStrength() && checkPasswordMatch()) {
      try {
        // Replace 'your-api-endpoint' with your actual API endpoint
        const response = await axiosInstance.post(APIS.change_password, values);
        if (response?.data) {
          // Handle success, such as redirecting to another page
          alert("Password changed successfully");
          setValues({
            currentPassword: "",
            newPassword: "",
            confirmNewPassword: "",
          });
          navigate("/");
        }
      } catch (error) {
        alert("Please enter correct password");
        console.error("Error changing password:", error.message);
        // Handle error, show error message to the user, etc.
      }
    }
  };

  return (
    <div className="container">
      <div className="password-reset-container">
        <div id="password-set-message" className="message"></div>
        <h2>Change Password</h2>
        <form id="password-form">
          <label htmlFor="current-password">Current Password</label>
          <div className="input-group">
            <input
              type={passwordVisibility.currentPassword ? "text" : "password"}
              id="current-password"
              name="current-password"
              required
              style={{ width: "500px" }}
              onChange={(e) =>
                setValues({ ...values, currentPassword: e.target.value })
              }
              value={values.currentPassword}
              onInput={checkPasswordStrength}
              onKeyUp={checkPasswordMatch}
            />
            <span
              id="new-password-toggle"
              className="toggle-visibility"
              style={{ cursor: "pointer" }}
              onClick={() => togglePasswordVisibility("currentPassword")}
            >
              {passwordVisibility.currentPassword ? "🙈" : "👁️"}
            </span>
          </div>
          <label htmlFor="new-password">New Password</label>
          <div className="input-group">
            <input
              type={passwordVisibility.newPassword ? "text" : "password"}
              id="new-password"
              name="new-password"
              style={{ width: "500px" }}
              required
              onChange={(e) =>
                setValues({ ...values, newPassword: e.target.value })
              }
              value={values.newPassword}
              onInput={checkPasswordStrength}
              onKeyUp={checkPasswordMatch}
            />
            <span
              id="new-password-toggle"
              className="toggle-visibility"
              style={{ cursor: "pointer" }}
              onClick={() => togglePasswordVisibility("newPassword")}
            >
              {passwordVisibility.newPassword ? "🙈" : "👁️"}
            </span>
          </div>
          {/* <div className="password-strength" id="password-strength">
            Strength: {strength.newPassword}
          </div> */}
          <div className="message" id="password-message"></div>

          <label htmlFor="confirm-password">Confirm New Password</label>
          <div className="input-group">
            <input
              type={passwordVisibility.confirmNewPassword ? "text" : "password"}
              id="confirm-password"
              name="confirm-password"
              required
              style={{ width: "500px" }}
              value={values.confirmNewPassword}
              onChange={(e) =>
                setValues({ ...values, confirmNewPassword: e.target.value })
              }
              onKeyUp={() => {
                checkPasswordMatch();
                checkPasswordStrength();
              }}
            />
            <span
              id="confirm-password-toggle"
              className="toggle-visibility"
              style={{ cursor: "pointer" }}
              onClick={() => togglePasswordVisibility("confirmNewPassword")}
            >
              {passwordVisibility.confirmNewPassword ? "🙈" : "👁️"}
            </span>
          </div>
          <div id="password-match-message" className="message">
            {matchMessage}
          </div>
          <button
            type="submit"
            onClick={handleSubmit}
            style={{
              marginTop: "10px",
              backgroundColor: "#c02233",
              padding: "5px",
              color: "white",
              fontWeight: "bolder",
            }}
          >
            Change my password
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
