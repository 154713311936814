import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Breadcrumb,
  Button,
  Col,
  Container,
  Dropdown,
  Image,
  Row,
  Modal,
} from "react-bootstrap";
import ScrollToTopOnMount from "../../Components/ScrollToTopOnMount";
import PaginationComponent from "../../Components/Pagination";
import axiosInstance from "../../utils/axiosInstance";
import { APIS, DEFAULT_PRODUCT_IMAGE } from "../../utils/constants";
import { useUser } from "../../utils/UserContext";
import Loader from "../../Components/Loader";
import "./product.css";

const itemsPerPageDropDown = [10, 25, 50, 75, 100];

export default function Product() {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, cartItem, setCartItem } = useUser();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [productData, setProductData] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [productQuantities, setProductQuantities] = useState([]);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [productList, setProductList] = useState([]);
  const [sortBy, setSortBy] = useState("name-ascending");
  const [maxProductObj, setMaxProductObj] = useState({});
  const queryParams = new URLSearchParams(location.search);
  let categoryId = queryParams.get("category");

  const [sliderValue, setSliderValue] = useState({
    start: 0,
    stop: 80,
    min: 0,
    max: 100,
  });

  const [checkbox, setCheckbox] = useState({
    inStock: false,
    outOfStock: false,
  });
  console.log(checkbox,"checkboxs")

  const sliderStyle = {
    "--start": `${
      ((sliderValue.start - sliderValue.min) /
        (sliderValue.max - sliderValue.min)) *
      100
    }%`,
    "--stop": `${
      ((sliderValue.stop - sliderValue.min) /
        (sliderValue.max - sliderValue.min)) *
      100
    }%`,
  };

  useEffect(() => {
    setSliderValue({ start: 0, stop: 80, min: 0, max: 100 });
  }, []);

  const handleStartChange = (e) => {
    const newStart = parseInt(e.target.value);
    if (newStart <= maxProductObj.price)
      if (newStart < +sliderValue.stop - 20) {
        setSliderValue({ ...sliderValue, start: +newStart });
      } else {
        setSliderValue({ ...sliderValue, start: +sliderValue.stop - 20 });
      }
  };

  const handleStopChange = (e) => {
    const newStop = parseInt(e.target.value);

    if (newStop <= maxProductObj.price)
      if (newStop > sliderValue.start + 20) {
        setSliderValue({ ...sliderValue, stop: +newStop });
      } else {
        setSliderValue({ ...sliderValue, stop: +sliderValue.start + 20 });
      }
  };

  useEffect(() => {
    const skip = (currentPage - 1) * itemsPerPage || 0;
    const take = itemsPerPage || 10;
    const queryParams = new URLSearchParams(location.search);
    const categoryId = queryParams.get("category");
    const parsedCategoryId = parseInt(categoryId, 10);
    const source = axios.CancelToken.source();

    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get(
          `${APIS.products_by_category}/${categoryId}?skip=${skip}&take=${take}`,
          { cancelToken: source.token }
        );

        const { data, totalData } = response.data;
        const maxObject = data.reduce(
          (max, current) => (current.price > max.price ? current : max),
          data[0]
        );

        setProductData(data);
        setTotalItems(totalData);
        setProductList(data);
        setCheckbox ({
          inStock: false,
          outOfStock: false,
        })
        setMaxProductObj(maxObject);
        setSliderValue({ ...sliderValue, stop: +maxObject.price });
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled", error.message);
        } else {
          setError(error);
        }
      } finally {
        setLoading(false);
      }
    };

    if (!isNaN(parsedCategoryId) && parsedCategoryId > 0) {
      fetchData();
    } else {
      console.error("Invalid category ID:", categoryId);
      setError(new Error("Invalid category ID"));
      setProductData([]);
    }
    return () => {
      source.cancel("Component got unmounted");
    };
  }, [location, currentPage, itemsPerPage]);

  const priceShort = () => {
    const filteredData = productData.filter(
      (item) =>
        item.price >= sliderValue.start && item.price <= sliderValue.stop
    );
    setProductList(filteredData);
  };

  const handleSortChange = (selectedSortBy) => {
    setSortBy(selectedSortBy);
    const sortedProducts = productData.sort((a, b) => {
      if (selectedSortBy === "name-ascending") {
        return a.name.localeCompare(b.name);
      } else if (selectedSortBy === "name-descending") {
        return b.name.localeCompare(a.name);
      } else if (selectedSortBy === "price-ascending") {
        return a.price - b.price;
      } else if (selectedSortBy === "price-descending") {
        return b.price - a.price;
      }
      return 0;
    });
    setProductList(sortedProducts);
  };

  useEffect(() => {
    let filteredList = [];

    if (
      (checkbox.inStock && checkbox.outOfStock) ||
      (!checkbox.inStock && !checkbox.outOfStock)
    ) {
      filteredList = productData;
    } else if (checkbox.inStock) {
      filteredList = productData.filter((item) => item.stockDetails.free > 0);
    } else if (checkbox.outOfStock) {
      filteredList = productData.filter((item) => item.stockDetails.free <= 0);
    }
    setProductList(filteredList);
  }, [checkbox]);

  useEffect(() => {
    setCurrentPage(1);
  }, [categoryId]);

  const handleUpdateCart = async () => {
    if (productQuantities.length === 0) {
      setError("Cart is empty. No need to update.");
      setShowErrorModal(true);
      console.log("Cart is empty. No need to update.");
      return;
    }
    try {
      const cartItems = productQuantities.map((product) => ({
        productId: product.productId,
        quantity: product.quantity,
      }));
      // Assuming you have an API endpoint for updating the cart
      await axiosInstance.post(APIS.addItem, {
        items: cartItems,
      });
      setCartItem(cartItem + cartItems?.length);
      alert("Cart updated successfully");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      // Handle the response, e.g., show a success message
    } catch (error) {
      setError(error.data.message);
      console.log(error);
      setShowErrorModal(true);
      setLoading(false);
      console.error("Error updating cart", error);
      alert(error?.message || "Something went wrong");
    }
  };
  const handleCloseErrorModal = () => setShowErrorModal(false);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleItemsPerPageChange = (num) => {
    setItemsPerPage(num);
    setCurrentPage(1);
  };

  const handleProductClick = (product_id) => {
    return navigate(`/product-details?product=${product_id}`);
  };

  const incrementQuantity = (productId) => {
    setProductQuantities((currentQuantities) => {
      // Check if the product already exists in the state
      const existingProduct = currentQuantities.find(
        (p) => p.productId === productId
      );

      if (existingProduct) {
        // If it exists, increment the quantity
        return currentQuantities.map((p) =>
          p.productId === productId ? { ...p, quantity: p.quantity + 1 } : p
        );
      } else {
        // If it doesn't exist, add a new entry with quantity 1
        return [...currentQuantities, { productId, quantity: 1 }];
      }
    });
  };

  const decrementQuantity = (productId) => {
    setProductQuantities((currentQuantities) => {
      const existingProduct = currentQuantities.find(
        (p) => p.productId === productId
      );

      if (existingProduct) {
        // Decrement the quantity if it's more than 1
        if (existingProduct.quantity > 1) {
          return currentQuantities.map((p) =>
            p.productId === productId ? { ...p, quantity: p.quantity - 1 } : p
          );
        } else {
          // Remove the product from the array if the quantity reaches 0
          return currentQuantities.filter((p) => p.productId !== productId);
        }
      }
      return currentQuantities; // Return the original state if the product doesn't exist
    });
  };

  const getSortLabel = (sortBy) => {
    switch (sortBy) {
      case "name-ascending":
        return "Name (A-Z)";
      case "name-descending":
        return "Name (Z-A)";
      case "price-ascending":
        return "Price (Low to High)";
      case "price-descending":
        return "Price (High to Low)";
      default:
        return "Name (A-Z)";
    }
  };

  const sortingOptions = [
    { key: "name-ascending", label: "Name (A-Z)" },
    { key: "name-descending", label: "Name (Z-A)" },
    { key: "price-ascending", label: "Price (Low to High)" },
    { key: "price-descending", label: "Price (High to Low)" },
  ];
  const priceFilterOptions = [
    { key: "name-ascending", label: "Name (A-Z)" },
    { key: "name-descending", label: "Name (Z-A)" },
  ];
  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <ScrollToTopOnMount />
      <div>
        <Container>
          <div className="bread-crumb space-top-small space-bottom-small">
            <Breadcrumb>
              <Breadcrumb.Item href="/">HOME</Breadcrumb.Item>
              <Breadcrumb.Item active>PRODUCTS</Breadcrumb.Item>
              {user && user.isVerified && (
                <Button
                  className="feature-login-button"
                  style={{
                    marginLeft: "34%",
                    marginBottom: "auto",
                    backgroundColor: "var(--orange)",
                    fontSize: "15px",
                    borderColor: "white",
                    color: "white",
                  }}
                  onClick={handleUpdateCart}
                >
                  <span
                    style={{
                      padding: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    Update Cart
                  </span>
                </Button>
              )}
              <Dropdown
                onSelect={handleSortChange}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingLeft: "260px",
                  margin: "0",
                }}
                className="filter-dropdown"
              >
                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                  Sort By: {getSortLabel(sortBy)}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {user?.isVerified ? (
                    <>
                      {sortingOptions?.map((option) => (
                        <Dropdown.Item key={option.key} eventKey={option.key}>
                          {option.label}
                        </Dropdown.Item>
                      ))}
                    </>
                  ) : (
                    <>
                      {priceFilterOptions?.map((option) => (
                        <Dropdown.Item key={option.key} eventKey={option.key}>
                          {option.label}
                        </Dropdown.Item>
                      ))}
                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown>
              {user && user.isVerified && (
                <>
                  <Dropdown
                    className="filter-dropdown"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      margin: "0",
                    }}
                  >
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      Items per page: {itemsPerPage}
                    </Dropdown.Toggle>

                    <Dropdown.Menu alignRight={true}>
                      {itemsPerPageDropDown.map((num) => (
                        <Dropdown.Item
                          key={num}
                          onClick={() => handleItemsPerPageChange(num)}
                        >
                          {num}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </>
              )}
            </Breadcrumb>
          </div>
        </Container>
      </div>
      <div className="row">
        <h2 style={{ paddingLeft: "9px" }}>Filters</h2>
        <div className="col-lg-2" style={{ border: "1px solid #dad8d8 ", height: "max-content" }}>
          <div className="price-filter-container" style={{ marginLeft: "20px" }}>
            <p>Price</p>
            <div className="slider" style={sliderStyle}>
              <input
                type="range"
                value={sliderValue.start}
                onChange={handleStartChange}
              />
              <input
                type="range"
                value={sliderValue.stop}
                onChange={handleStopChange}
              />
            </div>
            <div className="input-container">
              <input
                className="button"
                style={{ width: "100%", maxWidth: "80px" }}
                value={sliderValue.start}
                type="number"
                onChange={(e) => {
                  if (+e.target.value <= maxProductObj.price)
                    setSliderValue({
                      ...sliderValue,
                      start: +e.target.value,
                    });
                }}
              />
              <input
                className="button"
                style={{ width: "100%", maxWidth: "80px", marginTop: "10px" }}
                type="number"
                value={sliderValue.stop}
                onChange={(e) => {
                  if (+e.target.value <= maxProductObj.price)
                    setSliderValue({
                      ...sliderValue,
                      stop: +e.target.value,
                    });
                }}
              />
            </div>
            <Button
              variant="primary"
              style={{
                marginTop: 15,
                backgroundColor: "var(--orange)",
                fontSize: "15px",
                borderColor: "white",
                color: "white",
              }}
              onClick={priceShort}
            >
              Apply
            </Button>{" "}
          </div>
          <div
            style={{
              marginTop: "30px",
              marginLeft: "15px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={checkbox.inStock}
                value={checkbox.inStock}
                onChange={(e) => {
                  setCheckbox({
                    ...checkbox,
                    inStock: e.target.checked,
                  });
                }}
                id="flexCheckDefault"
              />
              <label
                className="form-check-label"
                htmlFor="flexCheckDefault"
              >
                In stocks
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value={checkbox.outOfStock}
                checked={checkbox.outOfStock}
                onChange={(e) => {
                  setCheckbox({
                    ...checkbox,
                    outOfStock: e.target.checked,
                  });
                }}
                id="flexCheckChecked"
              />
              <label
                className="form-check-label"
                htmlFor="flexCheckChecked"
              >
                Out of stock
              </label>
            </div>
          </div>
        </div>

        <div className="col-lg-10">
          <section className="card-section position-relative">
            <div className="card-contain">
              <Container>
                <Row className="row-gap-4">
                  {productList.length > 0 &&
                    productList.map((item, idx) => {
                      const productQuantity =
                        productQuantities.find(
                          (p) => p.productId === item.productID
                        ) || {};
                      return (
                        <Col lg={3} md={6} key={idx}>
                          {" "}
                          {/* Changed from lg={3} to md={6} */}
                          <div
                            className="new-products p-4"
                            style={{ cursor: "default" }}
                          >
                            <span
                              className="img-holder"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                handleProductClick(item.productID);
                              }}
                            >
                              <Image
                                src={` https://qw3images.blob.core.windows.net/products-qw/${item.code2}.png`}
                                className="img-contain"
                                alt={item?.name}
                                height={"300px"}
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = DEFAULT_PRODUCT_IMAGE;
                                }}
                              />
                            </span>
                            <div>
                              {user && user.isVerified && (
                                <div style={{ display: "flex" }}>
                                  <p
                                    style={{
                                      color: "var(--main)",
                                      fontWeight: "bold",
                                      fontSize: "15px",
                                      margin: "0 10px 0 0",
                                    }}
                                  >
                                    USD: {item?.price ? item?.price : "N/A"}{" "}
                                  </p>
                                  <p
                                    style={{
                                      color: item?.stockDetails?.free
                                        ? "green"
                                        : "var(--main)",
                                      fontWeight: "bold",
                                      fontSize: "15px",
                                      margin: "0 0 0 10px",
                                    }}
                                  >
                                    {item?.stockDetails?.free
                                      ? `Available Units: ${item?.stockDetails?.free}`
                                      : "Out of Stock"}
                                  </p>
                                </div>
                              )}

                              <p
                                style={{
                                  color: "var(--orange)",
                                  fontWeight: "bold",
                                  fontSize: "15px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  handleProductClick(item?.productID);
                                }}
                              >
                                {item?.name}
                              </p>
                              {user && (
                                <p
                                  style={{
                                    color: "black",
                                    fontWeight: "bold",
                                    fontSize: "15px",
                                    margin: "0 10px 0 0",
                                  }}
                                >
                                  UPC: {item?.code2 ? item?.code2 : "N/A"}{" "}
                                </p>
                              )}
                              {user?.isVerified && (
                                <div>
                                  <Button
                                    style={{
                                      backgroundColor: "var(--main)",
                                      borderColor: "white",
                                      borderRadius: 15,
                                    }}
                                    disabled={
                                      item?.stockDetails?.free <= 0 ||
                                      Object.keys(productQuantity).length === 0
                                    }
                                    onClick={() =>
                                      decrementQuantity(item?.productID)
                                    }
                                  >
                                    <span
                                      style={{ padding: 5, fontSize: "15px" }}
                                    >
                                      -
                                    </span>
                                  </Button>
                                  <span
                                    style={{
                                      paddingLeft: "10px",
                                      paddingRight: "10px",
                                  }}
                                  >
                                    Quantity: {productQuantity.quantity || 0}
                                  </span>
                                  <Button
                                    style={{
                                      backgroundColor: "var(--orange)",
                                      borderColor: "white",
                                      borderRadius: 15,
                                    }}
                                    disabled={
                                      item?.stockDetails?.free <
                                        productQuantity?.quantity ||
                                      item?.stockDetails?.free === 0
                                    }
                                    onClick={() => {
                                      (item?.stockDetails?.free >
                                        productQuantity?.quantity ||
                                        Object.keys(productQuantity).length ===
                                          0) &&
                                        incrementQuantity(item?.productID);
                                    }}
                                  >
                                    <span
                                      style={{ padding: 5, fontSize: "15px" }}
                                    >
                                      +
                                    </span>
                                  </Button>
                                </div>
                              )}
                            </div>
                            {/* {(!user || !user.isVerified) && (
                          <Button
                            href="/login"
                            className="feature-login-button"
                            style={{
                              marginTop: 5,
                              backgroundColor: "var(--main)",
                              fontSize: "15px",
                              borderColor: "white",
                              color: "white",
                            }}
                          >
                            <span
                              style={{
                                padding: "10px",
                                fontWeight: "bold",
                              }}
                            >
                              Login to view price
                            </span>
                          </Button>
                        )} */}
                          </div>
                        </Col>
                      );
                    })}

                  {productList.length === 0 && (
                    <span>No Products found Found</span>
                  )}
                </Row>
                {user && user.isVerified && (
                  <Button
                    className="feature-login-button"
                    style={{
                      marginTop: 15,
                      backgroundColor: "var(--orange)",
                      fontSize: "15px",
                      borderColor: "white",
                      color: "white",
                    }}
                    onClick={handleUpdateCart}
                  >
                    <span
                      style={{
                        padding: "10px",
                        fontWeight: "bold",
                      }}
                    >
                      Update Cart
                    </span>
                  </Button>
                )}
                {productData && productList.length > 0 && (
                  <>
                    <Row>
                      <PaginationComponent
                        itemsPerPage={itemsPerPage}
                        totalItems={totalItems}
                        paginate={paginate}
                        currentPage={currentPage}
                      />
                    </Row>
                    <Row>
                      {/* <Dropdown className="filter-dropdown">
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      Items per page: {itemsPerPage}
                    </Dropdown.Toggle>

                    <Dropdown.Menu align={"end"}>
                      {itemsPerPageDropDown.map((num) => (
                        <Dropdown.Item
                          key={num}
                          onClick={() => handleItemsPerPageChange(num)}
                        >
                          {num}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown> */}
                    </Row>
                  </>
                )}
              </Container>
            </div>
          </section>
        </div>
      </div>
      <Modal show={showErrorModal} onHide={handleCloseErrorModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{error}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseErrorModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
