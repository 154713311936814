import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import CustomButton from "../../Components/Button";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Password reset email sent to: ", email);
    navigate("/login");
  };

  const handleGoBack = () => {
    navigate("/login");
  };

  return (
    <section className="contact-section position-relative space-top">
      <div className="contact-contain">
        <Container>
          <Row className="justify-content-center">
            <Col lg={8}>
              <div className="contact-form">
                <h2>
                  Reset
                  <span className="fill-text orange">Password</span>
                </h2>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col lg={12}>
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Enter your email"
                          required
                        />
                      </div>
                    </Col>
                    <Col lg={12}>
                      <CustomButton
                        btnClassname="shadow-btn w-auto"
                        type="submit"
                        text={<span>Send Reset Link</span>}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col lg={12} className="d-center flex-column">
                      <Button
                        style={{
                          backgroundColor: "var(--main)",
                          borderColor: "white",
                          fontSize: "18px",
                          fontWeight: "bold",
                        }}
                        size="lg"
                        className="shadow-btn w-auto"
                        onClick={handleGoBack}
                      >
                        <span style={{ margin: 5 }}>← Back to Login</span>
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
}

export default ForgotPassword;
