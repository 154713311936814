import { useRef } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "./Slider.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

//TODO - API to get image location and hyperLinks
function Slider() {
  const swiperRefLocal = useRef();

  const handleMouseEnter = () => {
    swiperRefLocal?.current?.swiper?.autoplay?.stop();
  };

  const handleMouseLeave = () => {
    swiperRefLocal?.current?.swiper?.autoplay?.start();
  };

  const sliderImages = [
    {
      img: "https://qw3images.blob.core.windows.net/sliders-qw/lostmary1.jpg",
      name: "lostmary1",
      hrefLink: "/",
    },
    {
      img: "https://qw3images.blob.core.windows.net/sliders-qw/privbar1.jpg",
      name: "privbar1",
      hrefLink: "/",
    },
    {
      img: "https://qw3images.blob.core.windows.net/sliders-qw/viho1.jpg",
      name: "viho1",
      hrefLink: "/",
    },
  ];

  return (
    <section className="slider-section position-relative">
      <div className="slider-contain">
        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <Swiper
            slidesPerView={1}
            spaceBetween={30}
            ref={swiperRefLocal}
            loop={true}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            navigation={false}
            modules={[Pagination, Navigation, Autoplay]}
            className="mySwiper swiper-container mb-5"
          >
            {sliderImages.map((imgData, idx) => (
              <SwiperSlide key={idx}>
                <Row className="row-gap-10">
                  <Col lg={12} className="d-center">
                    <div className="clients">
                      <Link to={imgData.hrefLink}>
                        <img
                          src={imgData.img}
                          alt={imgData.name}
                          style={{ height: "100%", width: "auto" }}
                          loading={"lazy"}
                        />
                      </Link>
                    </div>
                  </Col>
                </Row>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
}
export default Slider;
