import Slider from "../../Components/Slider/Slider";
import ScrollToTopOnMount from "../../Components/ScrollToTopOnMount";
import FeatureCards from "../../Components/FeatureCards";
import Axios from "axios";
import { APIS } from "../../utils/constants";
import { useEffect, useState } from "react";
import axiosInstance from "../../utils/axiosInstance";
import { useNavigate } from "react-router-dom";

const featureItemsData = {
  data: [
    {
      // FeatureImage: "/assets/Slider/product-1.png",
      FeatureTitle:
        "Feature Item- 1000 - NICOTINE - DISPOSABLE VAPE - 20% - 1CT/PK",
      hyperLink: "/",
    },
    {
      FeatureImage: "/assets/Slider/product-1.png",
      FeatureTitle:
        "Feature Item- 1000 - NICOTINE - DISPOSABLE VAPE - 1% - 1CT/PK",
      hyperLink: "/",
    },
    {
      FeatureImage: "/assets/Slider/product-2.png",
      FeatureTitle:
        "Feature Item- 2000 - NICOTINE - DISPOSABLE VAPE - 2% - 2CT/PK",
      hyperLink: "/",
    },
    {
      // FeatureImage: "/assets/Slider/product-3.png",
      FeatureTitle:
        "Feature Item- 3000 - NICOTINE - DISPOSABLE VAPE - 3% - 3CT/PK",
      hyperLink: "/",
    },
    {
      FeatureImage: "/assets/Slider/product-4.png",
      FeatureTitle:
        "Feature Item- 4000 - NICOTINE - DISPOSABLE VAPE - 4% - 4CT/PK",
      hyperLink: "/",
    },
  ],
};

const featureItemsData2 = {
  data: [
    {
      FeatureImage: "/assets/Slider/product-4.png",
      FeatureTitle: "Most Sold - 4000 - NICOTINE FREE VAPE",
      hyperLink: "/",
    },
    {
      // FeatureImage: "/assets/Slider/product-1.png",
      FeatureTitle: "Most Sold - 1000 - NICOTINE FREE VAPE",
      hyperLink: "/",
    },

    {
      FeatureImage: "/assets/Slider/product-2.png",
      FeatureTitle: "Most Sold - 2000 - NICOTINE FREE VAPE",
      hyperLink: "/",
    },
    {
      FeatureImage: "/assets/Slider/product-1.png",
      FeatureTitle: "Most Sold - 1000 - NICOTINE FREE VAPE",
      hyperLink: "/",
    },
    {
      // FeatureImage: "/assets/Slider/product-3.png",
      FeatureTitle: "Most Sold - 3000 - NICOTINE FREE VAPE",
      hyperLink: "/",
    },
  ],
};

export default function Home() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [popularProducts, setPopularProducts] = useState([]);
  const [newArrivalProducts, setNewArrivalProducts] = useState([]);
  const [dealsProducts, setDealsProducts] = useState([]);

  useEffect(() => {
    const cancelPopular = fetchProducts("POPULAR", setPopularProducts);
    const cancelNewArrival = fetchProducts(
      "NEW_ARRIVAL",
      setNewArrivalProducts
    );
    const cancelDeals = fetchProducts("DEALS", setDealsProducts);

    return () => {
      cancelPopular("Component got unmounted");
      cancelNewArrival("Component got unmounted");
      cancelDeals("Component got unmounted");
      setLoading(false);
    };
  }, []);

  const fetchProducts = (productType, setProductsState) => {
    setLoading(true);
    const source = Axios.CancelToken.source();
    const controller = new AbortController();

    axiosInstance
      .post(
        `${process.env.REACT_APP_serverUrl}${APIS.feature_products}`,
        { type: productType },
        { signal: controller.signal }
      )
      .then((res) => {
        JSON.stringify(res);
        setProductsState(res.data);
      })
      .catch((err) => {
        if (Axios.isCancel(err)) {
          console.log("Request canceled:", err.message);
        } else {
          setProductsState([]);
        }
      })
      .finally(() => {
        setLoading(false);
      });

    return source.cancel;
  };
  return (
    <>
      <ScrollToTopOnMount />
      <Slider />
      <img
        src="/assets/Images/geek-bar-10x.png"
        alt="geek bar banner"
        width={"50%"}
        onClick={() => {
          navigate("/products?category=217");
        }}
        style={{ cursor: "pointer" }}
        loading={"lazy"}
      />
      <img
        src="/assets/Images/nexa-n20000-10x.png"
        alt="nexa n20000 banner"
        width={"50%"}
        style={{ cursor: "pointer" }}
        onClick={() => {
          navigate("/products?category=258");
        }}
        loading={"lazy"}
      />
      {popularProducts?.data?.length > 0 && (
        <FeatureCards
          title={"Popular Products"}
          subTitle={"Our customer likes following products the most"}
          cards={popularProducts}
          topSpace={true}
        />
      )}
      {newArrivalProducts?.data?.length > 0 && (
        <FeatureCards
          title={"New Arrival"}
          subTitle={
            "We Stock The Best Brand New Products From The Market In Our Store"
          }
          cards={newArrivalProducts}
        />
      )}
      {dealsProducts?.data?.length > 0 && (
        <FeatureCards
          title={"Deals"}
          subTitle={"Our top deals products"}
          topSpace={true}
          cards={dealsProducts}
        />
      )}
      {dealsProducts?.data?.length === 0 &&
        newArrivalProducts?.data?.length === 0 &&
        dealsProducts?.data?.length === 0 && <p>Coming soon....</p>}
    </>
  );
}
