import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Navbar,
  Nav,
  NavDropdown,
  Container,
  NavLink,
  Row,
  Col,
  Image,
  Button,
} from "react-bootstrap";
import axiosInstance from "../../utils/axiosInstance";
import { useUser } from "../../utils/UserContext";
import { SVGIcons } from "../Data/SVGIcons";
import "./navbar.css";
import { APIS } from "../../utils/constants";
import Cookies from "js-cookie";
import { useSearchParams } from "react-router-dom";

export default function NavigationBar() {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchParams] = useSearchParams();
  const searchVal = searchParams.get("searchValue");
  // const [openDropdownPath, setOpenDropdownPath] = useState("");
  const { user, logout, cartItem, setCartItem, setProductList } = useUser();
  const [searchValue, setSearchValue] = useState(searchVal || "");
  const [responseData, setResponseData] = useState([])
  useEffect(() => {
    const controller = new AbortController();

    const fetchData = async () => {
      try {
        const token = Cookies.get("QW_authToken");
       
        const response = await axiosInstance.get(APIS.categories_list, {
          signal: controller.signal,
        });
        const sortedArray = response.data.sort(
          (a, b) => a.positionNo - b.positionNo
        );

        setCategories(sortedArray);
        const viewCardResponse = await axiosInstance.get(`${APIS.user_profile}`);
        setResponseData(viewCardResponse.data);
        setLoading(true);
        const cartList =
          token &&
          (await axiosInstance.get(`${APIS.get_cart}`, {
            signal: controller.signal,
          }));
        setCartItem(cartList?.data?.cart?.items?.length || 0);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled", error.message);
        } else {
          setError(error);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    return () => {
      controller.abort("Component got unmounted");
    };
  }, []);

  function decodeHtml(html) {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }

  const handleCategoryClick = (category_id) => {
    return navigate(`/products?category=${category_id}`);
    handleMouseLeave()
  };

  const renderDropdownItems = (items, parentPath) => {
    const sortedArray = items.sort((a, b) => a.positionNo - b.positionNo);
    return sortedArray.map((item, index) => {
      const currentPath = `${parentPath}-${index}`;
      // console.log("-----currentPath---", currentPath);
      const hasChildren = item.subGroups && item.subGroups.length > 0;
      if (hasChildren) {
        return (
          <NavDropdown
            key={currentPath}
            title={
              <span
                style={{
                  color: "var(--orange)",
                  fontSize: "14px",
                }}
              >
                <span className="droplist">
                  {decodeHtml(item.name)}
                  {item.subGroups && item.subGroups.length > 0 && (
                    <span> {SVGIcons.DropDown}</span>
                  )}
                </span>
              </span>
            }
            id={`nav-dropdown-${currentPath}`}
            className="nested-dropdown"
          >
            {renderDropdownItems(item.subGroups, currentPath)}
          </NavDropdown>
        );
      } else {
        return (
          <NavDropdown.Item
            key={currentPath}
            href="#"
            className="dropdown-item"
            style={{ fontSize: "14px", color: "black", fontWeight: 500 }}
            onClick={() => handleCategoryClick(item.id)}
          >
            <span className="droplist">{decodeHtml(item.name)}</span>
          </NavDropdown.Item>
        );
      }
    });
  };

  const CustomDropdown = ({ children, isVisible, position }) => {
    const itemsPerRow = 5;

    return isVisible ? (
      <div
        onMouseLeave={handleMouseLeave}
        className="custom-dropdowns"
        style={{
          border: "6px solid #c02233",
          borderRadius: "25px",
          position: "absolute",
          background: "white",
          width: "98%",
          top: position.top,
          left: position.left,
          zIndex: 999,
          display: "grid",
          gridTemplateColumns: `repeat(${itemsPerRow}, 1fr)`,
          gap: "10px",
          padding: "10px",
        }}
      >
        {children}
      </div>
    ) : null;
  };

  const [openDropdownPath, setOpenDropdownPath] = useState("");
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  // const [hoveredNavItem, setHoveredNavItem] = useState(null);
  const handleMouseEnter = (currentPath, event) => {
    const { clientY } = event;
    const dropdownTop = clientY - 109;
    setDropdownPosition({
      top: dropdownTop,
    });
    setOpenDropdownPath(currentPath);
  };

  const handleMouseLeave = () => {
    setOpenDropdownPath("");
  };
  const searchProduct = () => {
    navigate("/products/search?searchValue=" + searchValue);
  };

  return (
    <>
      <div className="bg-orange w-100 p-2">
        <div>
          <div className="information-style-one">
            <div className="text-center text-white">
              <p className="small">
                <b>
                  WARNING: THIS PRODUCT CONTAINS NICOTINE. NICOTINE IS AN
                  ADDICTIVE CHEMICAL.
                </b>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-main w-100 p-2">
        <div>
          <div className="information-style-one">
            <div className="icon">
              <Row>
                <Col className="" lg={6}>
                  <a
                    className="small fw-800 text-one-line"
                    href="mailto:qualitywholesaleus@gmail.com"
                  >
                    {SVGIcons.Email}
                    <span className="ms-3">qualitywholesaleus@gmail.com</span>
                  </a>
                  <a
                    className="small fw-800 ms-md-5 text-one-line"
                    href="tel:+14302004319"
                  >
                    {SVGIcons.Call}
                    <span className="ms-3">(430) 200-4319</span>
                  </a>
                  <Link
                    to="/contact"
                    className="small fw-800 ms-md-5 text-one-line"
                  >
                    <span>Contact Us</span>
                  </Link>
                </Col>
                <Col className="col-5 text-end">
                  {user ? (
                    <Link
                      to={"/my-cart"}
                      className="my-cart"
                      style={{ color: "white" }}
                    >
                      {SVGIcons.Cart(cartItem)}
                    </Link>
                  ) : (
                    <>
                      <Link
                        to="/register"
                        className="small fw-800 ms-md-5 text-one-line"
                      >
                        <span>Register</span>
                      </Link>
                      <Link
                        to="/login"
                        className="small fw-800 ms-md-5 text-one-line"
                      >
                        <span>Login</span>
                      </Link>
                    </>
                  )}
                </Col>
                <Col className="col-1 text-end">
                  {user && (
                    <>
                      {/* <Link to={"/my-cart"} className="my-cart">
                      {SVGIcons.Cart(cartItem)}
                    </Link> */}
                      <NavDropdown
                        title={`Profile`}
                        id="basic-nav-dropdown"
                        className="navbar-link"
                        style={{ color: "white", float: "left" }}
                      >
                        {!user?.isAdmin && (
                          <>
                          <NavDropdown.Item> <span style={{fontSize:"18px",fontWeight:"600"}}>Hi {responseData.bussinessName}</span> </NavDropdown.Item>
                            <NavDropdown.Item
                              as={Link}
                              to="/my-cart"
                              style={{ fontSize: "14px" }}
                            >
                              View Cart <span> {SVGIcons.Cart}</span>
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item
                              as={Link}
                              to="/profile"
                              style={{ fontSize: "14px" }}
                            >
                              View Profile
                            </NavDropdown.Item>
                            {/* <NavDropdown.Divider />
                            <NavDropdown.Item
                              as={Link}
                              to="/payment-settings"
                              style={{ fontSize: "14px" }}
                            >
                              Payment Settings
                            </NavDropdown.Item> */}
                            <NavDropdown.Divider />
                            <NavDropdown.Item
                              as={Link}
                              to="/change-password"
                              style={{ fontSize: "14px" }}
                            >
                              Change Password
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item
                              as={Link}
                              to="/orders"
                              style={{ fontSize: "14px" }}
                            >
                              View Past Orders
                            </NavDropdown.Item>
                          </>
                        )}

                        {user?.isAdmin && (
                          <>
                            <NavDropdown.Divider />
                            <NavDropdown.Item
                              as={Link}
                              to="/knock-knock/admin/users"
                              style={{ fontSize: "14px" }}
                            >
                              View Users
                            </NavDropdown.Item>
                          </>
                        )}
                        <NavDropdown.Divider />
                        <NavDropdown.Item
                          onClick={logout}
                          style={{ fontSize: "14px" }}
                        >
                          Logout
                        </NavDropdown.Item>
                      </NavDropdown>
                    </>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>

      <Navbar
        className="scrollbox py-4"
        bg="light"
        expand="lg"
        collapseOnSelect
        onClick={handleMouseLeave}
      >
        <Container fluid style={{ paddingLeft: "15px", paddingRight: "15px" }}>
          <div className="header-row d-flex align-items-center justify-content-between w-100">
            <div className="nav-brand-wrapper">
              <Link to="/" className="logo">
                <Image
                  src={"/assets/Logo/logo.png"}
                  className="img-contain logo"
                  alt="Quality Wholesale USA Logo"
                />
              </Link>
            </div>
            <div className="d-flex">
              <input
                className="form-control me-2 custom-search-input"
                type="search"
                placeholder="Search"
                aria-label="Search"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    searchProduct();
                  }
                }}
              />
              <button
                className="btn btn-outline-success custom-search-button"
                onClick={searchProduct}
              >
                Search
              </button>
            </div>

            <div
              className="d-flex gap-5 navbar-login-details "
              onclick={handleMouseLeave}
            >
              <div className="d-flex align-items-center gap-5">
                <a href="/popular-products" className="btn-shine">
                  Popular Products
                </a>
                <a href="/new-arrival" className="btn-shine">
                  New Arrival
                </a>
                <a href="/deals" className="btn-shine">
                  Deals
                </a>
              </div>
            </div>
          </div>
        </Container>
      </Navbar>

      <Navbar
        bg="light"
        expand="lg"
        collapseOnSelect
        // onMouseLeave={handleMouseLeaveMenu}
      >
        <Container fluid style={{ paddingLeft: "15px", paddingRight: "15px" }}>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav
              className="me-auto"
              style={{
                fontSize: "14px",
                backgroundColor: "var(--orange)",
              }}
            >
              {categories.map((category, idx) => {
                const currentPath = `main-${idx}`;
                // const isDropdownOpen = openDropdownPath.startsWith(currentPath);

                return category.subGroups && category.subGroups.length > 0 ? (
                  <>
                    {" "}
                    <NavDropdown
                      key={currentPath}
                      title={
                        <span
                          className="spandrop"
                          style={{
                            fontSize: "18px",
                            paddingRight: "25px",
                          }}
                        >
                          <span
                            className="categoryName"
                            style={{
                              color:
                                openDropdownPath === currentPath
                                  ? "red"
                                  : "white",
                              textDecoration:
                                openDropdownPath === currentPath
                                  ? "2px underline"
                                  : "",
                            }}
                          >
                            {decodeHtml(category.name)}
                            {category.subGroups &&
                              category.subGroups.length > 0 && (
                                <span> {SVGIcons.DropDown}</span>
                              )}
                          </span>
                        </span>
                      }
                      id={`nav-dropdown-${currentPath}`}
                      className="navbar-dropdowns"
                      show={false}
                      onClick={(e) => handleMouseEnter(currentPath, e)}
                    />
                    <CustomDropdown
                      isVisible={openDropdownPath === currentPath}
                      position={dropdownPosition}
                      className="cust"
                    >
                      {renderDropdownItems(category.subGroups, currentPath)}
                    </CustomDropdown>
                    <NavDropdown
                      key={idx}
                      title={
                        <span
                          style={{
                            // color: "#000000",
                            color: "white",
                            fontSize: "15px",
                            // border: "1px solid white",
                            paddingRight: "25px",
                          }}
                        >
                          {decodeHtml(category.name)}
                          {category.subGroups &&
                            category.subGroups.length > 0 && (
                              <span> {SVGIcons.DropDown}</span>
                            )}
                        </span>
                      }
                      id={`nav-dropdown-${currentPath}`}
                      className="navbar-dropdown"
                      // show={isDropdownOpen}
                      // onMouseEnter={() => handleMouseEnter(currentPath)}
                      // onMouseLeave={() => handleMouseLeave(currentPath)}
                    >
                      {renderDropdownItems(category.subGroups, currentPath)}
                    </NavDropdown>
                  </>
                ) : (
                  <NavLink
                    key={idx}
                    href={category.link || "#"}
                    className="header-link"
                    id={`nav-header-${idx}`}
                    style={{
                      fontSize: "18px",
                      paddingRight: "25px",
                    }}
                    // onMouseLeave={() => handleMouseLeave(currentPath)}
                    onClick={() => handleCategoryClick(category.id)}
                  >
                    <span className="categoryName">
                      {decodeHtml(category.name)}
                    </span>
                  </NavLink>
                );
              })}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
