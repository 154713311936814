import { useRef, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import CustomButton from "../../Components/Button";
import ScrollToTopOnMount from "../../Components/ScrollToTopOnMount.js";
import "./SignUp.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { reCAPTCHA_KEY } from "../../utils/constants";

function SignUp() {
  const [loading, setLoading] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [policyCheckbox, setPolicyCheckbox] = useState(false);
  const navigate = useNavigate();
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [confirmPasswordVisibility, setConfirmPasswordVisibility] =
    useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    bussinessName: "",
    email: "",
    password: "",
    confirmPassword: "",
    bussinessPhone: "",
    phone: "",
    address1: "",
    address2: "",
    city: "",
    zip: "",
    state: "",
    country: "USA",
    businessLicenseNumber: "",
    tobaccoLicenseNumber: "",
    federalEINNumber: "",
    drivingLicenseNumber: "",
    accountNumber: "",
    TobaccoLicenseNumber: null,
    BusinessLicenseNumber: null,
    VoidCheque: null,
    DrivingLicenseNumber: null,
    FederalEINDocumentNumber: null,
  });
  const [files, setFiles] = useState([]);
  const [errors, setErrors] = useState({});
  const formRef = useRef(null); // Create a ref for the form

  const isImageFile = (file) => {
    const allowedExtensions = /(\.jpeg|\.png|\.pdf|\.jpg)$/i;
    return allowedExtensions.test(file.name);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const validateLength = (maxLength) => {
      if (value.length > maxLength) {
        return false;
      } else return true;
    };

    if (name === "bussinessPhone" || name === "phone") {
      if (value.toString().length <= 10)
        setFormData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
    } else if (name === "firstName") {
      if (!/[!@#$%^&*(),.?":{}|<>]/.test(value) && !/^\d{19}$/g.test(value))
        setFormData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
    } else if (name === "lastName") {
      if (!/[!@#$%^&*(),.?":{}|<>]/.test(value) && !/^\d{19}$/g.test(value))
        setFormData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
    } else if (name === "zip") {
      if (
        value.toString().length <= 6 &&
        !value.includes("--") &&
        !/[a-zA-Z]/.test(value) &&
        !/[-=]{3,}/.test(value) &&
        !/[!@#$%^&*(),.?":{}|<>]/.test(value)
      ) {
        setFormData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    } else if (name === "drivingLicenseNumber") {
      if (
        value.toString().length <= 14 &&
        !value.includes("--") &&
        !/[a-zA-Z]/.test(value) &&
        !/[-=]{3,}/.test(value) &&
        !/[!@#$%^&*(),.?":{}|<>]/.test(value) &&
        !/^(?!.*[-=]{2,})[0-9]{19}$/g.test(value) &&
        !/^\d{19}$/g.test(value)
      )
        setFormData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
    } else if (name === "businessLicenseNumber") {
      const validate = validateLength(20);
      validate &&
        setFormData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
    } else if (name === "tobaccoLicenseNumber") {
      const validate = validateLength(20);
      validate &&
        setFormData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
    } else if (name === "federalEINNumber") {
      const validate = validateLength(20);
      validate &&
        setFormData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
    } else if (name === "accountNumber") {
      const validate = validateLength(20);
      validate &&
        setFormData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const resetFormData = () => {
    setFormData({
      firstName: "",
      lastName: "",
      bussinessName: "",
      email: "",
      password: "",
      confirmPassword: "",
      bussinessPhone: "",
      phone: "",
      address1: "",
      address2: "",
      city: "",
      zip: "",
      state: "",
      country: "USA",
      businessLicenseNumber: "",
      tobaccoLicenseNumber: "",
      federalEINNumber: "",
      drivingLicenseNumber: "",
      accountNumber: "",
      TobaccoLicenseNumber: null,
      BusinessLicenseNumber: null,
      VoidCheque: null,
      DrivingLicenseNumber: null,
      FederalEINDocumentNumber: null,
    });
    setFiles([]);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && isImageFile(selectedFile)) {
      const { name } = e.target;
      const file = e.target.files[0];
      if (file.size < 10 * 1024 * 1024) {
        setFormData((prevState) => ({
          ...prevState,
          [name]: file ? { name, value: file } : null,
        }));
        console.log("Selected file is a valid image:", selectedFile);
      } else {
        alert(`Please slecte a file which is less than ${10} MB`);
        e.target.value = "";
        return;
      }
    } else {
      alert("Please select a valid image file (JPG or PNG).");
      e.target.value = null;
    }
  };
  const getInputClass = (fieldName) => {
    return errors[fieldName] ? "form-control error-input" : "form-control";
  };

  const validateForm = async () => {
    const newErrors = {};

    const validateLength = (field, minLength, maxLength) => {
      const trimmedValue = formData[field]?.trim();
      const fieldName =
        field
          .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between camel case
          .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2") // Handle consecutive uppercase letters
          .charAt(0)
          .toUpperCase() + field.slice(1);

      if (trimmedValue && trimmedValue.length > maxLength) {
        newErrors[
          field
        ] = `${fieldName} does not exceed more than ${maxLength} characters.`;
      } else if (trimmedValue && trimmedValue.length < minLength) {
        newErrors[
          field
        ] = `${fieldName} must be at least ${minLength} characters.`;
      } else if (!trimmedValue) {
        newErrors[field] = `${fieldName} is required.`;
      }
    };
    const validateRequiredField = (field) => {
      const fieldValue = formData[field];
      const fieldName =
        field
          .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between camel case
          .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2") // Handle consecutive uppercase letters
          .charAt(0)
          .toUpperCase() + field.slice(1);

      if (!fieldValue) {
        newErrors[field] = `${fieldName} is required.`;
      }
    };

    const validateEmail = () => {
      const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!pattern.test(formData.email)) {
        newErrors.email = "Please enter a valid email address.";
      } else if (!formData.email) {
        newErrors.email = "Email is required.";
      }
    };

    const validatePassword = () => {
      const passwordRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

      if (formData?.password?.length < 8) {
        newErrors.password = "Password must be at least 8 characters.";
      } else if (!passwordRegex.test(formData.password)) {
        newErrors.password =
          "At least one lowercase letter, uppercase letter, digit, special character (@,$,!,%,*,?,or&)";
      } else if (formData?.password && formData?.password?.length > 56) {
        newErrors.password =
          "Password does not exceed more than 56 characters.";
      } else if (!formData.password) {
        newErrors.password = "Password is required.";
      }
    };

    // Add more validation functions as needed

    validateLength("firstName", 3, 56);
    validateLength("lastName", 3, 56);
    validateLength("bussinessName", 3, 56);
    validateEmail();
    validatePassword();

    if (formData.confirmPassword !== formData.password) {
      newErrors.confirmPassword = "Password and Confirm Password do not match.";
    } else if (!formData.confirmPassword) {
      newErrors.confirmPassword = "Confirm Password is required.";
    }

    validateLength("bussinessPhone", 10, 10);
    validateLength("phone", 10, 10);
    validateLength("address1", 2, 56);
    formData.address2 && validateLength("address2", 2, 56);
    validateLength("city", 2, 56);
    validateLength("zip", 2, 6);
    validateLength("state", 2, 56);
    validateLength("country", 2, 56);
    validateLength("businessLicenseNumber", 2, 20);
    validateLength("tobaccoLicenseNumber", 2, 20);
    validateLength("federalEINNumber", 2, 20);
    validateLength("drivingLicenseNumber", 2, 14);
    validateLength("accountNumber", 2, 20);
    validateRequiredField("TobaccoLicenseNumber");
    validateRequiredField("BusinessLicenseNumber");
    validateRequiredField("VoidCheque");
    validateRequiredField("DrivingLicenseNumber");
    validateRequiredField("FederalEINDocumentNumber");
    if (!policyCheckbox) {
      newErrors.policyCheckbox =
        "Please check this privacy policy terms and condition.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // true if no errors
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isFormValid = await validateForm();
    if (!isFormValid) {
      formRef.current && formRef.current.scrollIntoView({ behavior: "smooth" });
      console.log("FORM-DATA", formData, isFormValid);
      return;
    }
    if (!recaptchaToken) {
      alert("Please verify that you are not a robot.");
      return;
    }

    let data = new FormData();
    Object.entries({ ...formData, recaptchaToken: recaptchaToken }).forEach(
      ([key, value]) => {
        if (key !== "confirmPassword")
          if (
            value !== null &&
            typeof value === "object" &&
            value.hasOwnProperty("value")
          ) {
            data.append(key, value.value);
          } else {
            if (key === "email") {
              value = value.toLowerCase();
              data.append(key, value);
            } else if (key === "address2") {
              value = value.toLowerCase();
              value.length <= 0
                ? data.append(key, "-")
                : data.append(key, value);
            } else {
              data.append(key, value);
            }
          }
      }
    );
    try {
      setLoading(true);
      await axios
        .post(`${process.env.REACT_APP_serverUrl}/auth/signup`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          resetFormData();
          setErrors({});
          setPolicyCheckbox(false);
          navigate("/under-review");
          return response;
        })
        .catch((error) => {
          alert(
            error?.response?.data?.message +
              ", Please try with another Email Address"
          );
        });
    } catch (error) {
      console.log(error);
      alert(error?.message);
      // setError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ScrollToTopOnMount />
      <section className="contact-section position-relative space-top">
        <div className="contact-contain">
          <Container>
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              encType="multipart/form-data"
            >
              <Row className="justify-content-center">
                <Col lg={8}>
                  <div className="contact-form">
                    <Row className="justify-content-center mt-4">
                      <Col lg={12} className="text-center">
                        <p className="already-account">
                          Already have an account?
                          <Link to="/login" className="login-link">
                            Log in
                          </Link>
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col lg={8}>
                  <div className="contact-form">
                    <h2>
                      Sign
                      <span className="fill-text orange">Up</span>
                    </h2>
                    <h3 className="widget-title widget-title fw-700 mt-5">
                      REGISTRATION DETAILS
                    </h3>
                    <Form>
                      <Row>
                        <Col lg={6}>
                          <div className="form-group">
                            {errors.firstName && (
                              <div className="error-message">
                                {errors.firstName}
                              </div>
                            )}
                            <input
                              id="firstName"
                              disabled={loading}
                              name="firstName"
                              type="text"
                              value={formData.firstName || ""}
                              className={getInputClass("firstName")}
                              placeholder="First Name"
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="form-group">
                            {errors.lastName && (
                              <div className="error-message">
                                {errors.lastName}
                              </div>
                            )}
                            <input
                              id="lastName"
                              name="lastName"
                              disabled={loading}
                              value={formData.lastName || ""}
                              className={getInputClass("lastName")}
                              type="text"
                              placeholder="Last Name"
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="form-group">
                            {errors.bussinessName && (
                              <div className="error-message">
                                {errors.bussinessName}
                              </div>
                            )}
                            <input
                              id="bussinessName"
                              disabled={loading}
                              name="bussinessName"
                              value={formData.bussinessName || ""}
                              className={getInputClass("bussinessName")}
                              type="text"
                              placeholder="Bussiness Name"
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div
                            className="form-group"
                            style={{ marginBottom: 0 }}
                          >
                            {errors.email && (
                              <div className="error-message">
                                {errors.email}
                              </div>
                            )}
                            <input
                              id="email"
                              name="email"
                              disabled={loading}
                              value={formData.email || ""}
                              className={getInputClass("email")}
                              type="email"
                              placeholder="Email"
                              onChange={handleChange}
                              required
                            />
                            <p
                              style={{
                                color: "#F00",
                                fontFamily: "Segoe UI",
                                fontSize: "15px",
                                marginTop: 0,
                                fontStyle: "normal",
                                fontWeight: "400",
                              }}
                            >
                              *Note this is email address you will receive your
                              order email and update*
                            </p>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="form-group">
                            {errors.password && (
                              <div className="error-message">
                                {errors.password}
                              </div>
                            )}
                            <input
                              id="password"
                              name="password"
                              type={passwordVisibility ? "text" : "password"}
                              style={{ marginBottom: "5px" }}
                              disabled={loading}
                              value={formData.password || ""}
                              className={getInputClass("password")}
                              placeholder="Password"
                              onChange={handleChange}
                              required
                            />
                          </div>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              style={{ marginBottom: "8%", fontSize: "medium" }}
                              id="flexCheckDefault"
                              onClick={() =>
                                setPasswordVisibility(!passwordVisibility)
                              }
                            />
                            <label
                              class="form-check-label"
                              for="flexCheckDefault"
                              style={{ fontSize: "medium" }}
                            >
                              Show Password
                            </label>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="form-group">
                            {errors.confirmPassword && (
                              <div className="error-message">
                                {errors.confirmPassword}
                              </div>
                            )}
                            <input
                              id="confirmPassword"
                              name="confirmPassword"
                              disabled={loading}
                              value={formData.confirmPassword || ""}
                              className={getInputClass("confirmPassword")}
                              type={
                                confirmPasswordVisibility ? "text" : "password"
                              }
                              style={{ marginBottom: "5px" }}
                              placeholder="Confirm Password"
                              onChange={handleChange}
                              required
                            />
                          </div>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              style={{ marginBottom: "8%", fontSize: "medium" }}
                              id="flexCheckDefault"
                              onClick={() =>
                                setConfirmPasswordVisibility(
                                  !confirmPasswordVisibility
                                )
                              }
                            />
                            <label
                              class="form-check-label"
                              for="flexCheckDefault"
                              style={{ fontSize: "medium" }}
                            >
                              Show Confirm Password
                            </label>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="form-group">
                            {errors.bussinessPhone && (
                              <div className="error-message">
                                {errors.bussinessPhone}
                              </div>
                            )}
                            <input
                              type="number"
                              id="bussinessPhone"
                              disabled={loading}
                              name="bussinessPhone"
                              className={getInputClass("bussinessPhone")}
                              value={formData.bussinessPhone || ""}
                              onChange={handleChange}
                              placeholder="Bussiness Phone"
                              required
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="form-group">
                            {errors.phone && (
                              <div className="error-message">
                                {errors.phone}
                              </div>
                            )}
                            <input
                              type="number"
                              id="phone"
                              name="phone"
                              disabled={loading}
                              className={getInputClass("phone")}
                              value={formData.phone || ""}
                              onChange={handleChange}
                              placeholder="Phone"
                              required
                            />
                          </div>
                        </Col>
                        <h3 className="widget-title widget-title fw-700 mt-5">
                          ADDRESS DETAILS
                        </h3>
                        <p
                          style={{
                            color: "#F00",
                            fontFamily: "Titillium Web",
                            fontSize: "13px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                            letterSpacing: "-0.39px",
                            textTransform: "capitalize",
                          }}
                        >
                          *This address will use as your shipping address*
                        </p>
                        <Col lg={6}>
                          <div className="form-group">
                            {errors.address1 && (
                              <div className="error-message">
                                {errors.address1}
                              </div>
                            )}
                            <input
                              type="text"
                              id="address1"
                              name="address1"
                              disabled={loading}
                              value={formData.address1 || ""}
                              className={getInputClass("address1")}
                              onChange={handleChange}
                              placeholder="Address 1"
                              required
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="form-group">
                            {errors.address2 && (
                              <div className="error-message">
                                {errors.address2}
                              </div>
                            )}
                            <input
                              type="text"
                              id="address2"
                              name="address2"
                              disabled={loading}
                              value={formData.address2 || ""}
                              className={getInputClass("address2")}
                              placeholder="Address 2"
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="form-group">
                            {errors.country && (
                              <div className="error-message">
                                {errors.country}
                              </div>
                            )}
                            <Form.Select
                              id="country"
                              name="country"
                              disabled={loading}
                              value={formData.country}
                              onChange={handleChange}
                              className={getInputClass("country")}
                              aria-label="Country"
                              defaultValue={"USA"}
                            >
                              <option disabled>Country</option>
                              <option selected value="USA">
                                USA
                              </option>
                            </Form.Select>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="form-group">
                            {errors.state && (
                              <div className="error-message">
                                {errors.state}
                              </div>
                            )}
                            <Form.Select
                              id="state"
                              name="state"
                              value={formData.state}
                              disabled={loading}
                              onChange={handleChange}
                              // className="form-control"
                              defaultValue={null}
                              className={getInputClass("state")}
                              aria-label="State"
                            >
                              <option value={null}>State</option>
                              <option value="New York">New York</option>
                              <option value="Alabama">Alabama</option>
                              <option value="Alaska">Alaska</option>
                              <option value="Arizona">Arizona</option>
                              <option value="Arkansas">Arkansas</option>
                              <option value="California">California</option>
                              <option value="Colorado">Colorado</option>
                              <option value="Connecticut">Connecticut</option>
                              <option value="Delaware">Delaware</option>
                              <option value="Florida">Florida</option>
                              <option value="Georgia">Georgia</option>
                              <option value="Hawaii">Hawaii</option>
                              <option value="Idaho">Idaho</option>
                              <option value="Illinois">Illinois</option>
                              <option value="Indiana">Indiana</option>
                              <option value="Iowa">Iowa</option>
                              <option value="Kansas">Kansas</option>
                              <option value="Kentucky">Kentucky</option>
                              <option value="Louisiana">Louisiana</option>
                              <option value="Maine">Maine</option>
                              <option value="Maryland">Maryland</option>
                              <option value="Massachusetts">
                                Massachusetts
                              </option>
                              <option value="Michigan">Michigan</option>
                              <option value="Minnesota">Minnesota</option>
                              <option value="Mississippi">Mississippi</option>
                              <option value="Missouri">Missouri</option>
                              <option value="Montana">Montana</option>
                              <option value="Nebraska">Nebraska</option>
                              <option value="Nevada">Nevada</option>
                              <option value="New Hampshire">
                                New Hampshire
                              </option>
                              <option value="New Jersey">New Jersey</option>
                              <option value="New Mexico">New Mexico</option>
                              <option value="New York">New York</option>
                              <option value="North Carolina">
                                North Carolina
                              </option>
                              <option value="North Dakota">North Dakota</option>
                              <option value="Ohio">Ohio</option>
                              <option value="Oklahoma">Oklahoma</option>
                              <option value="Oregon">Oregon</option>
                              <option value="Pennsylvania">Pennsylvania</option>
                              <option value="Rhode Island">Rhode Island</option>
                              <option value="South Carolina">
                                South Carolina
                              </option>
                              <option value="South Dakota">South Dakota</option>
                              <option value="Tennessee">Tennessee</option>
                              <option value="Texas">Texas</option>
                              <option value="Utah">Utah</option>
                              <option value="Vermont">Vermont</option>
                              <option value="Virginia">Virginia</option>
                              <option value="Washington">Washington</option>
                              <option value="West Virginia">
                                West Virginia
                              </option>
                              <option value="Wisconsin">Wisconsin</option>
                              <option value="Wyoming">Wyoming</option>
                            </Form.Select>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="form-group">
                            {errors.city && (
                              <div className="error-message">{errors.city}</div>
                            )}
                            <input
                              type="text"
                              id="city"
                              name="city"
                              disabled={loading}
                              value={formData.city || ""}
                              placeholder="City"
                              className={getInputClass("city")}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="form-group">
                            {errors.zip && (
                              <div className="error-message">{errors.zip}</div>
                            )}
                            <input
                              type="text"
                              id="zip"
                              name="zip"
                              disabled={loading}
                              value={formData.zip || ""}
                              placeholder="Zip"
                              className={getInputClass("zip")}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </Col>
                        <h3 className="widget-title widget-title fw-700 mt-5">
                          ADDITIONAL DETAILS
                        </h3>
                        <h3 className="widget-title widget-title fw-700 mt-5">
                          DOCUMENTS REQUIRED
                        </h3>
                        <Col lg={6}>
                          <div className="form-group">
                            {errors.businessLicenseNumber && (
                              <div className="error-message">
                                {errors.businessLicenseNumber}
                              </div>
                            )}
                            <input
                              id="businessLicenseNumber"
                              name="businessLicenseNumber"
                              type="text"
                              disabled={loading}
                              placeholder="Business License Number"
                              value={formData.businessLicenseNumber || ""}
                              className={getInputClass("businessLicenseNumber")}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="form-group">
                            <Form.Group controlId="formFile" className="mb-3">
                              {errors.BusinessLicenseNumber && (
                                <div className="error-message">
                                  {errors.BusinessLicenseNumber}
                                </div>
                              )}
                              <Form.Control
                                type="file"
                                name="BusinessLicenseNumber"
                                accept=".jpeg, .png, .jpg, .pdf"
                                disabled={loading}
                                className={getInputClass(
                                  "BusinessLicenseNumber"
                                )}
                                onChange={handleFileChange}
                                required
                              />
                            </Form.Group>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="form-group">
                            {errors.tobaccoLicenseNumber && (
                              <div className="error-message">
                                {errors.tobaccoLicenseNumber}
                              </div>
                            )}
                            <input
                              id="tobaccoLicenseNumber"
                              name="tobaccoLicenseNumber"
                              type="text"
                              disabled={loading}
                              value={formData.tobaccoLicenseNumber || ""}
                              placeholder="OTP/Vape/Tobacco License Number"
                              className={getInputClass("tobaccoLicenseNumber")}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="form-group">
                            <Form.Group controlId="formFile" className="mb-3">
                              {errors.TobaccoLicenseNumber && (
                                <div className="error-message">
                                  {errors.TobaccoLicenseNumber}
                                </div>
                              )}
                              <Form.Control
                                type="file"
                                name="TobaccoLicenseNumber"
                                disabled={loading}
                                accept=".jpeg, .png, .jpg, .pdf"
                                className={getInputClass(
                                  "TobaccoLicenseNumber"
                                )}
                                onChange={handleFileChange}
                              />
                            </Form.Group>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="form-group">
                            {errors.federalEINNumber && (
                              <div className="error-message">
                                {errors.federalEINNumber}
                              </div>
                            )}
                            <input
                              id="federalEINNumber"
                              name="federalEINNumber"
                              disabled={loading}
                              type="text"
                              value={formData.federalEINNumber || ""}
                              placeholder="Federal EIN Document Number"
                              className={getInputClass("federalEINNumber")}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="form-group">
                            <Form.Group controlId="formFile" className="mb-3">
                              {errors.FederalEINDocumentNumber && (
                                <div className="error-message">
                                  {errors.FederalEINDocumentNumber}
                                </div>
                              )}
                              <Form.Control
                                type="file"
                                name="FederalEINDocumentNumber"
                                accept=".jpeg, .png, .jpg, .pdf"
                                disabled={loading}
                                className={getInputClass(
                                  "FederalEINDocumentNumber"
                                )}
                                onChange={handleFileChange}
                              />
                            </Form.Group>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="form-group">
                            {errors.drivingLicenseNumber && (
                              <div className="error-message">
                                {errors.drivingLicenseNumber}
                              </div>
                            )}
                            <input
                              type="text"
                              id="drivingLicenseNumber"
                              name="drivingLicenseNumber"
                              disabled={loading}
                              value={formData.drivingLicenseNumber || ""}
                              placeholder="Driving License Number"
                              className={getInputClass("drivingLicenseNumber")}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="form-group">
                            <Form.Group controlId="formFile" className="mb-3">
                              {errors.DrivingLicenseNumber && (
                                <div className="error-message">
                                  {errors.DrivingLicenseNumber}
                                </div>
                              )}
                              <Form.Control
                                type="file"
                                name="DrivingLicenseNumber"
                                accept=".jpeg, .png, .jpg, .pdf"
                                disabled={loading}
                                className={getInputClass(
                                  "DrivingLicenseNumber"
                                )}
                                onChange={handleFileChange}
                              />
                            </Form.Group>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="form-group">
                            {errors.accountNumber && (
                              <div className="error-message">
                                {errors.accountNumber}
                              </div>
                            )}
                            <input
                              id="accountNumber"
                              name="accountNumber"
                              disabled={loading}
                              value={formData.accountNumber || ""}
                              type="text"
                              placeholder="Account Number"
                              className={getInputClass("accountNumber")}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="form-group">
                            <Form.Group controlId="formFile" className="mb-3">
                              {errors.VoidCheque && (
                                <div className="error-message">
                                  {errors.VoidCheque}
                                </div>
                              )}
                              <Form.Control
                                name="VoidCheque"
                                type="file"
                                accept=".jpeg, .png, .jpg, .pdf"
                                disabled={loading}
                                className={getInputClass("VoidCheque")}
                                onChange={handleFileChange}
                              />
                              <p
                                style={{
                                  color: "#F00",
                                  fontFamily: "Titillium Web",
                                  fontSize: "13px",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  lineHeight: "normal",
                                  letterSpacing: "-0.39px",
                                  textTransform: "capitalize",
                                }}
                              >
                                *Plesae enter your void chequwe in this file*
                              </p>
                            </Form.Group>
                          </div>
                        </Col>
                        <Col
                          lg={12}
                          className="d-flex align-items-end justify-content-between"
                        >
                          <ReCAPTCHA
                            sitekey={reCAPTCHA_KEY}
                            style={{ paddingBottom: "15px" }}
                            onChange={(value) => setRecaptchaToken(value)}
                          />
                        </Col>

                        <section className="agreement-section position-relative border">
                          <div className="agreement-contain pb-5">
                            <h3 className="main-title widget-title fw-700 mt-5">
                              DOCUMENTS REQUIRED
                            </h3>
                            <p className="mb-5">
                              Your personal data will be used to support your
                              experience throughout this website, to manage
                              access to your account, and for other purposes
                              described in our Privacy Policy
                            </p>
                          </div>
                        </section>
                        <Col lg={12}>
                          <Form.Group
                            className="my-5"
                            controlId="formBasicCheckbox1"
                          >
                            {errors.policyCheckbox && (
                              <div className="error-message">
                                {errors.policyCheckbox}
                              </div>
                            )}
                            <Form.Check
                              required
                              name="policyCheckbox"
                              type="checkbox"
                              value={policyCheckbox}
                              checked={policyCheckbox}
                              onClick={() => setPolicyCheckbox(!policyCheckbox)}
                              label="I have read and agree Quality Wholesale Terms of Use & Privacy Policy."
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={12} style={{ paddingBottom: "10px" }}>
                          <span style={{ color: "#c02233" }}>
                            NOTE: Your account will be held for moderation and
                            you will be unable to login until it is approved.
                          </span>
                        </Col>
                        <Col lg={12}>
                          <CustomButton
                            btnClassname="shadow-btn w-auto"
                            type="submit"
                            loading={loading}
                            btnClick={handleSubmit}
                            text="Register"
                          />
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Col>
              </Row>
            </Form>
          </Container>
        </div>
      </section>
    </>
  );
}
export default SignUp;
