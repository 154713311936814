import React from "react";
import { Container, Row, Col, Alert, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

function SuccessPage() {
  return (
    <Container className="mt-5">
      <Row className="justify-content-md-center text-center">
        <Col md={8}>
          <h1
            className="mb-4"
            style={{
              color: "var(--orange)",
            }}
          >
            Order Placed Successful!
          </h1>
          <Alert
            variant="success"
            style={{
              fontSize: 20,
              fontWeight: "bold",
            }}
          >
            Your order has been placed and is being processed.
            <br />
            {/* <strong>Order Number:</strong>  */}
          </Alert>
          <p className="text-muted">
            Your order is being processed by our delivery team and you should
            receive a confirmation from us shortly. Click the button below to
            see your orders
          </p>
          <Button
            variant="success"
            href="/orders"
            className="mt-3"
            style={{
              padding: "10px 20px",
              fontSize: 15,
              backgroundColor: "var(--orange)",
              borderColor: "white",
            }}
          >
            View My Orders
          </Button>
          <p className="mt-4">
            If you have any questions or if there's anything wrong with your
            order, please don't hesitate to{" "}
            <Link
              to="/contact"
              style={{
                color: "var(--main)",
                textDecoration: "none",
              }}
            >
              contact us
            </Link>
            . We're here to help ensure your experience is smooth and
            satisfactory.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default SuccessPage;
