import React, { useState,useEffect } from "react";
import axios from "axios";
import axiosInstance from "../../utils/axiosInstance";
import { APIS, DEFAULT_PRODUCT_IMAGE } from "../../utils/constants";
import { Col, Container, Form, Row, Button } from "react-bootstrap";
export default function UserProfile() {
    const [responseData, setResponseData] = useState([])
    console.log(responseData,"responseData")
    const [loading, setLoading] = useState(false);
    const [showButtons, setShowButtons] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        bussinessName: '',
        email: '',
        password: '',
        confirmPassword: '',
        bussinessPhone: '',
        phone: '',
        address1: '',
        address2: '',
        city: '',
        zip: '',
        state: '',
        country: 'USA',
        businessLicenseNumber: '',
        tobaccoLicenseNumber: '',
        federalEINNumber: '',
        drivingLicenseNumber: '',
        accountNumber: '',
    });
    console.log(formData,"formData")
    useEffect(() => {
        const source = axios.CancelToken.source();
        setLoading(true)
        const fetchData = async () => {
            try {
                const viewCardResponse = await axiosInstance.get(`${APIS.user_profile}`);
                setResponseData(viewCardResponse.data);
                const userData = viewCardResponse.data; 
                setFormData(userData); 
                setLoading(false)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
        return () => {
            source.cancel('Request canceled by cleanup');
        };
    }, []);
    const handleClick = () => {
        setShowButtons(true);
        setEditMode(true);
    };

    const handleSaveClick = async () => {
        setLoading(true);
        try {
            const updateProfileResponse = await axiosInstance.patch(`${APIS.update_profile}`, {
                address1: formData.address1,
                address2: formData.address2,
                city: formData.city,
                state: formData.state,
                country: formData.country,
                zip: formData.zip,
            });
            console.log('Profile updated successfully:', updateProfileResponse.data);
            setLoading(false);
            setShowButtons(false);
            setEditMode(false);
        } catch (error) {
            console.error('Error updating profile:', error);
            setLoading(false);
        }
    };

    const handleCancelClick = () => {
        setShowButtons(false);
        setEditMode(false);
    };


    const getInputClass = (fieldName) => {
        return errors[fieldName] ? "form-control error-input" : "form-control";
    };
    const [files, setFiles] = useState([]);
    const [errors, setErrors] = useState({});
    //   const formRef = useRef(null); 
    const handleChange = (e) => {
        const { name, value } = e.target;
        const validateLength = (maxLength) => {
            if (value.length > maxLength) {
                return false;
            } else return true;
        };

        if (name === "bussinessPhone" || name === "phone") {
            if (value.toString().length <= 10)
                setFormData((prevState) => ({
                    ...prevState,
                    [name]: value,
                }));
        } else if (name === "firstName") {
            if (!/[!@#$%^&*(),.?":{}|<>]/.test(value) && !/^\d{19}$/g.test(value))
                setFormData((prevState) => ({
                    ...prevState,
                    [name]: value,
                }));
        } else if (name === "lastName") {
            if (!/[!@#$%^&*(),.?":{}|<>]/.test(value) && !/^\d{19}$/g.test(value))
                setFormData((prevState) => ({
                    ...prevState,
                    [name]: value,
                }));
        } else if (name === "zip") {
            if (
                value.toString().length <= 6 &&
                !value.includes("--") &&
                !/[a-zA-Z]/.test(value) &&
                !/[-=]{3,}/.test(value) &&
                !/[!@#$%^&*(),.?":{}|<>]/.test(value)
            ) {
                setFormData((prevState) => ({
                    ...prevState,
                    [name]: value,
                }));
            }
        } else if (name === "drivingLicenseNumber") {
            if (
                value.toString().length <= 14 &&
                !value.includes("--") &&
                !/[a-zA-Z]/.test(value) &&
                !/[-=]{3,}/.test(value) &&
                !/[!@#$%^&*(),.?":{}|<>]/.test(value) &&
                !/^(?!.*[-=]{2,})[0-9]{19}$/g.test(value) &&
                !/^\d{19}$/g.test(value)
            )
                setFormData((prevState) => ({
                    ...prevState,
                    [name]: value,
                }));
        } else if (name === "businessLicenseNumber") {
            const validate = validateLength(20);
            validate &&
                setFormData((prevState) => ({
                    ...prevState,
                    [name]: value,
                }));
        } else if (name === "tobaccoLicenseNumber") {
            const validate = validateLength(20);
            validate &&
                setFormData((prevState) => ({
                    ...prevState,
                    [name]: value,
                }));
        } else if (name === "federalEINNumber") {
            const validate = validateLength(20);
            validate &&
                setFormData((prevState) => ({
                    ...prevState,
                    [name]: value,
                }));
        } else if (name === "accountNumber") {
            const validate = validateLength(20);
            validate &&
                setFormData((prevState) => ({
                    ...prevState,
                    [name]: value,
                }));
        } else {
            setFormData((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    };

    // const resetFormData = () => {
    //     setFormData({
    //         firstName: "",
    //         lastName: "",
    //         bussinessName: "",
    //         email: "",
    //         password: "",
    //         confirmPassword: "",
    //         bussinessPhone: "",
    //         phone: "",
    //         address1: "",
    //         address2: "",
    //         city: "",
    //         zip: "",
    //         state: "",
    //         country: "USA",
    //         businessLicenseNumber: "",
    //         tobaccoLicenseNumber: "",
    //         federalEINNumber: "",
    //         drivingLicenseNumber: "",
    //         accountNumber: "",
    //         TobaccoLicenseNumber: null,
    //         BusinessLicenseNumber: null,
    //         VoidCheque: null,
    //         DrivingLicenseNumber: null,
    //         FederalEINDocumentNumber: null,
    //     });
    //     setFiles([]);
    // };


    return (
        <>
            <Row className="justify-content-center">
                <Col lg={8}>
                    <div className="contact-form">
                        <h2>
                           Hello!  &nbsp;
                            <span className="fill-text orange">{formData.bussinessName}</span>
                        </h2>
                        <h3 className="widget-title widget-title fw-700 mt-5">
                            REGISTRATION DETAILS
                        </h3>
                        <Form>
                            <Row>
                                <Col lg={6}>
                                    <div className="form-group">
                                        {errors.firstName && (
                                            <div className="error-message">
                                                {errors.firstName}
                                            </div>
                                        )}
                                        <input
                                            id="firstName"
                                            disabled
                                            name="firstName"
                                            type="text"
                                            value={formData.firstName || ""}
                                            className={getInputClass("firstName")}
                                            placeholder="First Name"
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="form-group">
                                        {errors.lastName && (
                                            <div className="error-message">
                                                {errors.lastName}
                                            </div>
                                        )}
                                        <input
                                            id="lastName"
                                            name="lastName"
                                            disabled
                                            value={formData.lastName || ""}
                                            className={getInputClass("lastName")}
                                            type="text"
                                            placeholder="Last Name"
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="form-group">
                                        {errors.bussinessName && (
                                            <div className="error-message">
                                                {errors.bussinessName}
                                            </div>
                                        )}
                                        <input
                                            id="bussinessName"
                                            disabled
                                            name="bussinessName"
                                            value={formData.bussinessName || ""}
                                            className={getInputClass("bussinessName")}
                                            type="text"
                                            placeholder="Bussiness Name"
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div
                                        className="form-group"
                                        style={{ marginBottom: 0 }}
                                    >
                                        {errors.email && (
                                            <div className="error-message">
                                                {errors.email}
                                            </div>
                                        )}
                                        <input
                                            id="email"
                                            name="email"
                                            disabled
                                            value={formData.email || ""}
                                            className={getInputClass("email")}
                                            type="email"
                                            placeholder="Email"
                                            onChange={handleChange}
                                            required
                                        />

                                    </div>
                                </Col>
                                {/* <Col lg={6}>
                                    <div className="form-group">
                                        {errors.password && (
                                            <div className="error-message">
                                                {errors.password}
                                            </div>
                                        )}
                                        <input
                                            id="password"
                                            name="password"
                                            disabled
                                            value={formData.password || ""}
                                            className={getInputClass("password")}
                                            type="password"
                                            placeholder="Password"
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="form-group">
                                        {errors.confirmPassword && (
                                            <div className="error-message">
                                                {errors.confirmPassword}
                                            </div>
                                        )}
                                        <input
                                            id="confirmPassword"
                                            name="confirmPassword"
                                            disabled
                                            value={formData.confirmPassword || ""}
                                            className={getInputClass("confirmPassword")}
                                            type="password"
                                            placeholder="Confirm Password"
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </Col> */}
                                <Col lg={6}>
                                    <div className="form-group">
                                        {errors.bussinessPhone && (
                                            <div className="error-message">
                                                {errors.bussinessPhone}
                                            </div>
                                        )}
                                        <input
                                            type="number"
                                            id="bussinessPhone"
                                            disabled
                                            name="bussinessPhone"
                                            className={getInputClass("bussinessPhone")}
                                            value={formData.bussinessPhone || ""}
                                            onChange={handleChange}
                                            placeholder="Bussiness Phone"
                                            required
                                        />
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="form-group">
                                        {errors.phone && (
                                            <div className="error-message">
                                                {errors.phone}
                                            </div>
                                        )}
                                        <input
                                            type="number"
                                            id="phone"
                                            name="phone"
                                            disabled
                                            className={getInputClass("phone")}
                                            value={formData.phone || ""}
                                            onChange={handleChange}
                                            placeholder="Phone"
                                            required
                                        />
                                    </div>
                                </Col>
                                <h3 className="widget-title widget-title fw-700 mt-5">
                                    ADDRESS DETAILS{' '}
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        fill="currentColor"
                                        className="bi bi-pencil-square"
                                        viewBox="0 0 16 16"
                                        onClick={handleClick}
                                        style={{ cursor: 'pointer', marginLeft: '12px' }}
                                    >
                                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                        <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z" />
                                    </svg>
                                </h3>

                                <p
                                    style={{
                                        color: "#F00",
                                        fontFamily: "Titillium Web",
                                        fontSize: "13px",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        lineHeight: "normal",
                                        letterSpacing: "-0.39px",
                                        textTransform: "capitalize",
                                    }}
                                >
                                </p>
                                <Col lg={6}>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            id="address1"
                                            name="address1"
                                            disabled={!editMode}
                                            value={formData.address1}
                                            onChange={handleChange}
                                            className={getInputClass('address1')}
                                            placeholder="Address 1"
                                            required
                                        />
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            id="address2"
                                            name="address2"
                                            disabled={!editMode}
                                            value={formData.address2}
                                            onChange={handleChange}
                                            className={getInputClass('address2')}
                                            placeholder="Address 2"
                                            required
                                        />
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="form-group">
                                        <Form.Select
                                            id="country"
                                            name="country"
                                            disabled={!editMode}
                                            value={formData.country}
                                            onChange={handleChange}
                                            className={getInputClass('country')}
                                            aria-label="Country"
                                            defaultValue={'USA'}
                                        >
                                            <option disabled>Country</option>
                                            <option selected value="USA">
                                                USA
                                            </option>
                                        </Form.Select>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="form-group">
                                        <Form.Select
                                            id="state"
                                            name="state"
                                            value={formData.state}
                                            disabled={!editMode}
                                            onChange={handleChange}
                                            className={getInputClass('state')}
                                            aria-label="State"
                                            defaultValue={null}
                                        >
                                            <option value={null}>State</option>
                                            <option value="New York">New York</option>
                                            <option value="Alabama">Alabama</option>
                                            <option value="Alaska">Alaska</option>
                                            <option value="Arizona">Arizona</option>
                                            <option value="Arkansas">Arkansas</option>
                                            <option value="California">California</option>
                                            <option value="Colorado">Colorado</option>
                                            <option value="Connecticut">Connecticut</option>
                                            <option value="Delaware">Delaware</option>
                                            <option value="Florida">Florida</option>
                                            <option value="Georgia">Georgia</option>
                                            <option value="Hawaii">Hawaii</option>
                                            <option value="Idaho">Idaho</option>
                                            <option value="Illinois">Illinois</option>
                                            <option value="Indiana">Indiana</option>
                                            <option value="Iowa">Iowa</option>
                                            <option value="Kansas">Kansas</option>
                                            <option value="Kentucky">Kentucky</option>
                                            <option value="Louisiana">Louisiana</option>
                                            <option value="Maine">Maine</option>
                                            <option value="Maryland">Maryland</option>
                                            <option value="Massachusetts">
                                                Massachusetts
                                            </option>
                                            <option value="Michigan">Michigan</option>
                                            <option value="Minnesota">Minnesota</option>
                                            <option value="Mississippi">Mississippi</option>
                                            <option value="Missouri">Missouri</option>
                                            <option value="Montana">Montana</option>
                                            <option value="Nebraska">Nebraska</option>
                                            <option value="Nevada">Nevada</option>
                                            <option value="New Hampshire">
                                                New Hampshire
                                            </option>
                                            <option value="New Jersey">New Jersey</option>
                                            <option value="New Mexico">New Mexico</option>
                                            <option value="New York">New York</option>
                                            <option value="North Carolina">
                                                North Carolina
                                            </option>
                                            <option value="North Dakota">North Dakota</option>
                                            <option value="Ohio">Ohio</option>
                                            <option value="Oklahoma">Oklahoma</option>
                                            <option value="Oregon">Oregon</option>
                                            <option value="Pennsylvania">Pennsylvania</option>
                                            <option value="Rhode Island">Rhode Island</option>
                                            <option value="South Carolina">
                                                South Carolina
                                            </option>
                                            <option value="South Dakota">South Dakota</option>
                                            <option value="Tennessee">Tennessee</option>
                                            <option value="Texas">Texas</option>
                                            <option value="Utah">Utah</option>
                                            <option value="Vermont">Vermont</option>
                                            <option value="Virginia">Virginia</option>
                                            <option value="Washington">Washington</option>
                                            <option value="West Virginia">
                                                West Virginia
                                            </option>
                                            <option value="Wisconsin">Wisconsin</option>
                                            <option value="Wyoming">Wyoming</option>
                                        </Form.Select>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            id="city"
                                            name="city"
                                            disabled={!editMode}
                                            value={formData.city}
                                            placeholder="City"
                                            className={getInputClass('city')}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            id="zip"
                                            name="zip"
                                            disabled={!editMode}
                                            value={formData.zip}
                                            placeholder="Zip"
                                            className={getInputClass('zip')}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </Col>
                                <Col lg={6}></Col>
                                <Col lg={6}>
                                {showButtons && (
                                    <div
                                    style={{float:"right"}}
                                    >
                                        <Button
                                            style={{
                                                backgroundColor: "var(--main)",
                                                borderColor: "white",
                                                fontSize: "20px",
                                            }}
                                            onClick={() => handleSaveClick()}
                                            size="lg"
                                        >
                                            Save
                                        </Button>
                                        <Button
                                            style={{
                                                backgroundColor: "var(--orange)",
                                                borderColor: "white",
                                                fontSize: "20px",
                                            }}
                                            onClick={handleCancelClick}
                                            className="ms-2"
                                            size="lg"
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                )}
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Col>
            </Row>
        </>
    )

}