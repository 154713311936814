import { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper/modules";
import { useState } from "react";
import { Col, Container, Row, Button, Modal, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { SVGIcons } from "../Data/SVGIcons";
import "./featureCard.css";
import { DEFAULT_PRODUCT_IMAGE } from "../../utils/constants";
import { useUser } from "../../utils/UserContext";

export default function FeatureCards({ title, subTitle, cards }) {
  const navigate = useNavigate();
  const { user } = useUser();
  // const [show, setShow] = useState(false);
  // const [modalCardDetails, setModalCardDetails] = useState({});
  // const [products, setProducts] = useState([
  //   {
  //     id: 1,
  //     name: "Product 1",
  //     image: "/assets/Slider/product-1.png",
  //     quantity: 0,
  //     price: 10,
  //   },
  //   {
  //     id: 2,
  //     name: "Product 2",
  //     image: "/assets/Slider/product-2.png",
  //     quantity: 0,
  //     price: 15,
  //   },
  //   {
  //     id: 3,
  //     name: "Product 3",
  //     image: "/assets/Slider/product-3.png",
  //     quantity: 0,
  //     price: 20,
  //   },
  //   {
  //     id: 4,
  //     name: "Product 4",
  //     image: "/assets/Slider/product-4.png",
  //     quantity: 0,
  //     price: 25,
  //   },
  // ]);
  const swiperRefLocal = useRef();
  // const handleClose = () => setShow(false);
  const handleShow = () => {};

  const handleRedirect = (productID) => {
    if (productID && parseInt(productID) > 0) {
      return navigate(`/product-details?product=${productID}`);
    }
  };
  const cardsData =
    cards && cards?.data && cards?.data.length > 0 ? cards.data : [];

  const handleMouseEnter = () => {
    swiperRefLocal?.current?.swiper?.autoplay?.stop();
  };

  const handleMouseLeave = () => {
    swiperRefLocal?.current?.swiper?.autoplay?.start();
  };
  return (
    <section className="card-section position-relative">
      <div className={"card-contain space-top"}>
        <Container>
          {title && subTitle && (
            <div className="mb-5">
              <div
                className="feature-title"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  title === "Popular Products" && navigate("/popular-products");
                  title === "New Arrival" && navigate("/new-arrival");
                  title === "Deals" && navigate("/deals");
                }}
              >
                {title}
              </div>
              {subTitle && <div className="feature-subtitle">{subTitle}</div>}
            </div>
          )}
          <Row className="row-gap-10">
            <div
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <Swiper
                style={{
                  "--swiper-navigation-color": "#fff",
                  "--swiper-pagination-color": "#fff",
                }}
                slidesPerView={4}
                spaceBetween={20}
                ref={swiperRefLocal}
                loop={true}
                navigation={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                grabCursor={true}
                modules={[Navigation, Autoplay]}
                breakpoints={{
                  0: {
                    slidesPerView: 1.2,
                  },
                  424: {
                    slidesPerView: 2,
                  },
                  768: {
                    slidesPerView: 3,
                  },
                  1200: {
                    slidesPerView: 4,
                  },
                }}
                className="position-relative"
              >
                {/* <Modal
                  show={show}
                  onHide={handleClose}
                  size="lg"
                  className="product-modal"
                  centered
                >
                  <Modal.Header
                    closeButton
                    style={{ border: "none", marginRight: 10, color: "black" }}
                  />
                  <Modal.Body style={{ paddingTop: 0 }}>
                    <div className="row">
                      <div className="col-md-6">
                        <img
                          src={
                            modalCardDetails?.FeatureImage
                              ? modalCardDetails?.FeatureImage
                              : "/assets/Images/productImage.png"
                          }
                          alt={
                            modalCardDetails?.FeatureTitle
                              ? modalCardDetails?.FeatureTitle
                              : "Product"
                          }
                          className="img-fluid"
                        />
                      </div>
                      <div className="col-md-6">
                        <p style={{ fontSize: "20px", fontWeight: "bold" }}>
                          {modalCardDetails?.FeatureTitle}
                        </p>
                        <div className="product-variants">
                          <div className="product-table mb-5">
                            <Table striped bordered hover>
                              <thead>
                                <tr>
                                  <th>Image</th>
                                  <th>Name</th>
                                  <th>Quantity</th>
                                  <th>Price</th>
                                </tr>
                              </thead>
                              <tbody>
                                {products.map((product, idx) => (
                                  <tr key={idx}>
                                    <td>
                                      <img
                                        src={product.image}
                                        alt={product.name}
                                        style={{
                                          width: "50px",
                                          height: "50px",
                                        }}
                                      />
                                    </td>
                                    <td>{product.name}</td>
                                    <td>
                                      <Button
                                        variant="outline-danger"
                                        style={{ cursor: "not-allowed" }}
                                      >
                                        <span>{SVGIcons.Less}</span>
                                      </Button>
                                      {product.quantity}
                                      <Button
                                        variant="outline-success"
                                        style={{ cursor: "not-allowed" }}
                                      >
                                        {SVGIcons.More}
                                      </Button>
                                    </td>
                                    <td>
                                      <Link
                                        to={"/login"}
                                        style={{ color: "black" }}
                                      >
                                        Login
                                      </Link>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                        <Button
                          className="feature-login-button"
                          style={{
                            backgroundColor: "var(--main)",
                            fontSize: "15px",
                            borderColor: "white",
                          }}
                          href="/login"
                        >
                          <span
                            style={{
                              padding: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            Login to view price
                          </span>
                        </Button>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal> */}
                {cardsData.map((cardData, idx) => (
                  <SwiperSlide
                    key={idx}
                    onClick={() => {
                      handleRedirect(cardData.id);
                    }}
                  >
                    <Col>
                      <div className="new-products p-4">
                        <Link to="#" className="img-holder">
                          <div className="feature-image-container">
                            <img
                              src={` https://qw3images.blob.core.windows.net/products-qw/${cardData.code2}.png`}
                              className="feature-product-image"
                              alt={cardData?.name?.en}
                              height={"300px"}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = DEFAULT_PRODUCT_IMAGE;
                              }}
                              loading={"lazy"}
                            />
                            {/* <button
                              className="feature-quick-view-button"
                              onClick={() => {
                                setModalCardDetails(cardData);
                              }}
                            >
                              Quick View
                            </button> */}
                          </div>
                        </Link>
                        <p
                          style={{
                            color: "black",
                            fontWeight: "bold",
                            fontSize: "15px",
                          }}
                        >
                          {cardData?.name}
                        </p>
                        <p
                          style={{
                            color: "black",
                            fontWeight: "bold",
                            fontSize: "12px",
                          }}
                        >
                          UPC Code: {cardData?.code2}
                        </p>
                        {cardData?.price && (
                          <div>
                            <p
                              style={{
                                color: "var(--main)",
                                fontSize: "15px",
                                fontWeight: "bold",
                              }}
                            >
                              Price: {cardData?.price} USD
                            </p>
                          </div>
                        )}
                        {cardData?.stock?.free && (
                          <div>
                            <span
                              style={{
                                paddingLeft: "10px",
                                paddingRight: "10px",
                              }}
                            >
                              Quantity: {cardData?.stock?.free || 0}
                            </span>
                          </div>
                        )}
                        {(!user || !user.isVerified) && (
                          <div>
                            <Button
                              href="/login"
                              className="feature-login-button"
                              style={{
                                marginTop: 5,
                                backgroundColor: "var(--main)",
                                fontSize: "15px",
                                borderColor: "white",
                                color: "white",
                              }}
                            >
                              <span
                                style={{
                                  padding: "10px",
                                  fontWeight: "bold",
                                }}
                              >
                                Login to view price
                              </span>
                            </Button>
                          </div>
                        )}
                      </div>
                    </Col>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </Row>
        </Container>
      </div>
    </section>
  );
}
