import { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Form, Row, Button, Modal } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { reCAPTCHA_KEY } from "../../utils/constants";
import CustomButton from "../../Components/Button";
import ScrollToTopOnMount from "../../Components/ScrollToTopOnMount";
import { loginUser } from "../../utils/auth";
import Loader from "../../Components/Loader";

function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [error, setError] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);

  const [passwordVisibility, setPasswordVisibility] = useState(false);

  const isEmailValid = (email) => {
    // Simple email validation using a regular expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleEmailChange = (value) => {
    setEmail(value);
    if (value && !isEmailValid(value)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError("");
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!isEmailValid(email)) {
      setEmailError("Please enter a valid email address.");
      setShowErrorModal(true);
      return;
    }
    if (password?.length < 1) {
      setEmailError("Please enter a password.");
      setShowErrorModal(true);
      return;
    }
    if (!recaptchaToken) {
      setError("Please verify that you are not a robot.");
      return;
    }
    console.log("recaptchaToken", recaptchaToken);
    if ((email, password, recaptchaToken)) {
      const result = await loginUser(
        email.toLowerCase().trim(),
        password,
        recaptchaToken
      );
      if (result?.success) {
        if (result?.isVerified) {
          window.location.href = "/";
        } else {
          window.location.href = "/under-review";
        }
      } else {
        setError(result?.error?.data?.message);
        console.log(result?.error);
        setShowErrorModal(true);
        setLoading(false);
      }
    } else {
      setError("Please enter a valid credentials.");
      setShowErrorModal(true);
      setLoading(false);
    }
  };
  if (loading) {
    return <Loader />;
  }

  const handleCloseErrorModal = () => setShowErrorModal(false);
  return (
    <>
      <ScrollToTopOnMount />
      <section className="contact-section position-relative space-top">
        <div className="contact-contain">
          <Container>
            <Row className="justify-content-center">
              <Col lg={8}>
                <div className="contact-form">
                  <h2>
                    Sign
                    <span className="fill-text orange">In</span>
                  </h2>

                  <Form>
                    <Row>
                      <Col lg={6}>
                        <div className="form-group">
                          <input
                            className={`form-control ${
                              emailError ? "is-invalid" : ""
                            }`}
                            type="email"
                            onChange={(e) => handleEmailChange(e.target.value)}
                            placeholder="Email"
                            required
                          />
                          {emailError && (
                            <div className="invalid-feedback">{emailError}</div>
                          )}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="form-group">
                          <input
                            type={passwordVisibility ? "text" : "password"}
                            style={{ marginBottom: "5px" }}
                            className="form-control"
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleSubmit(e);
                              }
                            }}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Password"
                            required
                          />
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                              onClick={() =>
                                setPasswordVisibility(!passwordVisibility)
                              }
                            />
                            <label
                              class="form-check-label"
                              for="flexCheckDefault"
                            >
                              Show Password
                            </label>
                          </div>

                          {/* <span
                            id="confirm-password-toggle"
                            className="toggle-visibility"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              setPasswordVisibility(!passwordVisibility)
                            }
                          >
                            {passwordVisibility ? "🙈" : "👁️"}
                          </span> */}
                        </div>
                      </Col>
                      <Col
                        lg={12}
                        className="d-flex align-items-end justify-content-between"
                      >
                        <ReCAPTCHA
                          sitekey={reCAPTCHA_KEY}
                          style={{ paddingBottom: "15px" }}
                          onChange={(value) => setRecaptchaToken(value)}
                        />
                      </Col>
                      <Col
                        lg={12}
                        className="d-flex align-items-end justify-content-between"
                      >
                        <CustomButton
                          btnClassname="shadow-btn w-auto"
                          btnClick={handleSubmit}
                          text={<span>Sign In</span>}
                        />
                        <div className="info">
                          <Link
                            to="/contact"
                            className="big fw-800 text-gray"
                          >
                            Forgot Password?
                          </Link>
                        </div>
                      </Col>
                      <div className="border-top mt-5"></div>
                      <Col lg={12} className="d-center flex-column">
                        <h3 className="main-title widget-title fw-700 mt-5">
                          Don't have an account?
                        </h3>
                        <Link to="/register" className="big fw-800 text-black">
                          <CustomButton
                            btnClassname="shadow-btn w-auto"
                            text={<span className="">Register</span>}
                          />
                        </Link>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      {/* Error Modal */}
      <Modal show={showErrorModal} onHide={handleCloseErrorModal} centered>
        <Modal.Header closeButton />
        <Modal.Body>{error}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseErrorModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default SignIn;
