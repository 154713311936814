import { Button } from "react-bootstrap";
import "./CustomButton.css";

const CustomButton = (props) => {
  return (
    <Button
      variant="none"
      loading={props.loading}
      type={props.type && props.type.length ? props.type : "button"}
      className={`main-btn arrow-btn text-white ${props.btnClassname}`}
      onClick={props.btnClick}
    >
      <span className="custom-btn-inner">{props.text}</span>
    </Button>
  );
};

export default CustomButton;
