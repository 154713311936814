import React, { useEffect, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { APIS, DEFAULT_PRODUCT_IMAGE } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import axiosInstance from "../../utils/axiosInstance";
import Loader from "../../Components/Loader";

const SearchProduct = () => {
  const [productList, setProductList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const searchVal = searchParams.get("searchValue");
    if (searchVal && searchVal.length > 0) {
      searchProduct(searchVal).then(() => {
        console.log("Got it ");
      });
    }
  }, [searchParams]);
  const navigate = useNavigate();
  const handleProductClick = (product_id) => {
    return navigate(`/product-details?product=${product_id}`);
  };

  const searchProduct = async (searchValue) => {
    try {
      const source = axios.CancelToken.source();
      setLoading(true);
      const url = `${APIS.search}?value=${searchValue}`;
      const response = await axiosInstance.get(url, {
        cancelToken: source.token,
      });
      if (response.data.data.length > 0) {
        setProductList(response.data);
      }
    } catch (error) {
      alert(error.message);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <section
          className="card-section position-relative"
          style={{ marginTop: "2%" }}
        >
          <div className="card-contain">
            <Container>
              <Row className="row-gap-5">
                {productList?.data?.length > 0 &&
                  productList?.data?.map((item, idx) => {
                    return (
                      <Col lg={3} key={idx}>
                        <div
                          className="new-products p-4"
                          style={{ cursor: "default" }}
                        >
                          <span
                            className="img-holder"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              handleProductClick(item?.productID);
                            }}
                          >
                            <Image
                              src={` https://qw3images.blob.core.windows.net/products-qw/${item?.code2}.png`}
                              className="img-contain"
                              alt={item?.name}
                              height={"300px"}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = DEFAULT_PRODUCT_IMAGE;
                              }}
                            />
                          </span>
                          <div>
                            <p
                              style={{
                                color: "var(--orange)",
                                fontWeight: "bold",
                                fontSize: "15px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                handleProductClick(item?.productID);
                              }}
                            >
                              {item?.name}
                            </p>
                            {/* <p
                            style={{
                              color: "black",
                              fontWeight: "bold",
                              fontSize: "15px",
                              margin: "0 10px 0 0",
                            }}
                          >
                            UPC: {item?.code2 ? item?.code2 : "N/A"}{" "}
                          </p> */}
                          </div>
                          {/* {(!user || !user.isVerified) && (
                          <Button
                            href="/login"
                            className="feature-login-button"
                            style={{
                              marginTop: 5,
                              backgroundColor: "var(--main)",
                              fontSize: "15px",
                              borderColor: "white",
                              color: "white",
                            }}
                          >
                            <span
                              style={{
                                padding: "10px",
                                fontWeight: "bold",
                              }}
                            >
                              Login to view price
                            </span>
                          </Button>
                        )} */}
                        </div>
                      </Col>
                    );
                  })}

                {productList.length === 0 && (
                  <span>No Products found Found</span>
                )}
              </Row>
              {/* {productData && productList.length > 0 && (
              <>
                <Row>
                  <PaginationComponent
                    itemsPerPage={itemsPerPage}
                    totalItems={totalItems}
                    paginate={paginate}
                    currentPage={currentPage}
                  />
                </Row>
                <Row></Row>
              </>
            )} */}
            </Container>
          </div>
        </section>
      )}
    </div>
  );
};

export default SearchProduct;
