import { createContext, useContext, useState } from "react";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import { logoutUser } from "./auth";

const UserContext = createContext();

export const useUser = () => {
  return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const token = Cookies.get("QW_authToken");
    if (token) {
      try {
        const decoded = jwtDecode(token);
        return {
          firstName: decoded.firstName,
          lastName: decoded.lastName,
          isAdmin: decoded.isAdmin,
          userId: decoded.userId,
          email: decoded.email,
          isVerified: decoded.isVerified,
        };
      } catch (error) {
        return null;
      }
    }
    return null;
  });
  const [productList, setProductList] = useState([]);
  const [cartItem, setCartItem] = useState(0);
  const logout = async () => {
    await logoutUser(); // Call the logoutUser function you created above
    setUser(null); // Reset user state to indicate no user is logged in
    window.location.reload(true);
  };

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        logout,
        cartItem,
        setCartItem,
        productList,
        setProductList,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
