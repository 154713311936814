import React from "react";
import { Navigate } from "react-router-dom";
import { useUser } from "../utils/UserContext";

const LoggedInRoute = ({ children }) => {
  const { user } = useUser();
  const isAuthenticated = () => {
    return user;
  };

  return isAuthenticated() ? <Navigate to="/" replace /> : children;
};

export default LoggedInRoute;
