import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import { googleMapsLink } from "../../utils/constants";
import "leaflet/dist/leaflet.css";
import "./map.css";
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const redIcon = new L.Icon({
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
  iconUrl:
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png",
});

function MapComponent() {
  const position = [33.4301684, -94.0837575];

  return (
    <MapContainer
      center={position}
      zoom={13}
      style={{ height: "170px", width: "90%" }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker position={position} icon={redIcon}>
        <Popup>
          Quality Wholesale USA
          <br /> Warehouse.
          <a href={googleMapsLink} target="_blank" rel="noopener noreferrer">
            View Directions
          </a>
        </Popup>
      </Marker>
    </MapContainer>
  );
}

export default MapComponent;
