import React from "react";
import Pagination from "react-bootstrap/Pagination";
import "./pagination.css";

const PaginationComponent = ({
  itemsPerPage,
  totalItems,
  paginate,
  currentPage,
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const pageNumbers = [];
  const rangeDisplayed = 3;

  for (let i = 1; i <= totalPages; i++) {
    if (
      i === 1 ||
      i === totalPages ||
      (i >= currentPage - rangeDisplayed && i <= currentPage + rangeDisplayed)
    ) {
      pageNumbers.push(i);
    }
  }

  const handleFirst = () => paginate(1);
  const handlePrevious = () => paginate(Math.max(1, currentPage - 1));
  const handleNext = () => paginate(Math.min(totalPages, currentPage + 1));
  const handleLast = () => paginate(totalPages);

  return (
    <Pagination className="custom-pagination">
      <Pagination.First onClick={handleFirst} disabled={currentPage === 1} />
      <Pagination.Prev onClick={handlePrevious} disabled={currentPage === 1} />

      {pageNumbers.map((number, index) => (
        <React.Fragment key={number}>
          {index > 0 && pageNumbers[index - 1] !== number - 1 && (
            <Pagination.Ellipsis disabled />
          )}
          <Pagination.Item
            active={number === currentPage}
            onClick={() => paginate(number)}
          >
            {number}
          </Pagination.Item>
        </React.Fragment>
      ))}

      <Pagination.Next
        onClick={handleNext}
        disabled={currentPage === totalPages}
      />
      <Pagination.Last
        onClick={handleLast}
        disabled={currentPage === totalPages}
      />
    </Pagination>
  );
};

export default PaginationComponent;
